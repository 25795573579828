<template>
  <v-expansion-panel class="rounded-0">
    <v-expansion-panel-header>
      <h4>{{ $t('participation.exhibitionSpace.title') }}</h4>
    </v-expansion-panel-header>
    <v-expansion-panel-content>

      <table class="spaces">
        <tr>
          <th>{{ $t('participation.configureRegistrationForm.sizeOn') }}</th>
          <th>{{ $t('participation.configureRegistrationForm.sizeUp') }}</th>
          <th>{{ $t('participation.configureRegistrationForm.partialPayer') }}</th>
          <th>{{ $t('participation.configureRegistrationForm.fullPayer') }}</th>
          <th>{{ $t('participation.configureRegistrationForm.publicHand') }}</th>
          <th>{{ $t('participation.ua') }}</th>
          <th>{{ $t('participation.configureRegistrationForm.label') }}</th>
          <th></th>
        </tr>
        <tr>
          <td colspan="8" class="text-left">
            <small>{{ $t('participation.configureRegistrationForm.exhibitionSpace.standWithConstructionStand') }}</small>
          </td>
        </tr>
        <tr v-for="(item) in tradefair_spaces_with_construction" :key="item.id">
          <td>
            {{ item.attributes.size_from}} ㎡
          </td>
          <td>
            {{ item.attributes.size_to}} ㎡
          </td>
          <td>
            {{ item.attributes.price_sponsored | money }}
          </td>
          <td>
            {{ item.attributes.price_unsponsored | money }}
          </td>
          <td>
            {{ item.attributes.price_publicsector | money }}
          </td>
          <td>
            <v-icon class="mb-1 mr-3" size="18">
              fal
              {{ item.attributes.allow_subexhibitor ? 'fa-check' : 'fa-times' }}
            </v-icon>
          </td>
          <td>
            {{ item.attributes.title[$i18n.locale] }}
          </td>
          <td nowrap="nowrap">
            <v-btn color="secondary" icon @click="edit(item)">
              <v-icon size="18">fal fa-pencil</v-icon>
            </v-btn>
            <v-btn color="secondary" @click="remove(item)" icon>
              <v-icon size="18">fal fa-trash-alt</v-icon>
            </v-btn>
          </td>
        </tr>
        <tr>
          <td colspan="8">
            <v-btn color="secondary" icon @click="add(true)">
              <v-icon size="18">fal fa-plus</v-icon>
            </v-btn>
          </td>
        </tr>

        <tr>
          <td colspan="8" class="text-left">
            <small>{{ $t('participation.configureRegistrationForm.exhibitionSpace.standWithoutConstructionStand') }}</small>
          </td>
        </tr>
        <tr v-for="(item) in tradefair_spaces_without_construction" :key="item.id">
          <td>
            {{ item.attributes.size_from}} ㎡
          </td>
          <td>
            {{ item.attributes.size_to}} ㎡
          </td>
          <td>
            {{ item.attributes.price_sponsored | money }}
          </td>
          <td>
            {{ item.attributes.price_unsponsored | money }}
          </td>
          <td>
            {{ item.attributes.price_publicsector | money }}
          </td>
          <td>
            <v-icon class="mb-1 mr-3" size="18">
              fal
              {{ item.attributes.allow_subexhibitor ? 'fa-check' : 'fa-times' }}
            </v-icon>
          </td>
          <td>
            {{ item.attributes.title[$i18n.locale] }}
          </td>
          <td nowrap="nowrap">
            <v-btn color="secondary" icon @click="edit(item)">
              <v-icon size="18">fal fa-pencil</v-icon>
            </v-btn>
            <v-btn color="secondary" @click="remove(item)" icon>
              <v-icon size="18">fal fa-trash-alt</v-icon>
            </v-btn>
          </td>
        </tr>
        <tr>
          <td colspan="8">
            <v-btn color="secondary" icon @click="add(false)">
              <v-icon size="18">fal fa-plus</v-icon>
            </v-btn>
          </td>
        </tr>
      </table>

      <v-row class="mt-5">
        <v-col cols="6">
          <p class="mb-5">
            <b>{{ $t('participation.configureRegistrationForm.serviceDescription.descriptionWithContruction') }}</b></p>
          <p class="my-2">{{ $t('participation.configureRegistrationForm.service_description_de') }}</p>
          <vue-editor
              :placeholder="$t('participation.configureRegistrationForm.serviceDescription.serviceDescriptionWithContruction')"
              v-model="form.data.servicedescription_with_construction.de"
          />
          <p class="my-2">{{ $t('participation.configureRegistrationForm.service_description_en') }}</p>
          <vue-editor
              :placeholder="$t('participation.configureRegistrationForm.serviceDescription.serviceDescriptionWithContruction')"
              v-model="form.data.servicedescription_with_construction.en"
          />
        </v-col>
        <v-col cols="6">
          <p class="mb-5">
            <b>{{ $t('participation.configureRegistrationForm.serviceDescription.descriptionWithoutContruction') }}</b>
          </p>
          <p clasS="my-2">{{ $t('participation.configureRegistrationForm.service_description_de') }}</p>
          <vue-editor
              :placeholder="$t('participation.configureRegistrationForm.serviceDescription.serviceDescriptionWithoutContruction')"
              v-model="form.data.servicedescription_without_construction.de"
          />
          <p class="my-2">{{ $t('participation.configureRegistrationForm.service_description_en') }}</p>
          <vue-editor
              :placeholder="$t('participation.configureRegistrationForm.serviceDescription.serviceDescriptionWithoutContruction')"
              v-model="form.data.servicedescription_without_construction.en"
          />
        </v-col>
      </v-row>

      <v-btn class="mt-5" color="secondary" elevation="0" @click="save">
        {{ $t('forms.buttons.saveAndContinue') }}
      </v-btn>

      <organizer-customize-spaces-edit
          ref="edit"
          :fair="fair"
          :spaces="spaces"
          @submit="load"
      ></organizer-customize-spaces-edit>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import OrganizerCustomizeSpacesEdit from "@/components/organizer/Application/Customize/CustomizeRegistrationSpaceEdit";
import { VueEditor } from 'vue2-editor'
import validation from "@/mixins/validate";

export default {
  name: 'CustomizeRegistrationSpace',

  components: {
    VueEditor,
    OrganizerCustomizeSpacesEdit
  },

  mixins: [
    validation,
  ],

  props: {
    fair: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      spaces: [],

      form: {
        loading: false,

        data: {
          servicedescription_with_construction: {},
          servicedescription_without_construction: {},
        },

        validationRules: {
          servicedescription_with_construction: {},
          servicedescription_without_construction: {},
        },
      },
    }
  },

  computed: {
    tradefair_spaces_with_construction() {
      return this.spaces.filter((el) => { return el.attributes.construction_included === true; }).sort((a, b) => a.attributes.size_from - b.attributes.size_from);
    },

    tradefair_spaces_without_construction() {
      return this.spaces.filter((el) => { return el.attributes.construction_included === false; }).sort((a, b) => a.attributes.size_from - b.attributes.size_from);
    },
  },

  created() {
    this.setInitialFormData(this.fair.attributes)

    this.load()
  },

  methods: {
    async load() {
      this.form.loading = true

      let response = (await this.$axios.get(`/api/trade-fairs/${this.fair.id}/spaces`, {
        params: {
          'languages': '*',
          'include': [],
        },
      })).data

      this.spaces = response.data

      this.form.loading = false
    },

    async save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.form.loading = true
      try {
        await this.$axios.patch(`/api/trade-fairs/${this.fair.id}`, { ...this.getFormData() })

        this.$snackbar(this.$t('common.successApiMessage'))

        this.$emit('submit')
      } catch (e) {
        if (e?.response?.status === 422) {
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
      }

      this.form.loading = false
    },

    edit(item) {
      this.$refs.edit.show(item, item.attributes.construction_included)
    },

    add(construction_included) {
      this.$refs.edit.show(null, construction_included)
    },

    async remove(item) {
      if(confirm(this.$t('participation.configureRegistrationForm.exhibitionSpace.alertDeleteSpace'))) {
        await this.$axios.delete(`/api/trade-fairs/${this.fair.id}/spaces/${item.id}`, {})

        this.$snackbar(this.$t('common.successApiMessage'))

        this.load()
      }
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>

<style scoped>
  table.spaces { border-spacing: 0; border-collapse: collapse; }
  table.spaces th { text-align: left; border-bottom: 1px solid #000000; padding: .3rem 1rem .3rem 0; font-size: .8rem; }
  table.spaces td { text-align: left; border-bottom: 1px solid rgba(0,0,0,.1); padding: .3rem 1rem .3rem 0; }
  table.spaces td:last-child { text-align: right; }
</style>

export default {
  interest: {
    interestedPersons: 'Interested',
    company: 'Company',
    location: 'Location',
    accredited: 'Accredited',
    contacted: 'Contacted',
    email: 'E-mail',
    website: 'Website',
    reservedOn: 'Bookmarked',
    addressAndContactDetails: 'Address and contact details',
    phone: 'Phone',
    contactPerson: 'Contact person',
    countryInterest: 'Country interest',
    industryInterest: 'Industry interest',
    furtherTradeFairParticipations: 'Further trade fair participations',
    bookmarkedTradeFairs: 'Bookmarked trade fairs',
    participations: 'Participations',
    exportAsExcelSheet: 'Export as Excel sheet',
    exportAsPDFFile: 'Export as PDF file',
    exportAsCsvFile: 'Export as CSV file',
  },
}

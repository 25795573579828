<template>
  <div>
    <date-input
      :label="$t('participation.editSteps.deadline.registrationDeadline')"
      :initial-value="fair.attributes.registration_deadline"
      :start-trade-fair-date="fair.attributes.start_date"
      :end-limit="true"
      @changed="updateDeadline"
    />

    <submit-button
        :is-readonly="!form.data.deadline"
        :is-loading="form.loading"
        @clicked="handleDeadlineSubmit"
    />
  </div>
</template>

<script>
import DateInput from '@/components/base/BaseDateInput'
import SubmitButton from '@/components/base/SubmitButton'

export default {
  name: 'RegistrationDeadline',

  props: {
    fair: {
      type: Object,
    },
  },

  components: {
    SubmitButton,
    DateInput,
  },

  data() {
    return {
      deadline: null,

      form: {
        loading: false,

        data: {
          deadline: null,
        },
      },
    }
  },

  created() {
    this.deadline = this.fair.attributes.registration_deadline
    this.form.data.deadline = this.deadline
  },

  computed: {
    isDeadlineDifferent() {
      return this.deadline !== this.form.data.deadline
    },
  },

  methods: {
    updateDeadline(deadline) {
      this.form.data.deadline = deadline
    },

    async handleDeadlineSubmit() {
      if (this.isDeadlineDifferent) {
        this.form.loading = true

        await this.$axios.patch(`/api/trade-fairs/${this.fair.id}`, {
          registration_deadline: this.$options.filters.dateTime(this.form.data.deadline),
        })

        this.$snackbar(this.$t('common.successApiMessage'))

        this.form.loading = false
      }

      this.$emit('submit')
    },
  },
}
</script>

export default {
  tables: {
    tradeFair: {
      name: 'Name',
      tradeFair: 'Trade fair',
      status: 'Status',
      date: 'Date',
      city: 'City',
      country: 'Country',
      startAt: 'Start at',
      endAt: 'End at',
      details: 'Details',
      registrationDeadline: 'Registration deadline',
      bookmarked: 'Bookmarked',
      mainOffering: 'Main offering',
      industryFocus: 'Industry focus',
      informationAndRegistration: 'Information and registration',
      participationForm: 'Registration form',
      execution: 'Implemented by:',
      projectManager: 'Project manager',
      mail: 'E-mail',
      phone: 'Phone',
      fax: 'Fax',
      organizerWebsite: 'Organizer website',
      detailedTradeFairInformation: 'Detailed trade fair information',
      AUMATradeFairDatabase: 'AUMA trade fair database',
      bookmark: 'Bookmark',
      registerNow: 'Register now',
      signUp: 'Sign up',
      beginning: 'Beginning',
      program: 'Programme',
      association: 'Association',
      notYetKnown: 'not yet known',
      statuses: {
        canceled: 'Cancelled',
        interestExpressed: 'Bookmarked',
        registrationDeadlineRuns: 'Registration period runs',
        registrationDeadlineExpired: 'Registration deadline expired',
        summaryMissing: 'Trade fair summary missing',
        tradeFairInProgress: 'Trade fair is runnning'
      },
    },
    participation: {
      status: {
        submitted: 'Registration submitted',
        cancelled: 'Registration canceled',
        approved: 'Registration approved',
        rejected: 'Registration rejected',
        reprocessed: 'Please check registration',
        checkRegistration: 'Please check registration',
        completed: 'Participation completed',
      },
      pageStatus: {
        submitted: 'Registered (not confirmed)',
        cancelled: 'Registration cancelled',
        approved: 'Registration booked',
        rejected: 'Registration rejected',
        reprocessed: 'Returned for processing',
        checkRegistration: 'Please check registration',
        completed: 'Participation completed',
      },
      count: {
        submitted: 'Submitted',
        cancelled: 'Cancelled',
        approved: 'Approved',
        rejected: 'Rejected',
        reprocessed: 'Returned',
        checkRegistration: 'Check registration',
        completed: 'Completed',
      },
      form: {
        conditionsOfParticipation: 'Conditions of participation',
        onlineRegistrationAndParticipation: 'Online registration & participation',
        registrationAndParticipation: 'Registration & participation',
        atPresentOnlineRegistrationVia: 'At present, online registration via this platform is only available for ' +
          'some of the events in the Federal Government\'s foreign trade fair programme. You can download the ' +
          'registration documents under the following link. If you have any questions, please contact the ' +
          'implementing company listed below.',
        registrationDocumentsOnTheAUMA: 'Registration documents on the AUMA website',
        specialConditionsOfParticipation: 'Special Conditions of Participation (BTB)',
        generalConditionsOfParticipation:  'General Conditions of Participation of the Federal Republic of Germany ' +
          'at trade fairs and exhibitions abroad (ATB)',
        registerForParticipationInThisTradeFairInFewSimpleSteps: 'Register for participation in this trade fair ' +
          'in a few simple steps.',
        registerForParticipationDeadlineIsPassed: 'The registration deadline for this event has passed.',
        makeNoteOfYourParticipationInTheTradeFair: 'Make a note of your participation in the trade fair',
        areYouInterestedInParticipatingInThisTradeFair: 'Are you interested in participating in this trade fair ' +
          'without obligation? Then please let us know and bookmark this event. ' +
          'You will then be provided with all the necessary information in due course so that you can then ' +
          'make a binding decision about your participation.',
        iWouldLikeToReserveThisEventWithoutObligation: 'I would like to bookmark this event without obligation',
        accreditation: 'Accreditation',
        onceYouHaveBeenGrantedAnAccreditation: 'Once you have been granted an accreditation, ' +
          'you are authorized to make a binding booking of your participation ' +
          'in the events of the international trade fair program.',
        yourAccreditationWasGrantedOn: 'Your accreditation was granted on',
        yourAccreditationHasNotYetBeenCompleted: 'Your accreditation has not yet been completed.',
        carryOutAccreditation: 'Carry out accreditation',
        registration: 'Registration',
        registerAsAnExhibitorForTheJoint: 'Register as an exhibitor for the joint stand at {tradeFair} ' +
          'within the specified registration period. Other companies at your stand must be ' +
          'registered as sub-exhibitors.',
        registrationTakesPlaceOn: 'Registration takes place on',
        sqm: 'm<sup>2</sup>',
        standAreaWithStandConstruction: 'stand area with stand construction',
        registeredCoExhibitors: 'Registered co-exhibitors:',
        registeredCoExhibitorsNothing: 'No co-exhibitors',
        viewAndEditRegistration: 'View and edit registration',
        viewRegistration: 'View registration',
        registerNow: 'Register now',
        registrationConfirmation: 'Registration confirmation',
        yourRegistrationWillBeCheckedBy: 'Once your registration has been confirmed, you will receive all the information you need to ' +
            'prepare for and take part in the trade fair directly from the implementation company ' +
            '({organizer}). Your contact person will personally assist you with all questions regarding your ' +
            'participation in the trade fair (e.g. stand construction, logistics, marketing, entry).',
        yourRegistrationWasSuccessfullyConfirmedOn: 'Your registration was successfully confirmed on {date}',
        examinationAndRegistrationConfirmationPending: 'Examination and registration confirmation pending.',
        examinationAndRegistrationSendingPending: 'Examination and registration sending pending.',
        downloadRegistrationConfirmation: 'Download registration confirmation',
        ifYouHaveAnyQuestionsAboutRegistration: 'If you have any questions about your registration, please contact',
        mail: 'E-mail',
        phone: 'Phone',
        tradeFairPreparationAndParticipation: 'Trade fair preparation and participation',
        onceYouHaveConfirmedYourRegistration: 'Once your registration has been confirmed, you will receive ' +
            'all the information you need to prepare for and take part in the fair directly ' +
            'from the implementation company ({organizer}). They will personally assist you with ' +
            'all questions regarding your participation in the fair ' +
            '(e.g. stand construction, logistics, marketing, entry).',
        startOfTheFair: 'Start of the fair',
        ifYouHaveAnyQuestionsAboutTradeFair: 'If you have any questions about trade fair preparation ' +
          'and participation, please contact',
        closingAndExhibitorSurvey: 'Closing and exhibitor survey',
        afterTheTradeFairIsOver: 'After the trade fair is over, we ask you to take part in the exhibitor survey, ' +
          'which provides important information on improving the service of foreign trade fair participations.',
        exhibitorSurveyAvailableFromUntil: 'Exhibitor survey available from / until',
        youHaveAlreadyParticipatedInTheExhibitorSurvey: 'You have already participated in the exhibitor survey ' +
          '- thank you very much.',
        completeTheExhibitorSurvey: 'Complete the exhibitor survey',
        participationAndConditions: 'Participation and conditions',
        standSizesAndPrices: 'Stand sizes and prices',
        packagesPrices: 'Packages prices',
        informationOnTheConditionsAndParticipation : 'Information on the conditions and participation opportunities for this event will be published on the AUMA website:',
        minimumArea: 'Minimum area',
        maximumArea: 'Maximum area',
        qm: 'm<sup>2</sup>',
        priceUnsponsoredHelp: 'This price only applies to exhibitors who have already taken part in this trade fair participation at least four times since July 2021.',
        priceSponsored: 'Price 1st - 4th participation',
        priceUnsponsored: 'Price from 5th participation',
        pricePublicsector: 'Full price',
        pricePerM2withStandConstruction: 'Price per m<sup>2</sup> with stand construction',
        pricePerM2withoutStandConstruction: 'Price per m<sup>2</sup> without stand construction',
        fees: 'Fees',
        mandatoryFee: 'Mandatory fee',
        optionalFee: 'Optional fee',
        registrationFee: 'Registration fee',
        exhibitionAreasOfGermanParticipation: 'Exhibition areas of German participation',
        implementationCompany: 'Implementation company',
        contactPersonForThisTradeFairParticipation: 'Contact person for this trade fair participation',
        dataAndInformationAboutTheFair: 'Data and information about the trade fair',
        mainOfferings: 'Main offerings',
        frequency: 'Frequency',
        foundation: 'Founded in',
        websiteOfTheOrganizer: 'Website of the organizer',
        informationInTheAUMATradeFairDatabase: 'Detailed information in AUMA trade fair database',
        organizer: 'Organizer',
        visitorNumbers: 'Visitor numbers',
        exhibitorStructure: 'Exhibitor structure',
        visitorStructure: 'Visitor structure',
        tradeFairReport2019: 'Trade fair report 2019',
        downloads: 'Downloads',
        youHaveNotRegisteredYet: 'You have not yet registered for your participation.',
      }
    },
    accreditation: {
      accreditationRequests: 'Accreditation requests',
      checkAndApprove: 'check and approve',
      status: 'Status',
      statuses: {
        initialAccreditation: 'Initial accreditation',
        approved: 'Approved',
        update: 'Update'
      },
      companies: 'Companies',
      cityCountry: 'City, country',
      users: 'Users',
      date: 'Date',
      similarCompanies: 'Similar companies',
      currentAccreditationRequest: 'Current accreditation request',
      lastAccreditationGranted: 'Last accreditation granted',
      rejectedAt: 'revoked on {date}',
      currentAccreditation: 'Current accredited data',
      companyEntriesWithMatchingCharacteristicsWereFound: 'There are {similarities} entries with similarities',
      noCompanyEntriesWithMatchingCharacteristicsWereFound: 'No company entries with matching characteristics were found.',
      company: 'Company',
      street: 'Street',
      postcode: 'Postcode',
      location: 'Location',
      state: 'State',
      poBox: 'P.O. Box',
      poBoxZip: 'P.O. Box Zip',
      phone: 'Phone',
      fax: 'Fax',
      email: 'E-mail',
      website: 'Website',
      hrRegistryCourt: 'HR registry court',
      contactPerson: 'Contact person',
      hrNumber: 'HR number',
      taxNumber: 'Tax number',
      vatID: 'VAT ID',
      commercialRegisterExtract: 'Commercial register extract',
      authorityOrInstitutionUnderPublicLaw: 'Authority or institution under public law',
      participationPublicFounds: 'Participation in institutional funding from public funds',
      participationOfReligiousCommunities: 'Participation of religious communities or jur. persons of the public right',
      yes: 'Yes',
      no: 'No',
      connectedCompany: 'Related German Company',
      foreignRepresentation: 'Foreign Branch of a German company',
      foreignBranch: 'Foreign representation of a German company'
    }
  },
}

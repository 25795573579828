export default {
  onboarding: {
    header: {
      thanksForRegistration: 'Vielen Dank für Ihre Registrierung',
      completeProfile: 'Bitte nehmen Sie sich etwas Zeit um Ihr Profil zu vervollständigen, damit wir Ihnen möglichst relevante Informationen anzeigen können.',
    },
    steps: {
      companyData: {
        title: 'Unternehmensdaten',
      },
      countriesAndIndustries: {
        title: 'Länder & Branchen',
        description: 'Welche Länder und welche Branchen sind für Ihr Unternehmen für eine Messeteilnahme interessant?',
        fields: {
          countries: 'Länder',
          industrySectors: 'Branchen',
        },
      },
      tradeFairs: {
        title: 'Messen',
        description: 'Für welche Messen interessieren Sie sich speziell? (optional)',
        fields: {
          tradeFairs: 'Messen',
        }
      },
      nextSteps: {
        title: 'Ihre nächsten Schritte',
        description: 'Danke für diese hilfreichen Informationen. Das sind Ihre möglichen nächsten Schritte:',
        actions: {
          findTradeFairs: 'Finden Sie Messebeteiligungen, die für Ihr Unternehmen von Interesse sind.',
          carryOutAccreditation: 'Führen Sie die Akkreditierung für Ihr Unternehmen durch, damit Sie Ihre Messeteilnahme über diese Plattform buchen können.',
          useHelpAndAdvice: 'Nutzen Sie die Funktionen "Hilfe & Ratgeber" um sich mit Auswahl und Ablauf einer Auslandsmessebeteiligung vertraut zu machen.',
        },
      },
      toAccreditation: 'Zur Akkreditierung',
      nextStep: {
        title: 'Nächster Schritt',
        description: 'Danke für diese hilfreichen Informationen.',
      }
    },
  },
}

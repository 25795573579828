<template>
  <div>
    <application-header :exclude-header="excludeHeader" :fair="fair" :mode="mode" :company="company">
    </application-header>

    <v-card
        v-if="isTradeFairInactive"
        class="elevation-1 rounded-0 mt-5"
    >
      <v-card-text v-if="fair.attributes.flag_cancelled" class="text--primary">
        {{ $t('tradeFair.registrationIsCancelled', lang) }}
      </v-card-text>
      <v-card-text
          v-if="fair.attributes.flag_gegistration_external_locked"
          class="text--primary"
      >
        {{
          $t('tradeFair.registrationIsExternalLocked', lang)
        }}
      </v-card-text>
      <v-card-text v-if="fair.included.is_finished" class="text--primary">
        {{ $t('tradeFair.tradeFairIsFinished', lang) }}
      </v-card-text>
      <v-card-text
          v-if="fair.included.is_deadline_passed && !fair.included.is_finished"
          class="text--primary"
      >
        {{
          $t('tradeFair.registrationDeadlineIsPassed', lang)
        }}
      </v-card-text>
    </v-card>

    <div v-else>
      <v-stepper
          v-if="((isPreviewMode || isReadonlyMode) || !(!company?.attributes?.accreditation_approved_at || !company?.attributes?.accreditation_request_at))"
          v-model="opened"
          vertical
          non-linear
          disabled
          class="pa-0 mt-5 elevation-1 rounded-0"
          @change="handleStepChange"
      >
        <component
            v-for="(step, index) in steps"
            :id="step.name"
            :key="step.name"
            :is="step"
            :step="index + 1"
            :participation="participation"
            :company="company"
            :fair="fair"
            :mode="mode"
            :is-editable="isEditableStep(index)"
            @click="handleStepChange(index)"
            @open="handleOpenStep"
            @submit="proceedToNextStep(index)"
        />
      </v-stepper>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import participationMode from '@/mixins/participationMode'
import ApplicationHeader from '@/components/exhibitor/Participation/ApplicationHeader'
import ContactData from '@/components/exhibitor/Participation/Steps/ContactData'
import ExhibitionArea from '@/components/exhibitor/Participation/Steps/ExhibitionArea'
import TermsAndConditions from '@/components/exhibitor/Participation/Steps/TermsAndConditions'
import FinancialSupport from '@/components/exhibitor/Participation/Steps/FinancialSupport'
import BillingAddress from "@/components/exhibitor/Participation/Steps/BillingAddress"
import ExhibitionGoods from "@/components/exhibitor/Participation/Steps/ExhibitionGoods"
import ServicePackage from "@/components/exhibitor/Participation/Steps/ServicePackage"
import ExhibitionSpace from "@/components/exhibitor/Participation/Steps/ExhibitionSpace"
import BookingTypeSelect from '@/components/exhibitor/Participation/Steps/BookingTypeSelect'
import SubExhibitors from '@/components/exhibitor/Participation/Steps/SubExhibitors'
import Services from '@/components/exhibitor/Participation/Steps/Services'
import CostOverview from '@/components/exhibitor/Participation/Steps/CostOverview'
import CheckAndSend from "@/components/exhibitor/Participation/Steps/CheckAndSend.vue";

export default {
  name: 'ApplicationForm',

  props: {
    fair: {
      type: Object,
    },

    mode: {
      type: String,
    },

    excludeHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {
    ApplicationHeader,
    ContactData,
    ExhibitionArea,
    ExhibitionGoods,
    Services,
    SubExhibitors,
    FinancialSupport,
    CostOverview,
    BillingAddress,
    TermsAndConditions,
  },

  mixins: [participationMode],

  data() {
    return {
      current: 0,
      opened: 1,

      company: null,

      form: {
        loading: false,
      },
    }
  },

  computed: {
    ...mapGetters('auth', ['user']),

    ...mapGetters('participation', [
      'participation',
    ]),

    steps() {
      let components = [
        ContactData,
        ExhibitionArea,
        ExhibitionGoods,
        Services,
        SubExhibitors,
        FinancialSupport,
        CostOverview,
        BillingAddress,
        TermsAndConditions,
        CheckAndSend,
      ]

      if (this.isBookingTypePackage) {
        components.splice(1, 0, ServicePackage)
      }

      if (this.isBookingTypeSpace) {
        components.splice(1, 0, ExhibitionSpace)
      }

      if (
          this.fair.attributes.booking_type_package &&
          this.fair.attributes.booking_type_space
      ) {
        components.splice(1, 0, BookingTypeSelect)
      }

      if (this.fair.relationships?.exhibition_areas?.length === 0) {
        components = components.filter((el) => {
          return el !== ExhibitionArea
        })
      }

      if (!this.fair.relationships.services.filter((el) => !el.attributes.fee).length) {
        components = components.filter((el) => {
          return el !== Services
        })
      }

      return components
    },

    isTradeFairInactive() {
      return this.fair?.attributes.flag_cancelled ||
          this.fair.included.is_finished ||
          this.fair.attributes.flag_registration_external_locked ||
          this.fair.included.is_deadline_passed
    },
  },

  async created() {
    if (this.isPreviewMode) {
      this.loadAsOrganizerPreview()

      this.setPreviewMode(this.isPreviewMode)
    }

    if (this.isLiveMode) {
      this.loadAsExhibitorRead()
    }

    if (this.isReadonlyMode) {
      this.loadAsOrganizerRead()
    }

    if (this.participation?.id) {
      this.fetchParticipationCost({ fair: this.fair, participation: this.participation })
    }
  },

  methods: {
    ...mapActions('participation', {
      setPreviewMode: 'setPreviewMode',
      checkValidationSteps: 'checkValidationSteps',
      fetchParticipationCost: 'fetchParticipationCost',
    }),

    async loadAsOrganizerPreview() {
      this.form.loading = true

      this.company = null

      this.form.loading = false
    },

    async loadAsOrganizerRead() {
      this.form.loading = true

      let response = (await this.$axios.get(`/api/companies/${this.participation.attributes.company_id}?ignoreApprove=0`, {
        params: {
          'languages': '*',
          'include': [
            'country',
            'company',
            'federalState',
          ],
        },
      })).data

      this.company = response.data

      this.form.loading = false
    },

    async loadAsExhibitorRead() {
      this.form.loading = true

      let response = (await this.$axios.get(`/api/companies/${this.user.companyId}?ignoreApprove=0`, {
        params: {
          'languages': '*',
          'include': [
            'country',
            'company',
            'federalState',
          ],
        },
      })).data

      this.company = response.data

      this.form.loading = false
    },


    isEditableStep(step) {
      if (this.isReadonlyMode) {
        return true
      }

      if (this.isPreviewMode) {
        return true
      }

      if (step == 0) {
        return true
      }

      return this.current >= 1 || !!this.participation?.id
    },

    proceedToNextStep(step) {
      this.$snackbar(this.$t('common.successApiMessage', this.lang))
      this.fetchParticipationCost({ fair: this.fair, participation: this.participation })

      if (this.stepsError.length) {
        this.checkValidationSteps({ fair: this.fair, participation: this.participation })
      }

      this.current = step + 1 > this.current ? step + 1 : this.current
      this.opened = this.opened + 1

      if (this.isBookingTypePackage || (this.isBookingTypeSpace && this.participation?.relationships?.spaces?.length && !this.participation.relationships.spaces[0].attributes.allow_subexhibitor)) {
        var components = [ExhibitionGoods, SubExhibitors]

        while (components.includes(this.steps[this.opened - 1])) {
          this.current += 1
          this.opened += 1
        }
      }
    },

    handleOpenStep(step) {
      let index = this.steps.findIndex((el) => {
        return el.name.replace('HOC', '') === step
      })
      window.scrollTo(0, step)

      this.current = index + 1
      this.opened = index + 1
    },

    handleStepChange(step) {
      if (this.isPreviewMode) {
        return
      }

      if (step > 1 && !this.participation?.id) {
        this.$snackbar(this.$t('participation.fillExhibitorFirst', this.lang), 'error')
      }
    },
  },
}
</script>

<style>
.logo {
  max-height: 80px;
  width: auto;
  display: block;
}

.table-packages tr td {
  padding: 1.25rem 0.5rem !important;
  border-top: 1px solid #e9e9e9;
  vertical-align: top;
}

.stepper-abstract {
  display: inline-block;
  right: 0;
  padding-right: 2rem;
  position: absolute;
}
</style>

<template>
  <v-card
      class="elevation-1 rounded-0 mb-5"
  >
    <v-card-title>
      <v-icon
          v-if="notification.attributes.by_user_id === null"
          color="primary"
          class="mr-3"
          size="20"
      >
        fal fa-inbox-in
      </v-icon>
      <v-icon v-else color="primary" class="mr-3" size="20"
      >fal fa-inbox-out
      </v-icon
      >
      <h5 v-html="notification.attributes.data[$i18n.locale].subject"></h5>
    </v-card-title>
    <v-card-subtitle class="pt-4">
      <i class="fal fa-clock fa-fw"></i> {{ notification.attributes.created_at | dateTimeByLocale }} |
      {{ notification.attributes.data[$i18n.locale].from }}
    </v-card-subtitle>
    <v-card-text class="text--primary">
      <p v-html="notification.attributes.data[$i18n.locale].body"></p>
    </v-card-text>
    <v-divider v-if="notification.attributes.read_at === null"/>
    <v-card-actions
        v-if="notification.attributes.read_at === null && notification.attributes.by_user_id === null"
    >
      <v-btn @click="$emit('read', notification)" icon>
        <v-icon size="20" color="primary">fal fa-check</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'Notification',

  props: {
    notification: {
      type: Object,
      required: true,
    }
  },
}
</script>

<template>
  <fragment>
    <h3 class="mb-5">{{ $t('accreditation.steps.complete.subTitle') }}</h3>

    <p>{{ $t('accreditation.steps.complete.description') }}</p>
    <v-row class="m-0 mt-2">
      <v-col cols="12">
          <v-btn
            v-if="
              !accreditation.attributes.accreditation_approved_at &&
              accreditation_old.attributes.accreditation_approved_at
            "
            @click="$emit('cancel')"
            color="error"
            class="elevation-0"
            :loading="form.loading"
          >
            {{ $t('common.cancel') }}
          </v-btn>
        <v-btn
          @click="handleSubmit"
          :loading="form.loading"
          color="secondary"
          class="mb-5 elevation-0 mr-2 float-right"
          :disabled="!isVerifyEmail"
        >
          {{ $t('forms.buttons.completeAndSend') }}
        </v-btn>
      </v-col>
      <v-col cols="12" class="pt-0">
        <v-alert dense outlined type="warning" v-if="!isVerifyEmail">
          {{ $t('accreditation.steps.complete.requiredVerification') }}<br />
          <small>
            {{ $t('accreditation.steps.complete.tip') }}
          </small>
        </v-alert>
      </v-col>
    </v-row>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Complete',

  props: {
    accreditation: Object,
    accreditation_old: Object,
  },

  data() {
    return {
      form: {
        loading: false,
      },
    }
  },

  computed: {
    ...mapGetters('auth', ['isVerifyEmail']),
  },

  methods: {
    async handleSubmit() {
      this.form.loading = true

      await this.$axios.post(
        `/api/accreditations/${this.accreditation.id}/request`
      )

      this.$snackbar(this.$t('common.successApiMessage'))

      this.$emit('submit')

      this.form.loading = false
    },
  },
}
</script>

export default {
  participation: {
    accreditation: {
      data: 'Akkreditierungsdaten',
      viewData: 'Akkreditierungsdaten einsehen',
      dataHint: 'Hier finden Sie die aktuell genehmigten Akkreditierungdaten des Ausstellers',
    },
    participations: 'Messeteilnahmen',
    registrationForJointCompanyExhibition: 'Anmeldung zur Deutschen Beteiligung',
    registrationDeadlineIsRunning: 'Anmeldefrist läuft',
    seeParentExhibitor: 'siehe Hauptaussteller',
    configureRegistrationForm: {
      allowSubexhibitorCheckbox: 'Es dürfen Unteraussteller mit angemeldet werden',
      allowSubexhibitor: 'Unteraussteller dürfen bei einer Paket-Buchung in der Regel nicht hinzugefügt werden. Bitte ggf. BAFA kontaktieren.',
      allowSubexhibitorStandSpace: 'Unteraussteller dürfen bei Standflächen-Buchung in der Regel hinzugefügt werden. Bitte ggf. BAFA kontaktieren.',
      save: 'Speichern',
      preview: 'zur Vorschau-Ansicht',
      editRegistrationForm: 'Anmeldeformular bearbeiten',
      downloadSummary: 'Zusammenfassung des Anmeldeformulars herunterladen',
      price: 'Preis',
      priceQ: 'Preis €',
      priceQm: 'Preis €/㎡',
      pricePerUnit: 'Einzelpreis',
      sizeOn: 'Größe von',
      sizeUp: 'Größe bis',
      sizeFrom: 'Größe von',
      sizeTo: 'Größe bis',
      sizeInSqm: 'Größe in ㎡',
      partialPayer: 'Preis 1. - 4. Teilnahme',
      fullPayer: 'Preis ab 5. Teilnahme',
      priceSponsored: 'Preis 1. - 4. Teilnahme',
      priceUnsponsored: 'Preis ab 5. Teilnahme',
      pricePublicsector: 'Vollkosten',
      publicHand: 'Vollkosten',
      label: 'Bezeichnung / Hinweis',
      amount: 'Menge',
      add: 'Hinzufügen',
      addFee: 'Hauptausstellergebühr hinzufügen',
      addPackage: 'Paket hinzufügen',
      designation: 'Leistungsbeschreibung',
      designation_en: 'Bezeichnung (englisch)',
      designation_de: 'Bezeichnung (deutsch)',
      service_description_en: 'Leistungsbeschreibung (englisch)',
      service_description_de: 'Leistungsbeschreibung (deutsch)',
      apply: 'Übernehmen',
      cancel: 'Abbrechen',
      rejectWithMessageModal: {
        title: 'Nachricht an den Aussteller',
        body: 'Warum wurde diese Anmeldung nicht akzeptiert. (Optional)',
        label: 'Kurze Begründung zur Ablehnung schreiben, bitte ohne Signatur (wird automatisch ergänzt)'
      },
      serviceDescription: {
        generalServiceDescription: 'Allgemeine Leistungsbeschreibung',
        generalServiceDescriptionPlaceholder: 'Allgemeine Leistungsbeschreibung. Dieser Text wird dem Austeller während der Anmeldung und in der Anmeldebestätigung als Leistungsbeschreibung angezeigt.',
        descriptionWithContruction: 'Beschreibung für Ausstellungsfläche mit Standbau',
        descriptionWithoutContruction: 'Beschreibung für Ausstellungsfläche ohne Standbau',
        serviceDescriptionWithContruction: 'Beschreibung für Ausstellungsfläche mit Standbau. Dieser Text wird dem Austeller während der Anmeldung und in der Anmeldebestätigung als Leistungsbeschreibung angezeigt.',
        serviceDescriptionWithoutContruction: 'Beschreibung für Ausstellungsfläche ohne Standbau. Dieser Text wird dem Austeller während der Anmeldung und in der Anmeldebestätigung als Leistungsbeschreibung angezeigt.'
      },
      general: {
        title: 'Allgemeine Einstellungen',
        space: 'Ausstellungsfläche',
        package: 'Paket/Pauschale',
        minParticipants: 'Mindest-Teilnehmerzahl',
        spaceMinSize: 'Mindestausstellungsfläche (㎡)',
        spaceMaxSize: 'Maximale Ausstellungsfläche (㎡)',
        spaceRangeError: 'Dieser Bereich ist für bereits erstellte Bereiche nicht korrekt',
        typesError: 'Sie müssen mindestens eine Option auswählen',
        nameAlternative: 'Messenamen anpassen',
        nameAlternativeAlert: 'Bitte nur bei Bedarf ausfüllen!',
        nameAlternativeClue: 'Wenn Sie den Messenamen ändern und speichern, wird dieser ab sofort überall auf dieser Platform geändert! Für den Standardnamen lassen Sie dieses Feld bitte leer.'
      },
      exhibitionSpace: {
        conflictModalTitle: 'Konflikt mit Ausstellungsflächen',
        conflictModalBody: 'Die bereits angelegten Flächen im "Ausstellungsfläche"-Reiter stehen im Konflikt mit den eingegebenen maximalen oder minimalen Ausstellungsflächen. Sie können entweder die "Ausstellungsflächen" anpassen und diesen Schritt danach wiederholen oder Sie setzen die angelegten Flächen zurück.',
        conflictModalReset: 'Flächen zurücksetzen',
        conflictModalAdjust: 'Flächen anpassen',
        standWithConstructionStand: 'Ausstellungsfläche mit Standbau:',
        standWithoutConstructionStand: 'Ausstellungsfläche ohne Standbau:',
        alertDeleteSpace: 'Diesen Bereich wirklich löschen?',
        addExhibitionSpace: 'Ausstellungsflächenoption hinzufügen',
        editExhibitionSpace: 'Ausstellungsflächenoption bearbeiten',
        errorCollision: 'Konflikt mit anderen Ausstellungsflächenoptionen'
      },
      servicePackage: {
        title: 'Bezeichnung',
        title_de: 'Bezeichnung (deutsch)',
        title_en: 'Bezeichnung (englisch)',
        services: 'Enthaltene Leistungen',
        services_de: 'Enthaltene Leistungen (deutsch)',
        services_en: 'Enthaltene Leistungen (englisch)',
        alertDeletePackage: 'Dieses Servicepaket wirklich löschen?',
        addServicePackage: 'Servicepaket hinzufügen',
        editServicePackage: 'Servicepaket bearbeiten',
      },
      fees: {
        title: 'Gebühren',
        designation_en: 'Aussagekräftige Bezeichnung für Gebühr (EN)',
        designation_de: 'Aussagekräftige Bezeichnung für Gebühr (DE)',
        subExhibitorFee: 'Unteraussteller-Gebühr',
        perSubExhibitorFee: 'Gebühr pro Unteraussteller',
        perSubExhibitorFee_de: 'Gebühr pro Unteraussteller (deutsch)',
        perSubExhibitorFee_en: 'Gebühr pro Unteraussteller (englisch)',
        perSubExhibitorIn: 'in € pro Unteraussteller',
        perMainExhibitorFee: 'Betrag pro Hauptaussteller',
        perMainExhibitorIn: 'in € pro Hauptaussteller',
        enterExternalFee: 'Zusätzliche Unteraussteller-Gebühr des Veranstalters',
        furtherCompulsoryFee: 'Weitere Pflichtgebühr',
        alertDeleteFee: 'Diese Gebühr wirklich löschen?',
        addServiceFees: 'Gebühr hinzufügen',
        editServiceFees: 'Gebühr bearbeiten',
        coexhibitorFee: 'Veranstalter-Gebühr pro Unteraussteller',
        coexhibitorFeeTitle: 'Bezeichnung der Gebühr',
        coexhibitorFeeDescription: 'Hinweis / Beschreibung',
        coexhibitorFeeTitle_de: 'Bezeichnung der Gebühr (deutsch)',
        coexhibitorFeeDescription_de: 'Hinweis/Beschreibung (Deutsch) – Bitte beachten Sie, dass dieser Text nur in den Preisinformationen zur Messebeteiligung, nicht aber im Anmeldeformular selbst sichtbar ist.',
        coexhibitorFeeTitle_en: 'Bezeichnung der Gebühr (englisch)',
        coexhibitorFeeDescription_en: 'Hinweis/Beschreibung (Englisch) – Bitte beachten Sie, dass dieser Text nur in den Preisinformationen zur Messebeteiligung, nicht aber im Anmeldeformular selbst sichtbar ist.',
      },
      equipment: {
        title: 'Standausstattung und Zusatzleistungen',
        optional: 'Optional',
        optionalNotice: 'Optional: Hier können Sie zusätzliche Positionen anlegen, die von den Ausstellern kostenfrei oder kostenpflichtig gebucht werden können.',
        previewNotice: 'Nachfolgend sehen Sie die Vorschau der Eingabefelder für Aussteller.',
        amountSelectable: 'Mengenangabe möglich',
        amountSelectableText: 'Der Aussteller kann die gewünschte Menge für diese Position eingeben.',
        mandatorySelection: 'Pflichtauswahl',
        mandatoryBooking: 'Pflichtbuchung',
        mandatoryBookingMust: 'Diese Position <u>muss</u> durch den Aussteller gewählt bzw. gebucht werden.',
        mandatoryBookingCan: 'Diese Position <u>kann</u> durch den Aussteller gewählt bzw. gebucht werden.',
        multipleSelectionPossible: 'Mehrfachauswahl möglich',
        multipleSelectionPossibleOnlyOnce: 'Der Aussteller kann diese Position <u>nur einmal</u> in der gewünschten Menge buchen.',
        multipleSelectionPossibleSeveralTimes: 'Der Aussteller kann diese Position <u>mehrfach</u> in der gewünschten Menge buchen.',
        additionalTextInput: 'Zusätzliche Texteingabe erforderlich',
        additionalTextInputNotice: 'Es ist eine zusätzliche Angabe durch den Aussteller für diese Position erforderlich, z.B. ein Name',
        additionalTextInputLabelling: 'Beschriftung des Eingabefeldes',
        additionalTextInputLabelling_de: 'Beschriftung des Eingabefeldes (deutsch)',
        additionalTextInputLabelling_en: 'Beschriftung des Eingabefeldes (englisch)',
        addAnotherOption: 'weitere Option hinzufügen',
        alertDeleteOption: 'Diese Option wirklich löschen?',
        editEquipment: 'Standausstattung / Zusatzleistung bearbeiten',
        addEquipment: 'Standausstattung / Zusatzleistung hinzufügen',
        label: 'Bezeichnung',
        label_de: 'Bezeichnung (deutsch)',
        label_en: 'Bezeichnung (englisch)',
        description: 'Beschreibung',
        description_de: 'Beschreibung deutsch',
        description_en: 'Beschreibung englisch',
      },
      exhibitionArea: {
        title: 'Ausstellungsbereich',
        title_de: 'Ausstellungsbereich (deutsch)',
        title_en: 'Ausstellungsbereich (englisch)',
        alertDeleteExhibitionArea: 'Diesen Ausstellungsbereich wirklich löschen?',
        addExhibitionArea: 'Ausstellungsbereich hinzufügen',
        editExhibitionArea: 'Ausstellungsbereich bearbeiten',
        warning: 'Optional: Aussteller können <u>einen</u> der hier aufgeführten Ausstellungsbereiche für Ihre gewünschte Standplatzierung wählen.',
      }
    },
    contactData: {
      title: 'Aussteller',
      declaration: 'Wir erklären, dass wir inklusive dieser Anmeldung',
      participatedLessThanFiveTimes: 'Hiermit erklären wir, dass wir inklusive dieser Anmeldung seit Juli 2021 nicht mehr als vier Mal am deutschen Gemeinschaftsstand auf dieser Messe teilgenommen haben.',
      participatedMoreThanFourTimes: 'seit Juli 2021 zum fünften Mal oder öfter am deutschen Gemeinschaftsstand auf dieser Messe teilgenommen haben.',
      incorrectInformationAwareness: 'Mir ist bekannt, dass falsche Angaben den Ausschluss von weiteren Teilnahmen am Auslandsmesseprogramm des Bundes zur Folge haben können.',
      contactPerson: 'Ansprechpartner',
      companyData: 'Unternehmensdaten',
    },
    bookingTypeSelect: {
      title: 'Servicepaket oder Ausstellungsfläche',
      spaceLabel: 'Ich möchte eine Ausstellungsfläche mit oder ohne Standbau buchen',
      packageLabel: 'Ich möchte eines der angebotenen Teilnahmepakete buchen',
    },
    exhibitionSpace: {
      title: 'Ausstellungsfläche',
      withStandConstruction: 'mit Standbau',
      withoutStandConstruction: 'ohne Standbau',
      spacePerWithStandConstruction: 'mit Standbau',
      spacePerWithoutStandConstruction: 'ohne Standbau',
      standSpacePrice: 'Preis Ausstellungsfläche',
      hallSpace: 'Ausstellungsfläche',
      openSpace: 'Freifläche',
      costOverview: 'Kostenübersicht',
      sizeError: 'Bitte geben Sie einen Wert zur Ausstellungsfläche ein, der den Vorgaben entspricht.',
      spaceRangeError: 'Die Ausstellungsflächen müssen lückenlos sein',
      allowedCoexhibitor: 'Unteraussteller erlaubt'
    },
    servicePackage: {
      title: 'Servicepaket',
      hereIsNoServicePackage: 'Es ist kein Servicepaket angegeben',
    },
    exhibitionArea: {
      title: 'Ausstellungsbereich',
      areasChosen: 'kein Bereich gewählt | 1 Bereich gewählt | {amount} Bereiche gewählt',
      thereIsNoExhibitionArea: 'Es ist kein Ausstellungsbereich angegeben',
    },
    exhibitionGoods: {
      title: 'Ausstellungsgüter',
      addGoodsSubtitle: 'Legen Sie ein neues Austellungsgut mit Maß und Gewicht an',
      exhibitsSpecified: 'Keine Ausstellungsgüter angegeben | 1 Exponat angegeben | {amount} Exponate angegeben',
    },
    equipment: {
      title: 'Standausstattung und Zusatzleistungen',
      servicesSelected: 'Keine Auswahl | 1 gewählt | {amount} gewählt',
      badges: 'Abzeichen',
      multiselectable: 'mehrfach wählbar',
      specification: 'Spezifikation',
      quantity: 'Menge',
      unit: 'Einheit',
      description: 'Bezeichnung',
      unitPrice: 'Einzelpreis',
      totalPrice: 'Gesamtpreis',
      participantName: 'Name des Teilnehmers',
    },
    subExhibitors: {
      title: 'Unteraussteller',
      viewSubExhibitors: 'Unteraussteller ansehen',
      addSubExhibitors: 'Unteraussteller hinzufügen',
      addSubExhibitorsSubtitle: 'Legen Sie einen neuen Unteraussteller mit Ansprechpartner an',
      subExhibitor: 'Kein Unteraussteller | 1 Unteraussteller | {amount} Unteraussteller',
      contactPerson: 'Ansprechpartner',
      exhibitionGoods: 'Ausstellungsgüter',
      note: 'Für die Unteraussteller gelten die Allgemeinen Teilnahmebedingungen (ATB), soweit diese anwendbar sind. Die Teilnahme von Unterausstellern ist kostenpflichtig. Vertragsbeziehungen bestehen auch nach Zulassung ausschließlich zwischen der Durchführungsgesellschaft und dem Aussteller. Der Aussteller haftet für ein Verschulden seiner Unteraussteller wie für eigenes Verschulden.<br><br><b class="text-small">Leistungen für Unteraussteller:</b><br>• Nutzung des Bundesinformationsstandes<br>• Begleitmaßnahmen entsprechend denen für die Aussteller.'
    },
    financialSupport: {
      title: 'Doppelförderung / Beteiligung der öffentlichen Hand',
      declaration: {
        fromAccreditation: 'Diese Angaben wurden aus Ihrer Akkreditierung übernommen und können hier nicht verändert werden:',
        title: 'Erklärung zu Doppelförderung bzw. der Beteiligung der öffentlichen Hand',
        list: {
          notReceiveFunding: 'Wir erklären, dass wir keine institutionelle Förderung aus öffentlichen Mitteln erhalten.',
          notReceiveFurtherPublicFunds: 'Wir erklären, dass wir für die Teilnahme an dieser Messe keine weiteren öffentlichen Mittel aus Projektförderung erhalten.',
          companyIsNotAuthority: 'Wir erklären, dass unser Unternehmen keine Bundes-, Landes- oder Kommunalbehörde, Landesförderinstitut oder sonstige juristische Person des öffentlichen Rechts ist.',
          noReligionInterestInCompany: 'Wir erklären, dass an unserem Unternehmen keine Religionsgemeinschaft(en) oder juristische Person(en) des öffentlichen Rechts einzeln oder zusammen, direkt oder indirekt mehrheitlich beteiligt sind.',
        },
      },
    },
    costOverview: {
      title: 'Kostenübersicht',
      totalCosts: 'Gesamtkosten',
      position: 'Position',
      participationPrice: 'Beteiligungspreis und Gebühren',
      fees: {
        title: 'Gebühren',
        hallSpace: 'Ausstellungsfläche',
        packageFee: 'Paketgebühr',
        registrationFee: 'Registrierungsgebühr',
        subExhibitorRegistrationFee: 'Unteraussteller-Registrierungsgebühr',
        subExhibitorMediaEntry: 'Unteraussteller Medieneintrag',
        subExhibitorMediaEntry2: 'Unteraussteller Medieneintrag 2',
      },
      pricesDontIncludeTaxes: 'Die genannten Beteiligungspreise verstehen sich zuzüglich der ggf. gesetzlich anfallenden deutschen Umsatzsteuer und der ggf. gesetzlich anfallenden ausländischen Steuern (z.B. VAT, Sales Tax, etc.) auf die Leistungen der Durchführungsgesellschaft gegenüber dem Aussteller. Die Beteiligungspreise decken nur einen Teil der Gesamtkosten der Leistungen. <b>Die Verpflichtung zur Zahlung von 20% der genannten Gesamtkosten entsteht mit dieser Anmeldung.</b> Dieser Betrag wird mit Erhalt einer entsprechenden Anzahlungsrechnung fällig. Der in der Anzahlung ausgewiesene Betrag ist unter Angabe der Veranstaltung auf das in der Anzahlungsrechnung angegebene Konto zu überweisen. Bei Zuweisung der Fläche entsteht die Verpflichtung zur Zahlung des Restbetrages. Dieser ist sofort nach Erhalt der Endrechnung fällig. Der in der Endrechnung ausgewiesene Betrag ist unter Angabe der Veranstaltung auf das in der Endrechnung angegebene Konto zu überweisen.<br><br>Der Kostenberechnung liegt Ihre folgende Erklärung zur Doppelförderung bzw. Beteiligung der öffentlichen Hand zugrunde:<br>',
      noInstitutional: '•	Wir erhalten keine institutionelle Förderung aus öffentlichen Mitteln.<br>',
      noAuthority: '•	Wir sind keine Bundes-, Landes- oder Kommunalbehörde, Landesförderinstitut oder sonstige juristische Person des öffentlichen Rechts.<br>',
      noReligion: '• An unserem Unternehmen sind keine Religionsgemeinschaft(en) oder juristische Person(en) des öffentlichen Rechts einzeln oder zusammen, direkt oder indirekt mehrheitlich beteiligt.<br>',
      noFurtherFunds: '• Wir erhalten für die Teilnahme an dieser Messe keine weiteren öffentlichen Mittel aus Projektförderung.<br>',
      lessThenFive: '• Hiermit erklären wir, dass wir inklusive dieser Anmeldung seit Juli 2021 nicht mehr als vier Mal am deutschen Gemeinschaftsstand auf dieser Messe teilgenommen haben.<br>',
      furtherFunds: '• Wir erhalten für die Teilnahme an dieser Messe weitere öffentlichen Mittel aus Projektförderung.<br>',
      moreThenFour: '• Hiermit erklären wir, dass wir inklusive dieser Anmeldung seit Juli 2021 mehr als vier Mal am deutschen Gemeinschaftsstand auf dieser Messe teilgenommen haben.<br>',
      institutional: '•	Wir erhalten institutionelle Förderung aus öffentlichen Mitteln.<br>',
      authority: '•	Wir sind eine Bundes-, Landes- oder Kommunalbehörde, Landesförderinstitut oder sonstige juristische Person des öffentlichen Rechts.<br>',
      religion: '• An unserem Unternehmen sind (eine) Religionsgemeinschaft(en) oder (eine) juristische Person(en) des öffentlichen Rechts einzeln oder zusammen, direkt oder indirekt mehrheitlich beteiligt.<br>',
      waiver: 'Verzicht: Ein Verzicht auf einzelne firmenspezifische oder allgemeine Leistungen begründet keinen Anspruch auf Minderung des Beteiligungspreises. Auf einheitliche Gestaltungselemente kann in keinem Fall verzichtet werden.'
    },
    billingAddress: {
      title: 'Rechnungsanschrift',
      invoiceAddress: 'Rechnungsanschrift',
      company: 'Firma oder Organisation',
      responsible: 'Abteilung oder Ansprechpartner',
      email: 'E-Mail',
      street: 'Straße und Hausnummer',
      postalcode: 'PLZ',
      city: 'Ort',
      country: 'Land',
    },
    termsAndConditions: {
      title: 'Teilnahmebedingungen',
      list: {
        recognizingParticipationConditionsConfirmation: 'Wir bestätigen hiermit rechtsverbindlich, dass wir die Teilnahmebedingungen anerkennen.',
        specialParticipationConditions: 'Besondere Teilnahmebedingungen (BTB) – {tradeFairName}',
        undertakingOnlyAllowedProducts: 'Wir bestätigen hiermit rechtsverbindlich, dass wir die Allgemeinen Teilnahmebedingungen (ATB) anerkennen.',
      },
    },
    checkAndSend: {
      title: 'Prüfen und absenden',
      error: 'Bitte führen Sie alle anderen Schritte aus',
      bmel: '<b>Deutsche Erzeugnisse</b><br>In der amtlichen deutschen Beteiligung dürfen als Ausstellungsware nur deutsche Erzeugnisse vorhanden sein, ausgestellt und angeboten werden. Dennoch mitgebrachte ausländische Erzeugnisse hat der betreffende Aussteller sofort auf seine Kosten aus dem Stand zu entfernen. Für jeden Fall der Nichtentfernung fällt eine Vertragsstrafe von 250,- € an, bei Getränken von 25,- € pro Flasche an, die sofort zu zahlen ist.<br><br>Verweigert der Aussteller gleichwohl die Entfernung ausländischer Erzeugnisse, so veranlasst der Ausstellungsleiter die Entfernung auf Kosten des Ausstellers. Aussteller, die der Aufforderung des Ausstellungsleiters nicht nachkommen, bestimmte Exponate, die den Förderungsvoraussetzungen nicht entsprechen, aus dem Stand zu entfernen, können sich künftig an offiziellen Firmengemeinschaftsausstellungen des Bundesministeriums für Ernährung und Landwirtschaft nicht mehr beteiligen.',
      message: 'Zusätzliche Nachricht und Hinweise',
      registrationRules: {
        registrationWillBeBindingIf: 'Hiermit bestätige ich:',
        reviewCarriedOutByCompany: 'Diese Anmeldung ist gemäß den von mir zuvor bestätigten Allgemeinen Teilnahmebedingungen für Beteiligungen des Bundes an Messen und Ausstellungen im Ausland (ATB) verbindlich.',
        paidDownPaymentInvoice: 'Die Anmeldung bindet mich als Aussteller unabhängig von der Zulassung. Bedingungen und Vorbehalte bei der Anmeldung sind nicht zulässig und gelten als nicht gestellt.',
      },
      payment: {
        totalCosts: 'Gesamtkosten/Ihr Beteiligungspreis',
        plusTaxes: 'zzgl. gesetzlicher in- und ausländischer Steuern',
        taxesDescription: 'Mit unserer Anmeldung bestätigen wir, dass wir die Allgemeinen Teilnahmebedingungen (ATB) des Bundes an Messen und Ausstellungen im Ausland anerkennen. Die ATB können unter www.german-pavilion.com/ATB abgerufen werden.<br><br>Mit unserer Anmeldung erklären wir, dass über unser Vermögen kein Insolvenzverfahren beantragt oder eröffnet worden ist bzw. wir keine eidesstattliche Versicherung nach § 802c Zivilprozessordnung oder § 284 Abgabenordnung 1977 abgegeben haben bzw. zu deren Abgabe verpflichtet sind.'
      },
      submitSuccessModal: {
        title: 'Danke für Ihre Anmeldung.',
        message: 'Ihre Daten wurden zur Überprüfung an die Durchführungsgesellschaft übermittelt. Außerdem erhalten Sie eine Bestätigungs-E-Mail.',
      }
    },
    registrations: 'Anmeldungen',
    confirmed: 'Bestätigte Anmeldungen',
    registrationsInTotal: 'Anmeldungen gesamt',
    unconfirmed: 'unbestätigt',
    company: 'Firma',
    haUa: 'HA/UA',
    ha: 'HA',
    ua: 'UA',
    contactPerson: 'Ansprechpartner',
    registeredOn: 'Angemeldet am',
    registrationConfirmed: 'Anmeldung bestätigt',
    coExhibitor: 'Unteraussteller',
    coExhibitors: 'Unteraussteller',
    booking: 'Buchung',
    status: 'Status',
    changed: 'Geändert',
    fullPayer: 'Preis ab 5. Teilnahme',
    addressAndContactDetails: 'Anschrift und Kontaktdaten',
    street: "Straße",
    postcode: "Postleitzahl",
    city: "Stadt",
    country: "Land",
    federalState: "Bundesstaat",
    fax: 'Fax',
    email: 'Email',
    website: 'Webseite',
    contactSalutation: 'Ansprechpartner Anrede',
    contactFirstname: 'Ansprechpartner Vorname',
    contactSurname: 'Nachname der Kontaktperson',
    contactPhone: 'Telefonnummer der Kontaktperson',
    contactFax: 'Fax der Kontaktperson',
    contactEmail: 'Ansprechpartner E-Mail',
    companyName: 'Name der Firma',
    billingaddressCompany: 'Rechnungsadresse Firma',
    billingaddressPerson: 'Rechnungsabteilung oder Kontaktperson',
    billingaddressEmail: 'Rechnungs-E-Mail-Adresse',
    billingaddressStreet: 'Rechnungsadresse Straße',
    billingaddressPostcode: 'Postleitzahl der Rechnungsadresse',
    billingaddressCity: 'Rechnungsadresse Stadt',
    billingaddressCountry: 'Land der Rechnungsadresse',
    registeredConfirmed: 'Anmeldung bestätigt',
    subExhibitor: 'Unteraussteller',
    parentExhibitor: 'Hauptaussteller',
    bookedSpace: 'Gebuchte Fläche ㎡',
    bookedType: 'Buchungstyp',
    bookedService: 'Gebuchter Dienst',
    bookedAdditionalServices: 'Standausstattung und Zusatzleistungen',
    bookedGoods: 'Ausstellungswaren',
    bookedAreaTitle: 'Ausstellungsbereich',
    standConstructionIncluded: 'Standbau inklusive',
    pricingModel: 'Preismodell',
    additionNote: 'Zusätzliche Anmerkung des Ausstellers',
    bookedExhibitionSpace: 'Gebuchte Ausstellungsfläche',
    standEquipmentAndAdditionalServices: 'Standausstattung und Zusatzleistungen',
    phone: 'Telefon',
    expressionOfInterest: 'Interessensbekundung',
    signUp: 'Anmeldung',
    actionsByProjectManager: {
      confirmed: 'Anmeldung bestätigt durch {manager} (Projektleiter)',
      cancelled: 'Anmeldung storniert durch {manager} ({role})',
      rejected: 'Anmeldung abgelehnt durch {manager} (Projektleiter)',
    },
    projectManager: 'Projektleiter',
    notificationOfTheParticipant: 'Mitteilung des Ausstellers',
    takeIntoAccountWhenRegistering: 'Bitte berücksichtigen Sie bei unserer Anmeldung noch dies, das und jenes. Vielen Dank!',
    editSteps: {
      template: {
        template: 'Vorlage',
        apply: 'Vorlage anwenden',
        acceptMessage: 'Wenn Sie die Vorlage anwenden, werden alle bisherigen Einstellungen verworfen. Möchten Sie fortfahren?'
      },
      DynAMPConfiguration: {
        title: 'Lade Dyn AMP',
        configurationReceived: 'Konfiguration aus Dyn AMP empfangen',
        receivingConfiguration: 'Empfang Dyn AMP Konfiguration',
      },
      configurationRegistration: {
        title: 'Bearbeiten',
        text: 'Bitte passen Sie nachfolgend das Anmeldeformular und die möglichen Buchungsoptionen an. Dazu können Sie zunächst eine der vorhandenen Vorlagen übernehmen. Anschließend können Sie die einzelnen Einstellungen und Konditionen anpassen.',
      },
      projectManagement: {
        title: 'Projektleitung',
        contactPersonInRegistrationForm: 'Ansprechpartner im Anmeldeformular',
      },
      deadline: {
        title: 'Deadline',
        registrationDeadline: 'Anmeldeschluss',
      },
      approvalAndPublication: {
        title: 'Freigabe & Veröffentlichung',
        examinationAndApprovalIsRequired: 'Um die Online-Anmeldung starten zu können ist die Prüfung und Freigabe durch BMWK/BAFA bzw. BMEL erforderlich.',
        approvalGranted: 'Freigabe durch BMWK/BAFA bzw. BMEL wurde erteilt',
        disablingFormAttention: 'Achtung: Das Formular kann nicht mehr bearbeitet werden, nachdem Sie auf "Anmeldung öffnen" klicken.',
      },
    },
    stepsIsErrored: 'Bitte führen Sie diesen Schritt aus',
    supervisorPrintHint: 'Die Anmeldung erfolgt über die bisherigen Anmeldeformulare. Ausstelleranmeldungen sind daher hier nicht einsehbar.',
    link: {
      useRegistrationLink: 'Bitte verwenden Sie die nachfolgende URL, um direkt auf die Onlineanmeldung zu dieser Veranstaltung zu verlinken.',
      onlineRegistrationLink: 'Link zur Online-Anmeldung',
    },
    registrationFormPreview: 'Vorschau-Ansicht des Anmeldeformulars',
    registrationFormAlertTitle: 'Kostenübersicht in der Vorschau keine Referenz',
    registrationFormAlert: 'Die Kostenübersicht wird in der Vorschau zur Zeit nicht vollständig abgebildet. Alle Gebühren, Standausstattung und Zusatzleistungen, die Sie zuvor angelegt haben, werden dem Aussteller aber im Live-Anmeldeformular angezeigt.',
    actions: {
      view: 'Anmeldung ansehen',
      returnForProcessing: 'Anmeldung zur Bearbeitung zurückgeben',
      open: 'Anmeldung öffnen',
      edit: 'Anmeldung bearbeiten',
      confirm: 'Anmeldung bestätigen',
      cancel: 'Anmeldung stornieren',
      reject: 'Anmeldung ablehnen',
      saveAsPDF: 'Als PDF speichern',
      showForm: 'Anmeldeformular anzeigen',
    },
    statuses: {
      inPreparation: 'Anmeldung in Vorbereitung',
    },
    funding: {
      funded: 'Gefördert',
      notFunded: 'Nicht gefördert',
      publicSector: 'Öffentliche Hand',
    },
    tradeFairNotSelected: 'Sie haben keine Messe ausgewählt',
    noEndDate: 'Der exakte Messezeitraum ist noch nicht bekannt, daher kann die Messe noch nicht konfiguriert werden.',
    participationSubmitted: 'Antrag eingereicht wurde',
    registrationIsNotCarriedOutOnline: 'Die Anmeldung zu dieser Messebeteiligung wird nicht über die Online-Anmeldung durchgeführt. Die Anmeldeunterlagen wurden mit DynAMP erstellt und können auf der Seite des AUMA heruntergeladen werden:',
    firstCompleteSetupInAUMA: 'Um die Onlineanmeldung für diese Veranstaltung einrichten zu können, schließen Sie bitte zunächst die Einrichtung im DynAMP des AUMA ab. Wählen Sie dabei aus, dass der Anmeldeprozess als Onlineanmeldung vorgesehen ist.',
    onlineRegistration: 'Online-Anmeldung',
    openOnlineRegistration: 'Anmeldeformular',
    deadlineStatuses: {
      running: 'Die Online-Anmeldung läuft derzeit. Anmeldeschluss: {date}',
      expired: 'Die Anmeldefrist ist abgelaufen. Anmeldeschluss: {date}',
    },
    fillExhibitorFirst: 'Die Teilnahme ist noch nicht erstellt. Bitte füllen Sie zuerst das Ausstellerformular aus.',
    foreignRepresentation: 'Ausländische Vertretung',
    foreignBranch: 'Ausländische Niederlassung',
    relatedCompany: 'Verbundenes deutsches Unternehmen',
    notifyHeader: 'Interessenten benachrichtigen',
    notifyText: 'Senden Sie eine E-Mail an alle an dieser Messe Interessierten Unternehmen und informieren Sie sie darüber, dass eine Anmeldung zur Messe möglich ist. Die E-Mail beinhaltet auch den Link zur Anmeldung.',
    notifyButton: 'E-Mail an Interessenten senden',
    notifyCount: '{amount} Interessenten wurden am {date} um {time} (UTC) benachrichtigt.',
    totalSpace: 'Gesamte gebuchte Ausstellerfläche'
  },
}

<template>
  <v-container v-if="participation.id && fair.id" fluid>
    <v-card class="elevation-1 mb-5 rounded-0">
      <v-card-title class="px-5">
        <h3 class="primary--text">{{ fair.attributes.name_display }}</h3>
        <v-col class="text-right">
          <v-chip class="secondary white--text ml-2" small>
            {{ $t('tables.participation.pageStatus.' + participation.attributes.status) }}
          </v-chip>
        </v-col>
      </v-card-title>
      <v-card-text class="text--primary px-5">
        <v-row justify="space-around">
          <v-col cols="3">
            <small>{{ $t('tables.tradeFair.date') }}</small>
            <p>
              {{ fair.attributes.start_date | dateByLocale }} -
              {{ fair.attributes.end_date | dateByLocale }}
            </p>
          </v-col>

          <v-col cols="3">
            <small>{{ $t('tables.tradeFair.city') }}</small>
            <p>{{ fair.attributes.city }}, {{ fair.relationships.country.translations[$i18n.locale].country }}</p>
          </v-col>

          <v-col v-if="fair.attributes.registration_deadline" cols="3">
            <small>{{ $t('tables.tradeFair.registrationDeadline') }}</small>
            <p>
              {{ fair.attributes.registration_deadline | dateByLocale }}
            </p>
          </v-col>

          <v-col cols="3">
            <small>{{ $t('tables.tradeFair.participationForm') }}</small>
            <p>{{ fair.attributes.type.type }}</p>
          </v-col>

          <v-col
              cols="12"
              class="pt-0 mt-0"
          >
            <v-btn :to="{ name: 'exhibitor.participations.application', params: { id: fair?.id } }" color="secondary" class="elevation-0 mr-3"
                   v-if="fair.attributes.flag_registration_is_open && !participation.attributes.submitted_at" outlined>
              {{ $t('tables.participation.form.viewAndEditRegistration') }}
            </v-btn>
            <v-btn v-else :to="{ name: 'exhibitor.participations.application', params: { id: fair?.id } }" color="secondary" class="elevation-0 mr-3" outlined>
              {{ $t('tables.participation.form.viewRegistration') }}
            </v-btn>

            <v-btn color="secondary" class="elevation-0" v-if="participation.attributes.approved_at"
                   @click="downloadRegistrationConfirmation({ participation: participation, tradeFair: fair })"
                   outlined>
              <v-icon class="mr-2" size="20">fal fa-file-pdf</v-icon>
              {{ $t('tables.participation.form.downloadRegistrationConfirmation') }}
            </v-btn>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-expansion-panels
        v-if="fair.id"
        class="mb-10 elevation-0 rounded-0"
        v-model="panels"
        multiple
        flat
    >
      <v-expansion-panel
          v-if="fair.attributes.registration_type === 'online' && fair.attributes.flag_registration_is_open"
          class="elevation-1 rounded-0"
      >
        <v-expansion-panel-header>
          <h3>
            {{
              $t('tables.participation.form.onlineRegistrationAndParticipation')
            }}
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            {{
              $t(
                  'tables.participation.form.registerForParticipationInThisTradeFairInFewSimpleSteps'
              )
            }}
          </p>

          <v-stepper vertical flat class="elevation-0">
            <v-stepper-step
                step="1"
                complete-icon="fal fa-check"
                edit-icon="fal fa-check"
                complete
                editable
            >
              <h4>
                {{ $t('tables.participation.form.makeNoteOfYourParticipationInTheTradeFair') }}
              </h4>
            </v-stepper-step>
            <v-stepper-content step="1" class="pb-5">
              <p>
                {{ $t('tables.participation.form.areYouInterestedInParticipatingInThisTradeFair') }}
              </p>
              <HelpButton class="mb-3" :icon-size="20" :help-entry-id="3"/>
              <v-checkbox
                  v-model="fair.included.is_favorite"
                  :disabled="form.loading || participation && !isAvailableToFavorite({ status: participation.attributes.status })"
                  :label="$t('tables.participation.form.iWouldLikeToReserveThisEventWithoutObligation')"
                  @click="say({ tradeFair: fair, participation: participation })"
              />

              <v-divider></v-divider>
            </v-stepper-content>

            <v-stepper-step
                step="2"
                complete-icon="fal fa-check"
                edit-icon="fal fa-check"
                :complete="!!company.accreditationApprovedAt"
                editable
            >
              <h4>{{ $t('tables.participation.form.accreditation') }}</h4>
            </v-stepper-step>

            <v-stepper-content step="2" class="pb-5">
              <p>
                {{ $t('tables.participation.form.onceYouHaveBeenGrantedAnAccreditation') }}
              </p>

              <p
                  class="success--text font-weight-bold"
                  v-if="company.accreditationApprovedAt"
              >
                {{ $t('tables.participation.form.yourAccreditationWasGrantedOn') }}
                {{ company.accreditationApprovedAt | dateTimeByLocale }}
              </p>

              <p
                  class="warning--text font-weight-bold"
                  v-if="!company.accreditationApprovedAt"
              >
                {{ $t('tables.participation.form.yourAccreditationHasNotYetBeenCompleted') }}
              </p>

              <v-btn
                  color="secondary"
                  class="mb-5 elevation-0"
                  to="/exhibitor/accreditation"
                  small
                  v-if="!company.accreditationApprovedAt"
              >
                {{ $t('tables.participation.form.carryOutAccreditation') }}
              </v-btn>

              <v-divider></v-divider>
            </v-stepper-content>

            <v-stepper-step
                step="3"
                complete-icon="fal fa-check"
                edit-icon="fal fa-check"
                :complete="!!participation.attributes.submitted_at"
                editable
            >
              <h4>{{ $t('tables.participation.form.registration') }}</h4>
            </v-stepper-step>

            <v-stepper-content step="3" class="pb-5">
              <p>
                {{ $t('tables.participation.form.registerAsAnExhibitorForTheJoint', { tradeFair: fair.attributes.name_display })}}
              </p>

              <p class="success--text font-weight-bold">
                <v-chip class="secondary white--text ml-auto" small>
                  {{ $t('tables.participation.pageStatus.' + participation.attributes.status) }}
                </v-chip>
              </p>

              <p>
                <small>{{ $t('tables.participation.form.registeredCoExhibitors') }}</small
                ><br/>
                <span
                    v-for="coexhibitor in participation.relationships.coexhibitors"
                    :key="coexhibitor.id"
                >
                  {{ coexhibitor.company }}<br/>
                </span>
                <small v-if="!participation.relationships.coexhibitors.length">
                  {{ $t('tables.participation.form.registeredCoExhibitorsNothing') }}
                </small>
              </p>

              <v-btn
                  v-if="fair.attributes.flag_registration_is_open && !participation.attributes.submitted_at"
                  :to="{ name: 'exhibitor.participations.application', params: { id: fair?.id } }"
                  color="secondary"
                  small
                  class="mb-5 mr-4 elevation-0"
              >
                {{ $t('tables.participation.form.viewAndEditRegistration') }}
              </v-btn>
              <v-btn
                  v-else
                  :to="{ name: 'exhibitor.participations.application', params: { id: fair?.id } }"
                  color="secondary"
                  small
                  class="mb-5 mr-4 elevation-0"
              >
                {{ $t('tables.participation.form.viewRegistration') }}
              </v-btn>
              <v-divider></v-divider>
            </v-stepper-content>

            <v-stepper-step
                step="4"
                complete-icon="fal fa-check"
                edit-icon="fal fa-check"
                :complete="!!participation.attributes.approved_at"
                editable
            >
              <h4>
                {{ $t('tables.participation.form.registrationConfirmation') }}
              </h4>
            </v-stepper-step>

            <v-stepper-content step="4" class="pb-5">
              <p>
                {{ $t('tables.participation.form.yourRegistrationWillBeCheckedBy', {organizer: fair.relationships.organizer.name}) }}
              </p>
              <p
                  v-if="participation.attributes.approved_at"
                  class="success--text font-weight-bold"
              >
                {{ $t('tables.participation.form.yourRegistrationWasSuccessfullyConfirmedOn', {date: formatDateByLocale(participation.attributes.approved_at)})}}
              </p>
              <p
                  v-else-if="participation.attributes.submitted_at"
                  class="warning--text font-weight-bold"
              >
                {{ $t('tables.participation.form.examinationAndRegistrationConfirmationPending') }}
              </p>
              <p v-else class="warning--text font-weight-bold">
                {{ $t('tables.participation.form.examinationAndRegistrationSendingPending') }}
              </p>

              <v-list v-if="participation.attributes.approved_at">
                <v-list-item>
                  <v-list-item-icon>confirmationPDF
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      <a href="#" @click.prevent="downloadRegistrationConfirmation({ participation: participation })">
                        {{ $t('tables.participation.form.downloadRegistrationConfirmation') }}
                      </a>
                      <br/>
                      ({{ participation.attributes.approved_at | dateByLocale }})
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <p>
                <small>
                  {{ $t('tables.participation.form.ifYouHaveAnyQuestionsAboutRegistration') }}:
                </small>
                <br/>
                {{ fair.relationships.organizer.name }}<br/>
                {{ fair.attributes.contact_name }}<br/>
                {{ $t('tables.participation.form.mail') }}:
                <a :href="'mailto:' + fair.attributes.contact_email">{{ fair.attributes.contact_email }} </a ><br/>
                {{ $t('tables.participation.form.phone') }}:
                {{ fair.attributes.contact_phone }}
              </p>

              <v-divider></v-divider>
            </v-stepper-content>

            <v-stepper-step
                step="5"
                complete-icon="fal fa-check"
                edit-icon="fal fa-check"
                :complete="fair.included.is_finished && !!participation.attributes.approved_at"
                editable
            >
              <h4>
                {{ $t('tables.participation.form.tradeFairPreparationAndParticipation') }}
              </h4>
            </v-stepper-step>
            <v-stepper-content step="5" class="pb-5">
              <p>
                {{ $t('tables.participation.form.onceYouHaveConfirmedYourRegistration', {organizer: fair.relationships.organizer.name} ) }}
              </p>
              <p class="success--text font-weight-bold">
                {{ $t('tables.participation.form.startOfTheFair') }}:
                {{ fair.attributes.start_date | dateByLocale }}
              </p>
              <p>
                <small>
                  {{ $t('tables.participation.form.ifYouHaveAnyQuestionsAboutTradeFair') }}:
                </small>
                <br/>
                {{ fair.attributes.name_display }}<br/>
                {{ fair.attributes.contact_name }}<br/>
                {{ $t('tables.participation.form.mail') }}:
                <a :href="'mailto:' + fair.attributes.contact_email">
                  {{ fair.attributes.contact_email }}
                </a>
                <br/>
                {{ $t('tables.participation.form.phone') }}:
                {{ fair.attributes.contact_phone }}
              </p>
              <v-divider/>
            </v-stepper-content>
          </v-stepper>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel
          v-if="fair.attributes.registration_type === 'print' && fair.attributes.flag_registration_is_open"
          class="elevation-1 rounded-0"
      >
        <v-expansion-panel-header>
          <h3>
            {{ $t('tables.participation.form.registrationAndParticipation') }}
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            {{ $t('tables.participation.form.atPresentOnlineRegistrationVia') }}
          </p>
          <p>
            {{ $t('tables.participation.form.registrationDocumentsOnTheAUMA') }}
            <a :href="fair.attributes.link_auma_database">{{ fair.attributes.link_auma_database }}</a>
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <participation-and-conditions
          v-if="!(fair.attributes.registration_type === 'print' && !fair.attributes.flag_registration_is_open )"
          :company="company"
          :fair="fair"
      />

      <execution-company
          :trade-fair="fair"
          :organizer="fair.relationships.organizer"
      />

      <fair-information :fair="fair"/>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ParticipationAndConditions from '@/components/common/TradeFair/ParticipationAndConditions'
import ExecutionCompany from '@/components/common/TradeFair/ExecutionCompany'
import FairInformation from '@/components/common/TradeFair/FairInformation'
import date from '@/mixins/date'
import {PARTICIPATION_STATUS} from '@/enums/participationStatus'

export default {
  name: 'ExhibitorParticipationDetail',

  mixins: [date],

  components: {
    ParticipationAndConditions,
    ExecutionCompany,
    FairInformation,
  },

  data() {
    return {
      panels: [],

      fair: {},
      participation: {},

      form: {
        loading: false,
      },

      PARTICIPATION_STATUS
    }
  },

  computed: {
    ...mapGetters('company', ['company']),
  },

  async created() {
    this.load()
  },

  methods: {
    ...mapActions('participation', [
      'setNotedTradeFair',
      'unsetNotedTradeFair',
      'downloadRegistrationConfirmation',
    ]),

    ...mapActions('auth', ['fetchCompany']),

    async load() {
      this.form.loading = true

      let response = (await this.$axios.get(`/api/participations/${this.$route.params.id}`, {
        params: {
          'languages': '*',
          'include': [
            'tradeFair',
            'tradeFair.country',
            'tradeFair.industrySectors',
            'tradeFair.exhibitionAreas',
            'tradeFair.spaces',
            'tradeFair.packages',
            'tradeFair.services',
            'tradeFair.organizer',
            'coexhibitors',
          ],
          'sort': '',
        },
      })).data

      this.fair = response.data.relationships.trade_fair
      this.participation = response.data

      this.form.loading = false
    },


    async say({participation, tradeFair}) {
      if (this.loader) {
        return
      }

      if (participation && !this.isAvailableToFavorite({status: participation.status})) {
        return
      }

      this.loader = true
      if (tradeFair.isFavorite) {
        if (await this.setNotedTradeFair(tradeFair.id)) {
          await this.fetchParticipations()
        }
      } else {
        if (await this.unsetNotedTradeFair(tradeFair.id)) {
          await this.fetchParticipations()
        }
      }
      this.loader = false
    },

    isAvailableToFavorite({status}) {
      if ([
        PARTICIPATION_STATUS.REJECTED,
        PARTICIPATION_STATUS.CANCELLED,
        PARTICIPATION_STATUS.SUBMITTED,
        PARTICIPATION_STATUS.REPROCESSED,
        PARTICIPATION_STATUS.APPROVED
      ].includes(status)) {
        return false
      }

      return true
    },
  },

  watch: {
    async '$route.params.id'() {
      this.load()
    }
  },
}
</script>

export default {
  company: {
    company: 'Firma',
    companies: 'Unternehmen',
    companyData: 'Unternehmensdaten',
    overviewAndAdministrate: 'Übersicht und Verwaltung der registrierten Unternehmen.',
    street: 'Straße',
    postalcode: 'PLZ',
    postbox: 'Postfach',
    postboxPostalcode: 'Postfach PLZ',
    city: 'Ort',
    country: 'Land',
    federalState: 'Bundesland',
    phone: 'Telefon',
    fax: 'Fax',
    email: 'E-Mail',
    emailConfirmedAt: 'E-Mail bestätigt am',
    emailVerified: 'E-Mail bestätigt',
    website: 'Website',
    companyHeadquartersMustBeInGermany: 'Der Unternehmenssitz muss sich in der Bundesrepublik Deutschland befinden',
    extractFromRegisterCopy: 'Handelsregisterauszug, Gewerbeanmeldung oder ähnliches Dokument zum Nachweis der unternehmerischen Tätigkeit (PDF), max. 30 MB',
    confirmed: 'Bestätigt',
    accredited: 'Akkreditiert',
    registrationDate: 'Datum Registrierung',
    address: 'Anschrift',
    contactDetails: 'Kontaktdaten',
    registerData: 'Registerdaten',
    downloadRegisterExcerpt: 'Registerauszug herunterladen',
    hrNumber: 'HR-Nummer',
    hrRegisterCourt: 'Registergericht',
    sendResetPasswordLink: 'Hier können Sie dem Unternehmen an die angegebene Adresse einen Link zum Neusetzen des Passwortes senden.',
    sendPasswordReset: 'Passwort-Reset senden',
    sendConfirmEmail: 'E-Mail-Adresse bestätigen',
    resendConfirmEmail: 'Bestätigungs-E-Mail erneut senden',
    deleteCompany: 'Firma löschen',
    noUser: 'Kein Login vorhanden',
    noUserReasons: 'Neue Nutzer werden autoamtisch nach 10 Tagen ohne Bestätigung ihrer E-Mail-Adresse gelöscht.',
    revokeAccreditation: 'Akkreditierung entziehen',
    revokeAccreditationModal: 'Möchten Sie die Akkreditierung wirklich widerrufen?',
  },
}

export default {
  notification: {
    description: 'Hier können Sie eine Benachrichtigung zu dieser Veranstaltung an Interessenten und Teilnehmer versenden. Die Benachrichtigung wird per E-Mail versendet und in der Onlineanmeldung anzeigt.',
    notifications: 'Benachrichtigungen',
    newNotifications: 'Neue Benachrichtigungen',
    readNotifications: 'Gelesene Benachrichtigungen anzeigen',
    noNotifications: 'Es liegen derzeit noch keine Benachrichtigungen für Sie vor.',
    sendNotification: 'Benachrichtigung senden',
    sendTo: 'Senden an',
    groups: 'Senden an',
    interestedPersons: 'Interessenten',
    allParticipants: 'Alle Teilnehmer',
    confirmedParticipants: 'Bestätigte Teilnehmer',
    send: 'Senden',
    obtain: 'Erhalten',
    sent: 'Gesendet',
    markAsReadAll: 'Alle als gelesen markieren',
    allNotifications: 'Alle Benachrichtigungen',
    sendNotificationCount: '{count} E-Mails erfolgreich versendet!'
  },
}

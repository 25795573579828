<template>
  <v-row>
    <v-dialog v-model="modalOpenMoreThenFive" max-width="800px">
      <v-card>
        <v-card-title class="justify-space-between">
          <h4 class="primary--text">
            {{ $t('tables.participation.form.priceUnsponsored') }}
          </h4>
          <v-icon size="17" @click="closeModalMoreThenFive">fal fa-times</v-icon>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <p class="mt-5" v-html="$t('tables.participation.form.priceUnsponsoredHelp')">
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary" @click="closeModalMoreThenFive">
            {{ $t('common.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col
        v-show="tradeFair.relationships.packages.length > 0"
        cols="12"
        class="pr-5"
        v-if="tradeFair.attributes.booking_type_package"
    >
      <p class="mb-0">
        <strong>{{
            $t('tables.participation.form.packagesPrices')
          }}</strong>
      </p>
      <v-row>
        <v-col cols="12">
          <table class="table-costs">
            <thead>
            <tr>
              <th class="text-left">
                {{ $t('tables.tradeFair.name') }}
              </th>
              <th>
                {{ $t('tables.participation.form.priceSponsored') }}
              </th>
              <th>
                {{ $t('tables.participation.form.priceUnsponsored') }}
                <v-icon
                  size="18"
                  style="cursor: pointer; width: 18px; height: 18px"
                  class="align-start primary--text mb-1 ml-1"
                  @click="openModalMoreThenFive"
                  >
                    fal fa-question-circle
                </v-icon>
              </th>
              <th>
                {{ $t('tables.participation.form.pricePublicsector') }}
              </th>
            </tr>
            </thead>
            <tbody>
              <tr v-for="packag in tradeFair.relationships.packages" :key="packag.id">
                <td v-html="packag.attributes.title[$i18n.locale]"></td>
                <td>{{ packag.attributes.price_sponsored | money }}</td>
                <td>{{ packag.attributes.price_unsponsored | money }}</td>
                <td>{{ packag.attributes.price_publicsector | money }}</td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-if="tradeFair.attributes.booking_type_space && spacesSorted.length">
      <p class="mb-0">
        <strong>
          {{ $t('tables.participation.form.standSizesAndPrices') }}
        </strong>
      </p>
      <v-row>
        <v-col cols="12">
          <table class="table-costs">
            <thead>
            <tr>
              <th class="text-left">
                {{ $t('forms.fields.size') }}
              </th>
              <th>
                {{ $t('tables.participation.form.priceSponsored') }}
              </th>
              <th>
                {{ $t('tables.participation.form.priceUnsponsored') }}
                <v-icon
                  size="18"
                  style="cursor: pointer; width: 18px; height: 18px"
                  class="align-start primary--text mb-1 ml-1"
                  @click="openModalMoreThenFive"
                    >
                  fal fa-question-circle
                </v-icon>
              </th>
              <th>
                {{ $t('tables.participation.form.pricePublicsector') }}
              </th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="space in spacesSorted" :key="space.id">
              <td>
                {{ space.attributes.size_from + '-' + space.attributes.size_to }}
                <span v-html="$t('tables.participation.form.qm')"/>
              </td>
              <td>{{ space.attributes.price_sponsored | money }}</td>
              <td>{{ space.attributes.price_unsponsored | money }}</td>
              <td>{{ space.attributes.price_publicsector | money }}</td>
              <td v-html="$t(space.attributes.construction_included ? 'tables.participation.form.pricePerM2withStandConstruction' : 'tables.participation.form.pricePerM2withoutStandConstruction')"
              />
            </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
    </v-col>

    <v-col v-if="tradeFair.attributes.booking_type_space && spacesSorted.length" cols="12">
      <p class="pb-0 mb-0">
        {{ $t('tables.participation.form.minimumArea') }}:
        {{ tradeFair.attributes.space_min_size }}
        <span v-html="$t('tables.participation.form.qm')"/><br/>
        {{ $t('tables.participation.form.maximumArea') }}:
        {{ tradeFair.attributes.space_max_size }}
        <span v-html="$t('tables.participation.form.qm')"/>
      </p>
    </v-col>

    <v-col cols="6"
           v-if="tradeFair.attributes.coexhibitor_fee || tradeFair.attributes.coexhibitor_external_fee || tradeFair.attributes.coexhibitor_external_fee2">
      <strong>{{ $t('tables.participation.form.fees') }}</strong>
      <p class="mb-0">
        <span v-if="tradeFair.attributes.coexhibitor_fee">
          {{ tradeFair.attributes.coexhibitor_fee_title[$i18n.locale] }}:
          {{ tradeFair.attributes.coexhibitor_fee | money }} <br/>
        </span>
        <br/>
        <span v-if="tradeFair.attributes.coexhibitor_external_fee">
              {{ $t('forms.fields.coexhibitorExternalFee') + ':' }}<br/>
        </span>
        <span v-if="tradeFair.attributes.coexhibitor_external_fee">
              {{ tradeFair.attributes.coexhibitor_external_fee_title[$i18n.locale] }}:
              {{ tradeFair.attributes.coexhibitor_external_fee | money }}
        </span>
        <br/>
        <span v-if="tradeFair.attributes.coexhibitor_external_fee2">
              {{ tradeFair.attributes.coexhibitor_external_fee2_title[$i18n.locale] }}:
              {{ tradeFair.attributes.coexhibitor_external_fee2 | money }}
        </span>
      </p>
    </v-col>

    <v-col v-if="tradeFair.relationships.services.length !== 0" cols="6">
      <p class="pt-0">
        <strong>
          {{ $t('participation.equipment.title') }}
        </strong>
      </p>
      <div
          v-for="tradeFairService in tradeFair.relationships.services"
          :key="tradeFairService.id"
      >
        <p>

          <b>{{ tradeFairService.attributes.title[$i18n.locale] }}</b>
          <small v-if="tradeFairService.mandatory">
            ({{ $t('tables.participation.form.mandatoryFee') }})</small
          >
          <small v-else>
            ({{ $t('tables.participation.form.optionalFee') }})</small
          >
          <br/>
          <span
              v-html="tradeFairService.attributes.description[$i18n.locale]"
          ></span>
          {{ tradeFairService.attributes.price | money }}<br/>
        </p>
      </div>
    </v-col>

    <v-col v-if="tradeFair.relationships.exhibition_areas.length > 0" cols="6">
      <p>
        <strong>
          {{
            $t(
                'tables.participation.form.exhibitionAreasOfGermanParticipation'
            )
          }}
        </strong>
      </p>

      <ul>
        <li v-for="area in tradeFair.relationships.exhibition_areas" :key="area.id">
          {{ area.attributes.name[$i18n.locale] }}
        </li>
      </ul>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'PriceInfo',

  props: {
    tradeFair: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      modalOpenMoreThenFive: false
    }
  },

  computed: {
    spacesSorted() {
      var spaces = this.tradeFair.relationships.spaces

      return spaces.sort(
          (a, b) => b.attributes.construction_included - a.attributes.construction_included
      )
    },
  },
  methods: {
  openModalMoreThenFive() {
      this.modalOpenMoreThenFive = true
    },
    closeModalMoreThenFive() {
      this.modalOpenMoreThenFive = false
    },
  }
}
</script>

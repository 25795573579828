<template>
  <v-navigation-drawer
    class="primary"
    app
    light
    clipped
    fixed
    permanent
    expand-on-hover
    mini-variant
    width="350"
  >
    <v-list nav class="px-0 py-5">

      <v-list-item
        v-for="menuItem in items"
        :key="menuItem.name"
        :to="{ name: menuItem.route }"
        class="ma-0"
      >
        <v-list-item-icon>
          <v-icon
            class="menubar-icon ml-2"
            size="22"
          >
            fal {{ menuItem.icon }} fa-fw
          </v-icon>
          <v-badge
            v-if="menuItem.badge"
            :content="menuItem.badge"
            color="accent"
            overlap
            offset-x="10"
            offset-y="8"
          />
        </v-list-item-icon>
        <v-list-item-title class="white--text">{{ menuItem.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'AdminMenuBar',

  computed: {
    ...mapGetters('accreditation', [
      'accreditations'
    ]),

    items() {
      return [
        {
          name: this.$t('menu.overview'),
          icon: 'fa-home',
          route: 'admin.dashboard',
          badge: null,
        },
        {
          name: this.$t('accreditation.accreditations'),
          icon: 'fa-id-card',
          route: 'admin.accreditations',
          badge: this.accreditations.length,
        },
        {
          name: this.$t('company.companies'),
          icon: 'fa-industry-alt',
          route: 'admin.companies',
          badge: null,
        },
        {
          name: this.$t('users.users'),
          icon: 'fa-users',
          route: 'admin.users',
          badge: null,
        },
        {
          name: this.$t('tradeFair.tradeFairs'),
          icon: 'fa-calendar-alt',
          route: 'admin.fairs',
          badge: null,
        },
        {
          name: this.$t('help.manageEntries'),
          icon: 'fa-question-circle',
          route: 'admin.helps',
          badge: null,
        },
        {
          name: this.$t('help.answerQuestions'),
          icon: 'fa-comments-alt',
          route: 'admin.faqs',
          badge: null,
        },
      ]
    },
  },

  created() {
    this.fetchAccreditations()
  },

  methods: {
    ...mapActions('accreditation', [
      'fetchAccreditations',
    ]),
  },
}
</script>

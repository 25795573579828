<template>
  <div>
    <v-toolbar flat>
      <v-btn icon @click="$router.go(-1)">
        <v-icon>fal fa-angle-left</v-icon>
      </v-btn>
      <v-toolbar-title>
        <h5>{{ $t('common.back') }}</h5>
      </v-toolbar-title>
    </v-toolbar>
    <v-container class="pa-9" fluid>
      <v-card class="elevation-1 rounded-0">
        <v-card-title>
          <h4 class="primary--text">{{ company.company }}</h4>
          <v-spacer />
          <v-chip v-if="company.accreditationApprovedAt" class="success" small>
            {{ $t('company.accredited') }}
          </v-chip>
        </v-card-title>

        <v-divider />

        <v-card-text class="text--primary">
          <v-row>
            <v-col cols="7">
              <v-row>
                <v-col cols="4">
                  <small>{{ $t('company.address') }}</small>
                  <p>
                    {{ company.company }}
                    <br />
                    {{ company.street }}
                    <br />
                    <span v-if="company.addressSupplement">
                      {{ company.addressSupplement }}
                      <br />
                    </span>
                    {{ company.postalcode }} {{ company.city }}
                    <br />
                    {{ company.country ? company.country.translations[$i18n.locale].country : '' }}
                    <br />
                    {{ company.federalState?.name[$i18n.locale] }}
                  </p>
                </v-col>

                <v-col cols="4">
                  <small>{{ $t('company.contactDetails') }}</small>
                  <p>
                    <i class="fas fa-phone primary--text mr-3" />
                    {{ company.phone }}
                    <br />
                    <i class="fas fa-fax primary--text mr-3" />
                    {{ company.fax }}
                    <br />
                    <i class="fas fa-envelope primary--text mr-3" />
                    <a :href="'mailto:' + company.email">{{ company.email }}</a>
                    <br />
                    <i class="fas fa-home primary--text mr-3" />
                    <a :href="company.website" target="_blank">
                      {{ company.website }}
                    </a>
                  </p>
                  <div
                    v-if="
                      company.contactFirstName ||
                      company.contactLastName ||
                      company.contactEmail ||
                      company.contactPhone
                    "
                  >
                    <small>{{
                      $t('tables.accreditation.contactPerson')
                    }}</small>
                    <p>
                      <i class="fas fa-address-book primary--text mr-3" />
                      <span>
                        {{
                          $t(`common.salutation.${company.contactGender}`) +
                          ' ' +
                          company.contactFirstName +
                          ' ' +
                          company.contactLastName
                        }}
                      </span>
                      <br />
                      <i class="fas fa-phone primary--text mr-3" />
                      <span v-if="company.contactPhone">{{
                        company.contactPhone
                      }}</span>
                      <br />
                      <i class="fas fa-envelope primary--text mr-3" />
                      <a
                        v-if="company.contactEmail"
                        :href="'mailto:' + company.contactEmail"
                        >{{ company.contactEmail }}</a
                      >
                      <br />
                    </p>
                  </div>
                </v-col>

                <v-col cols="4" v-if="company.accreditationApprovedAt">
                  <small>{{ $t('company.registerData') }}</small>
                  <p>
                    {{ $t('forms.fields.vatIdNo') }}: {{ company.vatId }}
                    <br />
                    {{ $t('company.hrNumber') }}: {{ company.registryNumber }}
                    <br />
                    {{ $t('company.hrRegisterCourt') }}:
                    {{ company.registryCourt }}
                    <br />
                    <span :hidden="!company.hasDocument">
                      <i class="fas fa-arrow-to-bottom primary--text mr-2" />
                      <a @click="downloadDocument(company.id)">{{
                        $t('company.downloadRegisterExcerpt')
                      }}</a>
                    </span>
                  </p>
                </v-col>

                <v-col cols="12" v-if="company.accreditationApprovedAt">
                  <p>
                    <small>{{ $t('accreditation.status') }}</small>
                  </p>

                  <p>
                    <v-icon class="primary--text mb-1 mr-3" size="18">
                      fal fa-calendar-alt fa-fw
                    </v-icon>
                    <span
                      >{{ $t('accreditation.registeredSince') }}:
                      {{ company.createdAt | dateByLocale }}
                    </span>
                  </p>

                  <p>
                    <v-icon class="primary--text mb-1 mr-3" size="18">
                      fal fa-calendar-check fa-fw
                    </v-icon>
                    <span
                      >{{ $t('accreditation.grantedOn') }}
                      {{ company.accreditationApprovedAt | dateByLocale }}</span
                    >
                  </p>

                  <p v-if="company.accreditationRejectedAt">
                    <v-icon class="error--text mb-1 mr-3" size="18">
                      fal fa-calendar-times fa-fw
                    </v-icon>
                    <span class="error--text"
                      >{{ $t('accreditation.revokedOn') }}
                      {{ company.accreditationRejectedAt | dateByLocale }}</span
                    >
                  </p>

                  <p>
                    <v-icon class="primary--text mb-1 mr-3" size="18">
                      fal
                      {{
                        !company.declaredPublicSectorInvolvement
                          ? 'fa-check'
                          : 'fa-times'
                      }}
                      fa-fw
                    </v-icon>
                    <span>{{
                      $t('accreditation.underPublicLaw.publicSectorInvolvement')
                    }}</span>
                  </p>

                  <p>
                    <v-icon class="primary--text mb-1 mr-3" size="18">
                      fal
                      {{
                        !company.declaredPublicSectorInstitution
                          ? 'fa-check'
                          : 'fa-times'
                      }}
                      fa-fw
                    </v-icon>
                    <span>{{
                      $t(
                        'accreditation.underPublicLaw.publicSectorInstitution'
                      )
                    }}</span>
                  </p>

                  <p>
                    <v-icon class="primary--text mb-1 mr-3" size="18">
                      fal
                      {{
                        !company.declaredPublicSectorMajority
                          ? 'fa-check'
                          : 'fa-times'
                      }}
                      fa-fw
                    </v-icon>
                    <span>{{
                      $t('accreditation.underPublicLaw.publicSectorMajority')
                    }}</span>
                  </p>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  company.isForeignBranch || company.isForeignRepresentation
                "
              >
                <v-col cols="12">
                  <p>
                    <v-icon class="primary--text mb-1 mr-3" size="18">
                      fal
                      {{ company.isForeignBranch ? 'fa-check' : 'fa-times' }}
                      fa-fw
                    </v-icon>
                    <span>{{ $t('tables.accreditation.foreignBranch') }}</span>
                  </p>

                  <p>
                    <v-icon class="primary--text mb-1 mr-3" size="18">
                      fal
                      {{
                        company.isForeignRepresentation
                          ? 'fa-check'
                          : 'fa-times'
                      }}
                      fa-fw
                    </v-icon>
                    <span>{{
                      $t('tables.accreditation.foreignRepresentation')
                    }}</span>
                  </p>

                  <p>
                    <span>{{
                      $t('tables.accreditation.connectedCompany') +
                      ': ' +
                      company.relatedCompany
                    }}</span>
                  </p>
                </v-col>
              </v-row>
                <v-btn class="mt-5" color="secondary" style="text-transform: none;" outlined text :to="{ name: 'supervisor.notes', params: { id: company.companyId } }">
                <v-icon class="mr-2" size="18">fal fa-notes</v-icon>
                  {{ $t('help.notes.company') }}
                </v-btn>
                <v-badge v-if="company.notesCount" color="secondary" :content="company.notesCount">
                </v-badge>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapWaitingActions } from 'vue-wait'
import {
  FETCH_COMPANY,
} from '@/store/loading-types'
import validation from '@/mixins/validate'

export default {
  name: 'SupervisorCompany',

  mixins: [validation],

  data() {
    return {
      loaders: {
        loadCompany: FETCH_COMPANY,
      },
    }
  },

  computed: {
    ...mapGetters('company', ['company']),
  },

  async created() {
    await this.fetchCompany({
      id: this.$route.params.id,
      ignoreApprove: false,
    })
  },

  methods: {
    ...mapActions('accreditation', ['downloadDocument']),

    ...mapActions('company', ['fetchCompany']),

    ...mapActions('participation', ['downloadRegistrationConfirmation']),

    ...mapWaitingActions('company', {
      fetchCompany: FETCH_COMPANY,
    }),
  },
}
</script>

<template>
  <div>
    <v-data-table
        :headers="headers"
        :loading="form.loading"
        :items="participations"
        :page.sync="form.page"
        :options.sync="options"
        :footer-props="{itemsPerPageOptions: [10, 25, 50, 100], showFirstLastPage: true}"
        :server-items-length="form.total"
        @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
        show-expand
    >

      <template v-slot:item.name="{ item }">
        <router-link
            :to="{ name: 'exhibitor.participation.view', params: { id: item.id }}"
            class="text-decoration-none"
        >
          <h3 class="primary--text">{{ item.relationships.trade_fair.attributes.name_display }}</h3>
        </router-link>
      </template>

      <template v-slot:item.date_registration_deadline="{ item }">
        <span v-if="item.relationships.trade_fair.attributes.registration_deadline">
          {{ item.relationships.trade_fair.attributes.registration_deadline | dateByLocale }}
        </span>
        <span v-else>
          {{ $t('tables.tradeFair.notYetKnown') }}
        </span>
      </template>

      <template v-slot:item.date_start="{ item }">
        <span>
          {{ item.relationships.trade_fair.attributes.start_date_display[$i18n.locale] }}
        </span>
      </template>

      <template v-slot:item.date_end="{ item }">
        <span>
          {{ item.relationships.trade_fair.attributes.end_date | dateByLocale }}
        </span>
      </template>

      <template v-slot:item.venue="{ item }">
        <span>{{ item.relationships.trade_fair.attributes.city }}</span>
      </template>

      <template v-slot:item.country="{ item }">
        <span>{{ item.relationships.trade_fair.relationships.country.translations[$i18n.locale].country }}</span>
      </template>

      <template v-slot:item.is_favorite="{ item }">
        <v-simple-checkbox
            v-model="item.relationships.trade_fair.included.is_favorite"
            :ripple="false"
            :disabled="form.loading || (!isAvailableToFavorite({ status: item.attributes.status }))"
            @click="say({ tradeFair: item.relationships.trade_fair, participation: item })"
        />
      </template>

      <template v-slot:item.status="{ item }">
        <div id="chips-container" v-bind:class="{ 'd-flex align-center': item.deniedNote }">
          <v-chip
              v-if="item.attributes.status == 'reprocessed'"
              :class="`participation-${item.attributes.status}`"
              class="darken-1 white--text"
              color="blue"
              small
          >
            {{ $t(`tables.participation.status.${item.attributes.status}`) }}
          </v-chip>
          <v-chip
              v-else-if="item.attributes.status"
              :class="`participation-${item.attributes.status}`"
              class="darken-1 white--text"
              small
          >
            {{ $t(`tables.participation.status.${item.attributes.status}`) }}

          </v-chip>

          <v-tooltip
              v-if="item.attributes.denied_note && (item.attributes.status === PARTICIPATION_STATUS.REJECTED || item.attributes.status === PARTICIPATION_STATUS.CANCELLED || item.attributes.status === PARTICIPATION_STATUS.REPROCESSED)"
              bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  class="primary--text"
                  icon
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon size="20">far fa-fw fa-question-circle</v-icon>
              </v-btn>
            </template>
            <span>{{ item.attributes.denied_note }}</span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="elevation-1 px-1 grey lighten-5">
          <v-container fluid>
            <v-row>

              <v-col cols="5">
                <small>{{ $t('tables.tradeFair.mainOffering') }}</small>
                <p>{{ item.relationships.trade_fair.attributes.fair_focus[$i18n.locale] }}</p>

                <p>
                  <small v-if="item.relationships.trade_fair.relationships.sectors.length">
                    {{ $t('tables.tradeFair.industryFocus') }}
                  </small>
                  <br/>
                  <span
                      v-for="sector in item.relationships.trade_fair.relationships.sectors"
                      :key="sector.id"
                  >
                    {{ sector.translations[$i18n.locale].name }}
                    <br/>
                  </span>
                </p>

                <v-btn
                    class="secondary elevation-0 mt-4"
                    :to="{ name: 'exhibitor.participation.view', params: { id: item.id }}"
                    small
                >
                  {{ $t('tables.tradeFair.informationAndRegistration') }}
                </v-btn>
              </v-col>

              <v-col cols="7">

                <table class="facts">
                  <tbody>
                  <tr v-if="item.relationships.trade_fair.attributes.type">
                    <td class="pl-0">
                      <small>{{ $t('tables.tradeFair.participationForm') }}</small>
                    </td>
                    <td>
                      {{ item.relationships.trade_fair.attributes.type.type }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pl-0 align-start">
                      <small>{{ $t('tables.tradeFair.execution') }}</small>
                    </td>
                    <td class="align-start">
                      {{ item.relationships.trade_fair.relationships.organizer.name }}
                      <br/>
                      {{ item.relationships.trade_fair.relationships.organizer.address }}
                      <br/>
                      {{ item.relationships.trade_fair.relationships.organizer.postal_code }}
                      {{ item.relationships.trade_fair.relationships.organizer.city }}
                    </td>
                  </tr>
                  <tr>
                    <td class="pl-0 align-start">
                      <small>{{ $t('tables.tradeFair.projectManager') }}</small>
                    </td>
                    <td class="align-start">
                      {{ item.relationships.trade_fair.attributes.contact_name }}
                      <br/>
                      {{ $t('tables.tradeFair.mail') }}: {{ item.relationships.trade_fair.attributes.contact_email }}
                      <br/>
                      {{ $t('tables.tradeFair.phone') }}: {{ item.relationships.trade_fair.attributes.contact_phone }}
                      <br/>
                    </td>
                  </tr>
                  <tr>
                    <td class="pl-0">
                      <small>{{ $t('tables.tradeFair.organizerWebsite') }}</small>
                    </td>
                    <td><a :href="item.relationships.trade_fair.attributes.website"
                           target="_blank">{{ item.relationships.trade_fair.attributes.website }}</a></td>
                  </tr>
                  <tr>
                    <td class="pl-0">
                      <small>{{ $t('tables.tradeFair.detailedTradeFairInformation') }}</small>
                    </td>
                    <td>
                      <a
                          :href="item.relationships.trade_fair.attributes.link_auma_database"
                          target="_blank"
                      >
                        {{ $t('tables.tradeFair.AUMATradeFairDatabase') }}
                      </a>
                    </td>
                  </tr>

                  </tbody>
                </table>
              </v-col>
            </v-row>
          </v-container>
        </td>
      </template>

      <template slot="no-data">
        {{ $t('common.noDataAvailable') }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import {PARTICIPATION_STATUS} from '@/enums/participationStatus'

export default {
  name: 'ParticipationList',

  data() {
    return {
      participations: [],
      options: {},

      form: {
        total: 0,
        loading: false,
      },

      PARTICIPATION_STATUS,
    }
  },

  computed: {
    headers() {
      return [
        {
          text: '',
          value: 'data-table-expand'
        },
        {
          text: this.$t('tables.tradeFair.tradeFair'),
          sortable: false,
          value: 'name'
        },
        {
          text: this.$t('tables.tradeFair.status'),
          value: 'status',
          sortable: false
        },
        {
          text: this.$t('tables.tradeFair.city'),
          value: 'venue',
          sortable: false
        },
        {
          text: this.$t('tables.tradeFair.country'),
          value: 'country',
          sortable: false
        },
        {
          text: this.$t('tables.tradeFair.startAt'),
          value: 'date_start',
          sortable: false,
        },
        {
          text: this.$t('tables.tradeFair.endAt'),
          value: 'date_end',
          sortable: false,
        },
        {
          text: this.$t('tables.tradeFair.registrationDeadline'),
          value: 'date_registration_deadline',
          sortable: false,
        },
        {
          text: this.$t('tables.tradeFair.bookmarked'),
          value: 'is_favorite',
          sortable: false
        }
      ]
    }
  },

  created() {
    this.load()
  },

  methods: {
    ...mapActions('participation', [
      'setNotedTradeFair',
      'unsetNotedTradeFair',
    ]),

    async load() {
      this.form.loading = true

      let sort = ''

      for (let i in this.options.sortBy) {
        sort += this.options.sortDesc[i] ? '-' + this.options.sortBy[i] : this.options.sortBy[i]
      }

      let response = (await this.$axios.get('/api/company/participations', {
        params: {
          'languages': '*',
          'filter[future]': true,
          'include': [
            'tradeFair',
            'coexhibitors',
            'company',
          ],
          'sort': sort,
          'page[number]': this.options?.page ?? 1,
          'page[size]': this.options?.itemsPerPage ?? 10,
        },
      })).data

      this.participations = response.data

      this.form.total = response.meta.paginator.total
      this.form.loading = false
    },

    async say({participation, tradeFair}) {
      if (this.form.loading) {
        return
      }

      if (participation && !this.isAvailableToFavorite({status: participation.attributes.status})) {
        return
      }

      this.form.loading = true
      if (tradeFair.included.is_favorite) {
        if (await this.setNotedTradeFair(tradeFair.id)) {
          await this.fetchParticipations()
        }
      } else {
        if (await this.unsetNotedTradeFair(tradeFair.id)) {
          await this.fetchParticipations()
        }
      }

      this.load()
    },

    isAvailableToFavorite({status}) {
      if ([
        PARTICIPATION_STATUS.REJECTED,
        PARTICIPATION_STATUS.CANCELLED,
        PARTICIPATION_STATUS.SUBMITTED,
        PARTICIPATION_STATUS.REPROCESSED,
        PARTICIPATION_STATUS.APPROVED
      ].includes(status)) {
        return false
      }

      return true
    },
  },

  watch: {
    'options'() {
      this.load()
    },

    'filters': {
      handler() {
        this.options.page = 1

        this.load()
      },
      deep: true
    },
  },
}
</script>

<style scoped>
table.fair-details td {
  vertical-align: top;
  padding: .5em 0 !important;
}

#chips-container .v-chip.participation-submitted {
  background: #3cd1c2;
}

#chips-container .v-chip.participation-cancelled {
  background: #7d7d7d;
}

#chips-container .v-chip.participation-completed {
  background: #4a5568;
}

#chips-container .v-chip.participation-approved {
  background: #0E9A00;
}

#chips-container .v-chip.participation-rejected {
  background: #f83e70;
}
</style>

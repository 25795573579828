<template>
  <v-expansion-panel class="elevation-1 rounded-0">
    <v-expansion-panel-header>
      <h3>
        {{ $t('tables.participation.form.implementationCompany') }}
      </h3>
    </v-expansion-panel-header>

    <v-expansion-panel-content v-if="!isLoading">

      <v-row>
        <v-col
          cols="6"
          class="pr-5"
        >
          <p v-if="organizer?.email">
            {{ organizer.name }}
            <br />
            {{ organizer.address }}
            <br />
            {{ organizer.postalCode }} {{ organizer.city }}
            <br />
            <br v-if="organizer.phone"/>
            {{ $t('tables.tradeFair.phone') }}: {{ organizer.phone }}
            <br v-if="organizer.fax"/>
            {{ $t('tables.tradeFair.fax') }}: {{ organizer.fax }}
            <br />
            <a :href="'mailto:' + organizer.email">{{ organizer.email }}</a><br />         
            <a
                :href="`https://${organizer.website}`"
                target="_blank"
            >
              {{ organizer.website }}
            </a>
          </p>
          <p v-else>
            {{ $t('tables.tradeFair.notYetKnown') }}
          </p>
        </v-col>

        <v-col
          cols="6"
          class="pl-5"
          v-if="tradeFair.contactEmail"
        >
          <p class="mb-0">
            <strong>
              {{ $t('tables.participation.form.contactPersonForThisTradeFairParticipation') }}
            </strong>
          </p>
          <p>
            {{ tradeFair.contactName }}
            <br />
            {{ $t('tables.participation.form.mail') }}:
            <a :href="'mailto:' + tradeFair.contactEmail">{{ tradeFair.contactEmail }}</a>
            <br />
            {{ $t('tables.participation.form.phone') }}: {{ tradeFair.contactPhone }}
          </p>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: 'TradeFairExecutionCompany',

  props: {
    tradeFair: {
      type: Object,
      required: true,
    },

    organizer: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<template>
  <v-card class="elevation-1 rounded-0 mb-5">
    <v-card-title class="px-5 py-5">
      <h4 class="primary--text">{{ $t('participation.openOnlineRegistration') }}</h4>
      <v-chip v-if="fair.attributes.flag_cancelled" class="ml-auto">
        {{ $t('tradeFair.registrationIsCancelled') }}
      </v-chip>
      <v-chip v-if="fair.attributes.flag_registration_external_locked" class="ml-auto" small>
        {{ $t('tradeFair.registrationIsExternalLocked')}}
      </v-chip>
      <v-chip v-if="fair.attributes.is_finished" class="ml-auto" small>
        {{ $t('tradeFair.tradeFairIsFinished') }}
      </v-chip>
      <v-chip v-if="fair.attributes.is_deadline_passed && !fair.attributes.is_finished" class="ml-auto" small>
        {{ $t('tradeFair.registrationDeadlineIsPassed')}}
      </v-chip>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
            cols="12"
            v-if="isDeadlineRunning"
        >
          <contact-person-fields :fair="fair"  @submit="$emit('update')"/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ContactPersonFields from '@/components/organizer/Application/ContactPersonFields'

export default {
  name: 'EditActiveRegistration',

  components: {
    ContactPersonFields,
  },

  props: {
    fair: {
      type: Object,
      required: true,
    },

    isDeadlineRunning: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

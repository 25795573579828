export default {
  forms: {
    fields: {
      signature: 'Signature',
      title: 'Title',
      amount: 'Amount',
      firstName: 'First name',
      lastName: 'Last name',
      name: 'Name',
      phone: 'Phone',
      mobileIncl: 'Mobile incl. country code',
      mobile: 'Mobile',
      email: 'E-mail',
      password: 'Password',
      passwordConfirmation: 'Password confirmation',
      userEmail: 'User e-mail',
      organizerEmail: 'Organizer e-mail',
      registrationType: 'Registration type',
      state: 'State',
      vatID: 'VAT ID',
      vatId: 'VAT ID',
      fax: 'Fax',
      website: 'Website',
      company: 'Company',
      street: 'Street',
      address: 'Address',
      addresssupplement: 'Address supplement',
      postalcode: 'Postcode',
      postbox: 'P.O. Box',
      postboxPostalcode: 'P.O. Box ZIP',
      city: 'City',
      country: 'Country',
      responsible: 'Responsible',
      tel: 'Tel',
      federalState: 'Federal state',
      federalStateRequired: 'Please select a Federal State',
      gender: 'Gender',
      salutation: 'Salutation',
      contactTitle: 'Title',
      contactGender: 'Salutation',
      contactFirstname: 'First name',
      contactLastname: 'Last name',
      contactName: 'Name',
      contactEmail: 'E-mail',
      contactPhone: 'Phone',
      contactFax: 'Fax',
      male: 'Male',
      female: 'Female',
      weight: 'Weight',
      height: 'Height',
      width: 'Width',
      depth: 'Depth',
      weightHint: 'Weight in kg',
      heightHint: 'Height in Centimeters (cm)',
      widthHint: 'Width in Centimeters (cm)',
      depthHint: 'Depth in Centimeters (cm)',
      hints: 'Hints',
      incorrectInformationAwareness: 'Incorrect information awareness',
      size: 'Size',
      withStandConstruction: 'With stand construction',
      registryCourt: 'Court of registry, trade office or similar institution',
      registryNumberEntry: 'Commercial register number or enter "not available"',
      registryNumber: 'Commercial register number',
      taxNumber: 'Tax number',
      vatIdNo: 'VAT ID no.',
      registerExcerpt: 'Extract from the commercial register, business registration or similar document',
      document: 'Extract from the commercial register, business registration or similar document',
      recognizeConditionsForParticipation: 'Conditions for participation in trade fairs abroad',
      dataProtectionDeclaration: 'Data protection declaration for participation in trade fairs abroad',
      area: 'Exhibition area',
      differentAddressForSendingInvoicesSet: 'Different billing address',
      differentAddressForSendingInvoicesNotSet: 'I would like to enter a different billing address.',
      organization: 'Organization',
      msMr: 'Ms/Mr',
      userType: 'User type',
      association: 'Association',
      created: 'Created',
      lastLogin: 'Last login',
      lastUpdated: 'Last updated',
      type: 'Type',
      role: 'Role',
      termsOfUse: 'Terms of use',
      termsOfUseAdoption: {
        before: 'I have read and agree to the ',
        link: 'privacy policy.',
        after: ''
      },
      sendTo: 'Send to',
      groups: 'Send to',
      subject: 'Subject',
      message: 'Message',
      body: 'Message',
      participatedMoreThanFourTimes: 'Official participations amount',
      manager: 'Project manager',
      ministryApproval: 'BMWK/BAFA/BMEL approval',
      activeUser: 'Active user',
      minParticipants: 'Minimum number of participants',
      spaceMinSize: 'Minimum exhibition space',
      spaceMaxSize: 'Maximum exhibition space',
      sizeFrom: 'Size on',
      sizeTo: 'Size up',
      priceSponsored: 'Price 1st - 4th participation',
      priceUnsponsored: 'Price from 5th participation',
      pricePublicsector: 'Full price',
      services: 'Services included',
      coexhibitorFee: 'Organiser fee per sub-exhibitor',
      coexhibitorFeeTitle: 'Name of the fee',
      coexhibitorFeeDescription: 'Note/Description',
      coexhibitorFeeTitle_de: 'Name of the fee (German)',
      coexhibitorFeeDescription_de: 'Note/Description (German)',
      coexhibitorFeeTitle_en: 'Name of the fee (English)',
      coexhibitorFeeDescription_en: 'Note/Description (English)',
      coexhibitorExternalFee: 'Organiser fee per sub-exhibitor',
      coexhibitorExternalFeeTitle: 'Name of the fee',
      coexhibitorExternalFeeTitle_de: 'Name of the fee (German)',
      coexhibitorExternalFeeTitle_en: 'Name of the fee (English)',
      coexhibitorExternalFee2: 'Organiser fee per sub-exhibitor 2',
      coexhibitorExternalFee2Title: 'Name of the fee 2',
      coexhibitorExternalFee2Title_de: 'Name of the fee (German) 2',
      coexhibitorExternalFee2Title_en: 'Name of the fee (English) 2',
      description: 'Description',
      price: 'Price',
      unit: 'Unit',
      unit_de: 'Unit (German)',
      unit_en: 'Unit (English)',
      specificationLabel: 'Specification label',
      bookingTypeSpace: 'Exhibition space',
      bookingTypePackage: 'Package',
      furtherParticipationRequirements: 'Required: Further information about your company',
      germanRelations: "What is your company's relationship to a German company?",
      foreignBranch: 'Foreign branch of a German company',
      foreignRepresentation: 'Foreign representation of a German company',
      relatedCompany: 'Please enter the name of the German company here',
      noRelations: 'No reference to a German company',
      noRelationsMessage: 'Unfortunately, you cannot continue the accreditation process in the Foreign Trade Fair Participation Programme of Germany. Only companies from Germany and their foreign branches and representatives are eligible to participate.',
      contactTradeFairParticipation: 'Contact for trade fair participation',
      headline_de: 'Headline german',
      headline_en: 'Headline english',
      abstract_de: 'Summary german',
      abstract_en: 'Summary english',
      text_de: 'Text german',
      text_en: 'Text english',
      contactUserId: 'Contact person',
      exhibitorsTotal: 'Exhibitors in total',
      exhibitorsTotalHa: 'Amount of German Pavilion parent exhibitors in total',
      exhibitorsHaThereofImporters: 'thereof Importers',
      exhibitorsTotalUa: 'Amount of German Pavilion parent exhibitors in total',
      exhibitorsUaThereofImporters: 'thereof Importers',
      spacesTotal: 'Space in total in ㎡',
      spacesExhibitorSpace: 'Space for Exhibitors in ㎡',
      spacesExhibitorSpaceHint: 'incl. free spaces if applicable',
      participationsWhichHalls: 'Participation in which halls (Sector and ㎡)',
      participationsWhichStates: 'Participations from federal states',
      participationsFiveBiggest: 'Five biggest foreign participations',
      participationsGermanCommunity: 'German participations',
      participationsGermanCommunityHint: 'Outside the German Community Stand',
      visitorsPreliminary: 'Preliminary number of visitors',
      visitorsForeign: 'thereof foreign',
      visitorsVip: 'VIP-Visitors at German Pavilion',
      generalOpeningBy: 'Opening by',
      generalReception: 'BMEL-/BMWK-Reception',
      privacyPolicy: 'Privacy policy',
      billPerEMail: "Bill per E-mail",
      billingEMail: "Invoice E-mail-address",
      billingaddressEmail: "Invoice E-mail-address",
      brand: 'Brand',
      brandFlag: 'Insert Brand',
      brandPlaceholder: 'Enter the name of the brand that should be used here together with your company name'
    },
    buttons: {
      save: 'Save',
      continue: 'Continue',
      saveAndContinue: 'Save and Continue',
      add: 'Add',
      addEntry: 'Add entry',
      submit: 'Submit',
      upload: 'Upload',
      completeAndSend: 'Complete and send',
      send: 'Send',
      search: 'Search',
    },
    hints: {
      notAvailable: 'Not available',
      password: 'The password must be at least 8 characters and contain at least one uppercase character and one number.',
    },
    sponsors: {
      bmwi: 'BMWK',
      bmel: 'BMEL',
    }
  },
}

export default {
  accreditation: {
    accreditations: 'Akkreditierungen',
    checkAndApproveNew: 'Neue Akkreditierungen prüfen und freigeben.',
    title: 'Akkreditierung',
    notAvailable: 'Keine Angabe',
    description: {
      authorizedToBookViaPlatform: 'Als akkreditiertes Unternehmen erhalten Sie die Berechtigung, Ihre Teilnahme an den Veranstaltungen des Auslandsmesseprogramms des Bundes sicher, verbindlich und komfortabel über diese Plattform zu buchen.',
      companyIdentityIsChecked: 'Dazu wird die Identität Ihres Unternehmens geprüft. Außerdem wird geprüft, ob Ihr Unternehmen zur Teilnahme an den Auslandsmessebeteiligungen des Bundes berechtigt ist.'
    },
    statusTexts: {
      approvalPending: 'Ihre Akkreditierungsanfrage ist eingegangen und wird derzeit geprüft. Sie werden per E-Mail informiert, sobald die Akkreditierung erteilt wurde.',
      approved: 'Die Akkreditierung Ihres Unternehmens wurde am {date} erteilt. Sie können Ihre übermittelten Daten nachstehend aktualisieren. Aktualisierungen werden erst nach erneuter Prüfung freigeschaltet.',
      rejected: 'Die Akkreditierung Ihres Unternehmens wurde am {date} entzogen. Sie können Ihre übermittelten Daten nachstehend aktualisieren. Aktualisierungen werden erst nach erneuter Prüfung freigeschaltet.',
    },
    hints: {
      enterCompanyDetails: 'Unternehmensdaten eingeben',
      uploadExcerpt: 'Handelsregisterauszug oder Gewerbeanmeldung hochladen',
      publicSectorParticipationInformation: 'Angaben zur Beteiligung der öffentlichen Hand',
    },
    steps: {
      companyData: {
        title: 'Unternehmensdaten',
        subTitle: 'Unternehmens- und Kontaktdaten',
      },
      registerExcerpt: {
        title: 'Registerauszug',
        subTitle: 'Registerauszug',
        notice: 'Für ausländische Niederlassungen oder Vertretungen ist lediglich die Angabe der Ust-ID-Nummer obligatorisch',
        description: 'Bitte laden Sie hier Ihren aktuellen Handelsregisterauszug als PDF-Dokument hoch. Alternativ können Sie hier auch Ihre Gewerbeanmeldung oder einen anderen geeigneten Nachweis zu Ihrer Organisation einreichen.',
      },
      publicParticipation: {
        title: 'Beteiligung der öffentlichen Hand',
        subTitle: 'Erklärung zur Beteiligung der öffentlichen Hand',
        declarations: {
          publicSectorInvolvement: 'Wir erhalten keine institutionelle Förderung aus öffentlichen Mitteln.',
          publicSectorInstitution: 'Unser Unternehmen ist keine Bundes-, Landes- oder Kommunalbehörde, Landesförderinstitut oder sonstige juristische Person des öffentlichen Rechts.',
          publicSectorMajority: 'An unserem Unternehmen sind keine Religionsgemeinschaft(en) oder juristische Person(en) des öffentlichen Rechts einzeln oder zusammen, direkt oder indirekt mehrheitlich beteiligt.',
        },
      },
      conditions: {
        title: 'Bedingungen',
        recognizeConditionsForParticipation: {
          label: 'Wir bestätigen hiermit rechtsverbindlich, dass wir die Allgemeinen und Besonderen Teilnahmebedingungen des Bundes an Messen und Ausstellungen im Ausland anerkennen.',
          link: 'Allgemeine Teilnahmebedingungen für Beteiligungen des Bundes an Messen und Ausstellungen im Ausland (ATB)',
        },
        dataProtectionDeclaration: {
          label: 'Wir bestätigen hiermit rechtsverbindlich, dass wir die Datenschutzerklärung für Beteiligungen des Bundes an Messen und Ausstellungen im Ausland zur Kenntnis genommen habe/haben und erkläre/n mich/uns damit einverstanden.',
          link: 'Datenschutzerklärung',
        },
      },
      complete: {
        title: 'Fertig',
        subTitle: 'Akkreditierung absenden',
        description: 'Senden Sie Ihre Daten jetzt ab. Die Bearbeitung Ihrer Akkreditierung dauert in der Regel 1-2 Werktage. Sie werden per E-Mail benachrichtigt.',
        requiredVerification: 'E-Mail-Bestätigung erforderlich',
        tip: 'Sollten Sie Ihre E-Mail-Adresse bereits bestätigt haben und der Button ist dennoch ausgegraut, loggen Sie sich bitte aus und wieder ein, um das Problem zu beheben',
        successModalTitle: 'Akkreditierung eingereicht',
        successModalMessage: 'Ihre Akkreditierungsanfrage ist eingegangen und wird derzeit geprüft. Sie werden per E-Mail informiert, sobald die Akkreditierung erteilt wurde. Dies dauert üblicherweise ein bis zwei Tage.',
      },
    },
    status: 'Akkreditierungsstatus',
    registeredSince: 'Registriert seit',
    grantedOn: 'Akkreditierung erteilt am',
    revokedOn: 'Akkreditierung entzogen am',
    underPublicLaw: {
      publicSectorInvolvement:'Institutionelle Förderung aus öffentlichen Mitteln',
      publicSectorInstitution:'Behörde oder Institution des öffentlichen Rechts',
      publicSectorMajority:'Beteiligung von Religionsgemeinschaften oder juristischen Personen des öffentlichen Rechts'
    },
    accreditationApproved: 'Akkreditierung wurde genehmigt',
    accreditationRejected: 'Akkreditierung wurde abgelehnt',
    alert: 'Wenn Sie im nachstehenden Feld auf "weiter" klicken, starten Sie eine neue Akkreditierungsanfrage.',
    infoSponsored: 'Klicken Sie jeweils auf die Checkbox, wenn die Erklärung für Ihr Unternehmen zutreffend ist. <br>Hinweis: Um ermäßigte Beteiligungspreise zu erhalten, müssen in der Regel alle drei Erklärungen zutreffend sein.',
    infoSponsoredInstitutionalOrPublic: 'Mit institutioneller Förderung sind Zuwendungen zur Deckung der gesamten Ausgaben oder eines nicht abgegrenzten Teils der Ausgaben eines Zuwendungsempfängers gemeint. Die institutionelle Förderung gleicht in der Praxis einer Art Dauerverpflichtung für die öffentliche Hand. <b>Wenn dies nicht</b> auf Ihr Unternehmen zutrifft, klicken Sie auf die Checkbox.',
    infoSectorMajority: 'Wenn keine öffentlichen Gelder zur Finanzierung Ihrer Messebeteiligungen im Auslandsmesseprogramm eingesetzt werden, können Sie diese Aussage mit Klick in die Checkbox bestätigen.',
    vatIdRequired: 'Eingabe USt-ID erforderlich',
  },
}



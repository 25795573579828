<template>
  <div>
    <p>
      {{ $t('participation.editSteps.approvalAndPublication.examinationAndApprovalIsRequired') }}
    </p>
    <v-checkbox
      class="ml-2"
      v-model="form.data.ministryApproval"
      :label="$t('participation.editSteps.approvalAndPublication.approvalGranted')"
      :error-messages="errors.ministryApproval"
    />
    <v-alert dense outlined type="warning">
      {{ $t('participation.editSteps.approvalAndPublication.disablingFormAttention') }}
    </v-alert>
    <v-btn
      color="secondary"
      class="elevation-0"
      :loading="form.loading"
      @click="handleOpenRegistration"
    >
      <v-icon size="16" class="mr-2">fal fa-rocket</v-icon>
      {{ $t('participation.actions.open') }}
    </v-btn>
  </div>
</template>

<script>
import { sameAs } from 'vuelidate/lib/validators'
import validation from '@/mixins/validate'
import notifications from '@/mixins/notifications'

export default {
  name: 'OpenRegistration',

  mixins: [validation, notifications],

  props: {
    fair: Object,
  },

  data() {
    return {
      form: {
        loading: false,

        data: {
          ministryApproval: false,
        },

        validationRules: {
          ministryApproval: {
            sameAs: sameAs(() => true),
          },
        },

        custom: {
          validationRulesMap: {
            ministryApproval: {
              sameAs: 'checkboxRequired',
            },
          },
        },
      },
    }
  },

  methods: {
    async handleOpenRegistration() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.form.loading = true

      await this.$axios.post(`/api/trade-fairs/${this.fair.id}/open-registration`)

      this.$snackbar(this.$t('common.successApiMessage'))

      this.form.loading = false

      this.$emit('update')
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>

export default {
    tables: {
        tradeFair: {
            name: 'Name',
            bookmarked: 'Vorgemerkt',
            tradeFair: 'Messe',
            status: 'Status',
            date: 'Datum',
            city: 'Ort',
            country: 'Land',
            startAt: 'Beginn',
            endAt: 'Ende',
            details: 'Details',
            registrationDeadline: 'Anmeldeschluss',
            isFavorite: 'Vormerken',
            mainOffering: 'Angebotsschwerpunkte',
            industryFocus: 'Branchenschwerpunkte',
            informationAndRegistration: 'Informationen und Anmeldung',
            participationForm: 'Beteiligungsform',
            execution: 'Durchführung',
            projectManager: 'Projektleiter',
            mail: 'Mail',
            phone: 'Telefon',
            fax: 'Fax',
            organizerWebsite: 'Veranstalter-Website',
            detailedTradeFairInformation: 'Ausführliche Messeinformationen',
            AUMATradeFairDatabase: 'Messedatenbank des AUMA',
            bookmark: 'Vormerken',
            registerNow: 'Jetzt anmelden',
            signUp: 'Anmeldung',
            beginning: 'Beginn',
            program: 'Messeprogramm',
            association: 'Verband',
            notYetKnown: 'noch nicht bekannt',
            statuses: {
                canceled: 'Abgesagt',
                interestExpressed: 'Interesse bekundet',
                registrationDeadlineRuns: 'Anmeldefrist läuft',
                registrationDeadlineExpired: 'Anmeldefrist abgelaufen',
                summaryMissing: 'Messekurzbericht ausstehend',
                tradeFairInProgress: 'Messe läuft'
            },
        },
        participation: {
            status: {
                submitted: 'Teilnahme eingereicht',
                cancelled: 'Teilnahme abgesagt',
                approved: 'Teilnahme genehmigt',
                rejected: 'Teilnahme abgelehnt',
                reprocessed: 'Bitte Anmeldung prüfen',
                checkRegistration: 'Bitte Anmeldung prüfen',
                completed: 'Teilnahme abgeschlossen',
            },
            pageStatus: {
                submitted: 'Angemeldet (unbestätigt)',
                cancelled: 'Anmeldung storniert',
                approved: 'Teilnahme gebucht',
                rejected: 'Anmeldung abgelehnt',
                reprocessed: 'Zur Bearbeitung zurückgegeben',
                checkRegistration: 'Bitte Anmeldung prüfen',
                completed: 'Teilnahme abgeschlossen',
            },
            count: {
                submitted: 'Eingereicht',
                cancelled: 'Abgebrochen',
                approved: 'Bestätigt',
                rejected: 'Abgelehnt',
                reprocessed: 'Zurückgegeben',
                checkRegistration: 'Anmeldung prüfen',
                completed: 'Vollendet',
            },
            form: {
                conditionsOfParticipation: 'Teilnahmebedingungen',
                onlineRegistrationAndParticipation: 'Online-Anmeldung & Teilnahme',
                registrationAndParticipation: 'Anmeldung & Teilnahme',
                atPresentOnlineRegistrationVia: 'Derzeit steht die Onlineanmeldung über diese Plattform nur für einen Teil ' +
                    'der Veranstaltungen des Auslandsmesseprogramms des Bundes zur Verfügung. Unter nachfolgendem Link können ' +
                    'Sie sich die Anmeldeunterlagen herunterladen. Bitte kontaktieren Sie bei Fragen die unten genannten ' +
                    'Durchführungsgesellschaft.',
                registrationDocumentsOnTheAUMA: 'Anmeldeunterlagen auf der Website des AUMA',
                specialConditionsOfParticipation: 'Besondere Teilnahmebedingungen (BTB)',
                generalConditionsOfParticipation: 'Allgemeine Teilnahmebedingungen für Beteiligungen des Bundes an Messen und Ausstellungen im Ausland (ATB)',
                registerForParticipationInThisTradeFairInFewSimpleSteps: 'Melden Sie sich in wenigen einfachen Schritten für ' +
                    'die Teilnahme an dieser Messe an.',
                registerForParticipationDeadlineIsPassed: 'Die Anmeldefrist für diese Veranstaltung ist abgelaufen.',
                makeNoteOfYourParticipationInTheTradeFair: 'Messebeteiligung vormerken',
                areYouInterestedInParticipatingInThisTradeFair: 'Sie interessieren sich unverbindlich für die Teilnahme ' +
                    'an dieser Messe? Dann teilen Sie uns das bitte mit und merken Sie diese Veranstaltung vor. ' +
                    'Sie werden dann zu gegebener Zeit mit allen notwendigen Informationen versorgt, so dass Sie dann ' +
                    'verbindlich über Ihre Teilnahme entscheiden können.',
                iWouldLikeToReserveThisEventWithoutObligation: 'Ich möchte diese Veranstaltung unverbindlich vormerken',
                accreditation: 'Akkreditierung',
                onceYouHaveBeenGrantedAnAccreditation: 'Mit einer durchgeführten und erteilten Akkreditierung erhalten Sie ' +
                    'die Berechtigung, Ihre Teilnahme an den Veranstaltungen des Auslandsmesseprogramms verbindlich zu buchen.',
                yourAccreditationWasGrantedOn: 'Ihre Akkreditierung wurde erteilt am',
                yourAccreditationHasNotYetBeenCompleted: 'Ihre Akkreditierung wurde noch nicht abgeschlossen.',
                carryOutAccreditation: 'Akkreditierung durchführen',
                registration: 'Anmeldung',
                registerAsAnExhibitorForTheJoint: 'Melden Sie sich innerhalb der vorgesehenen Anmeldefrist als Aussteller ' +
                    'für den Gemeinschaftsstand an der {tradefair} an. Weitere Unternehmen auf Ihrem Messestand müssen als ' +
                    'Unteraussteller angemeldet werden.',
                registrationTakesPlaceOn: 'Anmeldung erfolgt am',
                sqm: 'm<sup>2</sup>',
                standAreaWithStandConstruction: 'Ausstellungsfläche mit Standbau',
                registeredCoExhibitors: 'Angemeldete Unteraussteller:',
                registeredCoExhibitorsNothing: 'Keine Unteraussteller',
                viewAndEditRegistration: 'Anmeldung ansehen und bearbeiten',
                viewRegistration: 'Anmeldung ansehen',
                registerNow: 'Jetzt anmelden',
                registrationConfirmation: 'Anmeldebestätigung',
                yourRegistrationWillBeCheckedBy: 'Nach Bestätigung Ihrer Anmeldung erhalten Sie alle Informationen zur Messevorbereitung ' +
                'und -teilnahme direkt von der Durchführungsgesellschaft ({organizer}). Ihr Ansprechpartner ' +
                'betreut Sie persönlich bei allen Fragen rund um Ihre Messebeteiligung (z.B. Standbau, ' +
                'Logistik, Marketing, Einlass).',
                yourRegistrationWasSuccessfullyConfirmedOn: 'Ihre Anmeldung wurde am {date} erfolgreich bestätigt.',
                examinationAndRegistrationConfirmationPending: 'Prüfung und Anmeldebestätigung ausstehend.',
                examinationAndRegistrationSendingPending: 'Prüfung und Anmeldung verschickt ausstehend.',
                downloadRegistrationConfirmation: 'Anmeldebestätigung herunterladen',
                ifYouHaveAnyQuestionsAboutRegistration: 'Bei Fragen zu Ihrer Anmeldung wenden Sie sich bitte an',
                mail: 'E-Mail',
                phone: 'Telefon',
                tradeFairPreparationAndParticipation: 'Messevorbereitung und Messeteilnahme',
                onceYouHaveConfirmedYourRegistration: 'Nach erfolgter Anmeldebestätigung erhalten Sie alle benötigten ' +
                    'Informationen zur Vorbereitung und zum Ablauf Ihrer Messeteilnahme direkt von der ' +
                    'Durchführungsgesellschaft ({organizer}). Diese betreut Sie persönlich bei allen ' +
                    'Fragen zu Ihrer Messeteilnahme (z.B. Standbau, Logistik, Marketing, Einreise).',
                startOfTheFair: 'Messebeginn',
                ifYouHaveAnyQuestionsAboutTradeFair: 'Bei Fragen zur Messevorbereitung und Messeteilnahme ' +
                    'wenden Sie sich bitte an',
                closingAndExhibitorSurvey: 'Abschluss und Ausstellerbefragung',
                afterTheTradeFairIsOver: 'Nach Abschluss der Messe bitten wir Sie, an der Ausstellerbefragung ' +
                    'teilzunehmen.Diese gibt und wichtige Informationen zur Serviceverbesserung der Auslandsmessebeteiligungen.',
                exhibitorSurveyAvailableFromUntil: 'Ausstellerbefragung verfügbar ab/bis',
                youHaveAlreadyParticipatedInTheExhibitorSurvey: 'Sie haben bereits an der Ausstellerbefragung ' +
                    'teilgenommen - vielen Dank.',
                completeTheExhibitorSurvey: 'Ausstellerbefragung abschließen',
                participationAndConditions: 'Beteiligung und Konditionen',
                standSizesAndPrices: 'Standgrößen und Preise',
                packagesPrices: 'Paketpreise',
                informationOnTheConditionsAndParticipation: 'Informationen zu den Konditionen und Beteiligungsmöglichkeiten dieser Veranstaltung werden auf der Website des AUMA veröffentlicht:',
                minimumArea: 'Mindestfläche',
                maximumArea: 'Maximalfläche',
                qm: 'm<sup>2</sup>',
                priceUnsponsoredHelp: 'Dieser Preis gilt nur für Aussteller, die seit Juli 2021 bereits schon mind. 4x an dieser Messebeteiligung teilgenommen haben.',
                priceSponsored: 'Preis 1. - 4. Teilnahme',
                priceUnsponsored: 'Preis ab 5. Teilnahme',
                pricePublicsector: 'Vollkosten',
                pricePerM2withStandConstruction: 'Preis pro m<sup>2</sup> mit Standbau',
                pricePerM2withoutStandConstruction: 'Preis pro m<sup>2</sup> ohne Standbau',
                registrationFee: 'Registrierungsgebühr',
                fees: 'Gebühren',
                mandatoryFee: 'Pflichtgebühr',
                optionalFee: 'Optional',
                exhibitionAreasOfGermanParticipation: 'Ausstellungsbereiche der deutschen Beteiligung',
                implementationCompany: 'Durchführungsgesellschaft',
                contactPersonForThisTradeFairParticipation: 'Ansprechpartner für diese Messebeteiligung',
                dataAndInformationAboutTheFair: 'Daten und Informationen zur Messe',
                mainOfferings: 'Angebotsschwerpunkte',
                frequency: 'Turnus',
                foundation: 'Gründungsjahr',
                websiteOfTheOrganizer: 'Internetseite des Veranstalters',
                informationInTheAUMATradeFairDatabase: 'Ausführliche Informationen in der Messedatenbank des AUMA',
                organizer: 'Veranstalter',
                visitorNumbers: 'Besucherzahlen',
                exhibitorStructure: 'Ausstellerstruktur',
                visitorStructure: 'Besucherstruktur',
                tradeFairReport2019: 'Messebericht 2019',
                downloads: 'Downloads',
                youHaveNotRegisteredYet: 'Sie haben sich noch nicht für Ihre Teilnahme angemeldet.',
            }
        },
        accreditation: {
            accreditationRequests: 'Akkreditierungsanfragen',
            checkAndApprove: 'prüfen und freigeben',
            status: 'Status',
            statuses: {
                initialAccreditation: 'Erstakkreditierung',
                approved: 'Genehmigt',
                update: 'Aktualisierung'
            },
            companies: 'Unternehmen',
            cityCountry: 'Stadt, Land',
            users: 'Nutzer',
            date: 'Datum',
            similarCompanies: 'Ähnliche Unternehmen',
            currentAccreditationRequest: 'Aktuelle Akkreditierungsanfrage',
            lastAccreditationGranted: 'Zuletzt erteilte Akkreditierung',
            rejectedAt: 'entzogen am {date}',
            currentAccreditation: 'Aktuell akkreditierte Daten',
            companyEntriesWithMatchingCharacteristicsWereFound: 'Es gibt {similarities} Einträge mit Ähnlichkeiten',
            noCompanyEntriesWithMatchingCharacteristicsWereFound: 'Es wurden keine Unternehmenseinträge mit ' +
                'übereinstimmenden Merkmalen gefunden.',
            company: 'Firma',
            street: 'Straße',
            postcode: 'PLZ',
            location: 'Ort',
            state: 'Bundesland',
            poBox: 'Postfach',
            poBoxZip: 'Postfach PLZ',
            phone: 'Telefon',
            fax: 'Fax',
            email: 'E-Mail',
            website: 'Website',
            contactPerson: 'Kontaktperson',
            hrRegistryCourt: 'Handelsregistergericht',
            hrNumber: 'HR Nummer',
            taxNumber: 'Steuernummer',
            vatID: 'USt ID',
            commercialRegisterExtract: 'Handelsregisterauszug',
            authorityOrInstitutionUnderPublicLaw: 'Behörde oder Institution des öffentlichen Rechts',
            participationPublicFounds: 'Beteiligung an institutioneller Förderung aus öffentlichen Mitteln',
            participationOfReligiousCommunities: 'Beteiligung von Religionsgemeinschaften oder juristischen Personen des öffentlichen Rechts',
            yes: 'Ja',
            no: 'Nein',
            connectedCompany: 'Verbundenes Deutsches Unternehmen',
            foreignRepresentation: 'Ausländische Vertretung eines deutschen Unternehmens',
            foreignBranch: 'Ausländische Niederlassung eines deutschen Unternehmens'
        }
    },
}

export default {
  common: {
    title: 'German Pavilion - Online-Registration',
    description: 'German Pavilion - Online-Registration',
    federalForeignTradeFairProgram:
      'The Foreign Trade Fair Participation Programme of Germany',
    inCooperationWith: 'In cooperation with',
    organizer: 'Organizer',
    continue: 'continue',
    save: 'save',
    apply: 'Apply',
    support: 'Support',
    noDataAvailable: 'No data available',
    linesPerPage: 'Lines per page',
    all: 'All',
    close: 'close',
    view: 'view',
    min: 'min',
    max: 'max',
    per: 'per',
    sqm: '㎡',
    none: 'none',
    total: 'Total',
    meter: 'Meter',
    kg: 'kg',
    edit: 'edit',
    delete: 'delete',
    confirm: 'confirm',
    confirmModal: 'Please confirm',
    cancel: 'Cancel',
    deleteConfirmation: 'Are you sure you want to delete {item}?',
    generalConfirmation: 'Are you sure you want to {action}?',
    changeProject: 'Change project',
    copiedSuccessfully: 'Copied successfully',
    link: 'Link',
    fullLinkTag: 'Full link tag',
    id: 'ID',
    yes: 'Yes',
    no: 'No',
    documents: {
      imprint: 'Imprint',
      privacyPolicy: 'Privacy Policy',
    },
    genders: {
      male: 'Mr',
      female: 'Ms',
      divers: 'Genderneutral',
    },
    salutation: {
      male: 'Mr',
      female: 'Ms',
      divers: '',
    },
    default: {
      country: 'Germany',
    },
    pageNotFound: 'Page not found',
    pageNotFoundText: 'This page could not be found.',
    serverErrors: 'Server Error',
    serverErrorsText: '<p>Please wait a few minutes before trying again.</p>' +
    '<p>If this message continues to appear:</p><p>Log out and log in again. It can also help to clear the browser cache of the page on which this error has occurred. On a Windows system, press the key combination Ctrl + F5 on the corresponding page, on a Mac ⌘ + Shift + R.</p>'+
    '<p>If you have any further questions, the German Pavilion support team will be happy to help you at <a href="mailto:support@german-pavilion.com" title="Support German Pavilion">support@german-pavilion.com</a></p>',
    errorApiMessage: 'An error occurred while saving your data',
    errorFetchApiMessage: 'An error occurred while fetching data',
    successApiMessage: 'Data saved successfully',
    incorrectDisplayTitle: 'Display size too small',
    incorrectDisplayButton: 'Send link by e-mail',
    incorrectDisplayBody:
      'For the correct display and smooth use of this online registration platform, you need an end device with\n' +
      '                  a minimum screen width of 1,024 pixels (e.g. tablet or desktop PC). The application is not suitable for\n' +
      '                  smartphones.',
    atbLink: 'https://www.german-pavilion.com/en/portal/atb',
    back: 'Back',
  }
}

<template>
  <div>
    <v-alert
        v-if="!isCorrectSpacesRanges"
        dense
        outlined
        type="error"
    >
      {{ $t('participation.exhibitionSpace.spaceRangeError') }}
    </v-alert>

    <p>{{ $t('participation.editSteps.configurationRegistration.text') }}</p>


    <v-row class="mt-12">
      <v-col>
        <v-row>
          <v-col cols="6" class="px-0">
            <v-autocomplete
                v-model="form.data.template"
                :items="templates"
                item-value="id"
                :item-text="`attributes.title[${$i18n.locale}]`"
                class="col-7"
                :label="$t('participation.editSteps.template.template')"
            />
          </v-col>
          <v-col cols="3" class="text-right">
            <v-btn
                color="secondary"
                class="mt-3"
                :disabled="!form.data.template"
                @click="showAcceptTemplate"
            >
              {{ $t('participation.editSteps.template.apply') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col class="text-right">
        <v-btn
            color="secondary"
            class="mt-3"
            @click="handleSubmit"
        >
          {{ $t('forms.buttons.saveAndContinue') }}
        </v-btn>
      </v-col>
    </v-row>
    <accept-template-modal ref="modal" @submit="handleAcceptTemplate"></accept-template-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AcceptTemplateModal from '@/components/organizer/Application/AcceptTemplateModal'

export default {
  name: 'RegistrationConfigureForm',

  props: {
    fair: Object,
  },

  components: {
    AcceptTemplateModal
  },

  data() {
    return {
      templates: [],

      form: {
        loading: false,

        data: {
          template: null
        }
      }
    }
  },

  created() {
    this.load()
  },

  computed: {
    ...mapGetters('organizer', [
      'isCorrectSpacesRanges',
    ]),
  },

  methods: {
    async load() {
      this.form.loading = true

      let response = (await this.$axios.get(`/api/registration-templates`, {
        params: {
          'languages': '*',
          'include': [],
        },
      })).data

      this.templates = response.data

      this.form.loading = false
    },

    showAcceptTemplate() {
      this.$refs.modal.show()
    },

    async handleAcceptTemplate() {
      this.form.loading = true

      await this.$axios.put(`/api/trade-fairs/${this.fair.id}/apply-registration-template/${this.form.data.template}`)

      this.$snackbar(this.$t('common.successApiMessage'))

      this.$emit('template')

      this.form.loading = false
    },

    async handleSubmit() {
      if (this.isCorrectSpacesRanges) {
        this.$emit('submit')
      }
    },
  },
}
</script>

export default {
  menu: {
    overview: 'Übersicht',
    myTradeFairParticipations: 'Meine Messeteilnahmen',
    accreditation: 'Akkreditierung',
    findTradeFairs: 'Messen finden',
    helpAndAdvice: 'Hilfe & Ratgeber',
    settings: 'Einstellungen',
    changeProject: 'Projekt wechseln',
    editRegistrationForm: 'Anmeldeformular bearbeiten',
    participantRegistration: 'Teilnehmer-Anmeldungen',
    tradeFairSummary: 'Messekurzbericht',
    interestedPersons: 'Interessenten',
    sendNotification: 'Benachrichtigung senden',
    userManagement: 'Nutzerverwaltung',
    start: 'Start',
    fairs: 'Messen',
  },
}

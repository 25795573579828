<template>
  <v-container fluid v-if="fair.id">
    <v-card
        class="elevation-1 mb-5 rounded-0"
        :loading="form.loading"
    >
      <v-card-title class="px-5 d-flex">
        <h3 class="primary--text" v-html="fair.attributes.name_display"></h3>
        <v-spacer></v-spacer>
        <div class="text-right">
          <v-chip
              v-if="fair.attributes.status"
              class="secondary white--text ml-2"
              small
          >
            {{ $t('tables.tradeFair.statuses.' + fair.attributes.status) }}
          </v-chip>
          <v-chip
              v-if="fair.included.is_favorite"
              class="secondary white--text ml-2"
              small
          >
            {{ $t('tables.tradeFair.statuses.interestExpressed') }}
          </v-chip>
        </div>
      </v-card-title>
      <v-card-text class="text--primary px-5">
        <v-row justify="space-between">
          <v-col cols="12" class="pb-0">
            <p class="text-small">{{ fair.attributes.name_display }}</p>
          </v-col>
          <v-col cols="3">
            <small>{{ $t('tables.tradeFair.date') }}</small>
            <p v-if="fair.attributes.start_date_display">{{ fair.attributes.start_date_display[$i18n.locale] }} -
              {{ fair.attributes.end_date | dateByLocale }}</p>
          </v-col>

          <v-col cols="3">
            <small>{{ $t('tables.tradeFair.city') }}</small>
            <p v-if="fair.relationships.country">{{ fair.attributes.city }}, {{ fair.relationships.country.translations[$i18n.locale].country }}</p>
          </v-col>

          <v-col
              v-if="fair.attributes.registration_deadline"
              cols="3"
          >
            <small>{{ $t('tables.tradeFair.registrationDeadline') }}</small>
            <p v-if="fair.attributes.registration_deadline">
              {{ fair.attributes.registration_deadline | dateByLocale }}
            </p>
            <p v-else>
              {{ $t('tables.tradeFair.notYetKnown') }}
            </p>
          </v-col>

          <v-col cols="3">
            <small>{{ $t('tables.tradeFair.participationForm') }}</small>
            <p v-if="fair.attributes.type">{{ fair.attributes.type.type }}</p>
          </v-col>

          <v-col
              cols="12"
              class="pt-0 mt-0"
          >
            <v-btn
                v-if="!form.loading 
                  && !isTradeFairInactive 
                  && company.accreditationApprovedAt 
                  && !company.accreditationRejectedAt 
                  && fair.attributes.registration_type == REGISTRATION_TYPES.ONLINE"
                :to="{ name: 'exhibitor.participations.application', params: { id: fair.id } }"
                class="secondary elevation-0 mr-5"
            >
              {{ $t('tables.tradeFair.registerNow') }}
            </v-btn>

            <v-btn
                v-if="!form.loading && (!company.accreditationApprovedAt || company.accreditationRejectedAt)"
                :to="{ name: 'exhibitor.accreditation', params: { id: fair.id } }"
                class="secondary elevation-0 mr-5"
            >
              {{ $t('settings.sections.accreditation.statuses.button.carryOut') }}
            </v-btn>
            <v-btn
                v-if="!fair.included.is_favorite"
                :disabled="form.loading || participation && !isAvailableToFavorite({ status: participation.attributes.status })"
                v-on:click="fair.included.is_favorite = true, say({ tradeFair: fair, participation: participation })"
                class="elevation-0 mr-5"
                outlined
            >
              {{ $t('tables.tradeFair.bookmark') }}
            </v-btn>
          </v-col>

        </v-row>
      </v-card-text>
    </v-card>

    <v-expansion-panels
        class="mb-10 elevation-0 rounded-0"
        v-model="panels"
        multiple
        flat
    >
      <v-expansion-panel
          v-if="fair.attributes.registration_type === 'print' && fair.attributes.flag_registration_is_open"
          class="elevation-1 rounded-0"
      >
        <v-expansion-panel-header>
          <h3>
            {{ $t('tables.participation.form.registrationAndParticipation') }}
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <p>
            {{ $t('tables.participation.form.atPresentOnlineRegistrationVia') }}
          </p>
          <p>
            {{ $t('tables.participation.form.registrationDocumentsOnTheAUMA') }}
            <a :href="fair.attributes.linkAumaDatabase">{{ fair.attributes.link_auma_database }}</a>
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <participation-and-conditions
          v-if="fair.attributes.flag_registration_is_open && fair.attributes.registration_type === 'online'"
          :company="company"
          :fair="fair"
      />

      <execution-company
          :trade-fair="fair"
          :organizer="fair.relationships.organizer"
      />

      <fair-information :fair="fair"/>

    </v-expansion-panels>

  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ParticipationAndConditions from '@/components/common/TradeFair/ParticipationAndConditions'
import ExecutionCompany from '@/components/common/TradeFair/ExecutionCompany'
import FairInformation from '@/components/common/TradeFair/FairInformation'
import {REGISTRATION_TYPES} from '@/enums/registrationTypes'
import {PARTICIPATION_STATUS} from '@/enums/participationStatus'

export default {
  name: 'ExhibitorFairDetail',

  components: {
    ParticipationAndConditions,
    ExecutionCompany,
    FairInformation,
  },

  props: {
    fairs: {
      type: Array,
      default: null,
    },
    participations: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      fair: {},
      participation: {},

      form: {
        loading: false,
      },

      panels: [],

      PARTICIPATION_STATUS,
      REGISTRATION_TYPES,
    }
  },

  computed: {
    ...mapGetters('company', [
      'company',
    ]),

    isTradeFairInactive() {
      return this.fair?.attributes.flag_cancelled ||
          this.fair.included.is_finished ||
          this.fair.attributes.flag_registration_external_locked ||
          this.fair.included.is_deadline_passed ||
          !this.fair.attributes.flag_registration_is_open
    },


  },

  async created() {
    await this.load()
  },

  methods: {
    ...mapActions('participation', [
      'setNotedTradeFair',
      'unsetNotedTradeFair',
      'fetchParticipations',
    ]),

    async load() {
      this.form.loading = true

      try {
        let response1 = (await this.$axios.get(`/api/trade-fairs/${this.$route.params.id}`, {
          params: {
            'languages': '*',
            'include': [
              'country',
              'industrySectors',
              'exhibitionAreas',
              'spaces',
              'packages',
              'services',
              'organizer',
            ],
          },
        })).data
        this.fair = response1.data

        let response2 = (await this.$axios.get(`/api/trade-fairs/${this.$route.params.id}/participation`, {
          params: {
            'languages': '*',
            'include': [
              'coexhibitors',
            ],
          },
        })).data
        this.participation = response2.data

      } catch (e) {
        await this.$router.push({name: 'exhibitor.fairs'})
      }

      this.form.loading = false
    },

    async say({participation, tradeFair}) {
      if (this.form.loading) {
        return
      }

      if (participation && !this.isAvailableToFavorite({status: participation.status})) {
        return
      }

      this.form.loading = true
      if (tradeFair.included.is_favorite) {
        if (await this.setNotedTradeFair(tradeFair.id)) {
          await this.fetchParticipations()
        }
      } else {
        if (await this.unsetNotedTradeFair(tradeFair.id)) {
          await this.fetchParticipations()
        }
      }

      this.$emit('submit')
      this.form.loading = false
    },

    isAvailableToFavorite({status}) {
      if ([
        PARTICIPATION_STATUS.REJECTED,
        PARTICIPATION_STATUS.CANCELLED,
        PARTICIPATION_STATUS.SUBMITTED,
        PARTICIPATION_STATUS.REPROCESSED,
        PARTICIPATION_STATUS.APPROVED
      ].includes(status)) {
        return false
      }

      return true
    },
  },

  async beforeRouteUpdate(to, from, next) {
    if (to.params.id !== from.params.id) {
      next()

      this.load()
    }
  },
}
</script>

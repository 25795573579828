export default {
  interest: {
    interestedPersons: 'Interessenten',
    company: 'Firma',
    location: 'Ort',
    accredited: 'Akkreditiert',
    contacted: 'Kontaktiert',
    email: 'E-Mail',
    website: 'Website',
    reservedOn: 'Vorgemerkt am',
    addressAndContactDetails: 'Anschrift und Kontaktdaten',
    phone: 'Telefon',
    contactPerson: 'Ansprechpartner',
    countryInterest: 'Länderinteresse',
    industryInterest: 'Brancheninteresse',
    furtherTradeFairParticipations: 'Weitere Messeteilnahmen',
    bookmarkedTradeFairs: 'Vorgemerkte Messen',
    participations: 'Teilnahmen',
    exportAsExcelSheet: 'Als Excel herunterladen',
    exportAsPDFFile: 'Als PDF exportieren',
    exportAsCsvFile: 'Als CSV-Datei exportieren',
  },
}

<template>
  <div class="row">
    <div class="col-6 mt-5">
      <date-input
          :label="$t('participation.editSteps.deadline.registrationDeadline')"
          :initial-value="form.deadline"
          :end-limit="true"
          :start-trade-fair-date="fair.attributes.start_date"
          @changed="updateDeadline"
      />
      <v-btn
          class="elevation-0 secondary"
          :loading="form.loading"
          :disabled="form.loading"
          @click="handleSaveDeadline"
      >
        {{ $t('forms.buttons.save') }}
      </v-btn>
    </div>
    <div class="col-6">
      <contact-person-picker
          :manager="projectManager"
          :is-submit-available="false"
          @managerChanged="handleManagerChange"
      />

      <v-text-field
          v-model="form.data.name"
          :label="$t('forms.fields.name')"
          :error-messages="errors.name"
          :loading="form.loading"
          class="rounded-0"
          filled
          dense
      />

      <v-text-field
          v-model="form.data.email"
          :label="$t('forms.fields.email')"
          :error-messages="errors.email"
          :loading="form.loading"
          class="rounded-0"
          filled
          dense
      />

      <v-text-field
          v-model="form.data.phone"
          :label="$t('forms.fields.phone')"
          :error-messages="errors.phone"
          :loading="form.loading"
          class="rounded-0"
          filled
          dense
      />

      <v-btn
          class="elevation-0 secondary"
          :loading="form.loading"
          :disabled="form.loading"
          @click="handleSaveContactPerson"
      >
        {{ $t('forms.buttons.save') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import validation from '@/mixins/validate'
import DateInput from '@/components/base/BaseDateInput'
import ContactPersonPicker from '@/components/organizer/Application/ContactPersonPicker'

export default {
  name: 'ContactPersonFields',

  mixins: [
    validation,
  ],

  components: {
    DateInput,
    ContactPersonPicker
  },

  props: {
    fair: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      users: [],
      currentManagerId: null,

      deadline: null,

      form: {
        loading: false,

        customFieldsMap: {
          name: 'contact_name',
          email: 'contact_email',
          phone: 'contact_phone',
        },

        data: {
          name: null,
          email: null,
          phone: null,
        },

        validationRules: {
          name: {
            required,
          },
          email: {
            required,
            email,
          },
          phone: {},
        }
      },
    }
  },

  computed: {
    projectManager() {
      return (this.fair.relationships.access_users && this.fair.relationships.access_users.length) ? this.fair.relationships.access_users[this.fair.relationships.access_users.length - 1] : null
    },
  },

  created() {
    this.form.data.name = this.fair.attributes.contactName
    this.form.data.email = this.fair.attributes.contactEmail
    this.form.data.phone = this.fair.attributes.contactPhone

    this.form.deadline = this.fair.attributes.registration_deadline
  },

  methods: {
    updateDeadline(deadline) {
      this.form.deadline = deadline
    },

    handleManagerChange(manager) {
      if (!manager) {
        return
      }

      this.form.data.name = manager.attributes.full_name
      this.form.data.email = manager.attributes.email
      this.form.data.phone = manager.attributes.phone
      this.currentManagerId = manager.id
    },

    async handleSaveDeadline() {
      this.form.loading = true

      await this.$axios.patch(`/api/trade-fairs/${this.fair.id}`, {
        registration_deadline: this.$options.filters.dateTime(this.form.deadline),
      })

      this.$snackbar(this.$t('common.successApiMessage'))

      this.form.loading = false

      this.$emit('submit')
    },

    async handleSaveContactPerson() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.$axios.patch(`/api/trade-fairs/${this.fair.id}`,  { ...this.getFormData() })

      if (this.currentManagerId && this.currentManagerId !== this.projectManager.id && this.fair.attributes.flag_registration_is_open) {
        await this.$axios.post(`/api/users/${this.currentManagerId}/trade-fairs/${this.fair.id}`)
      }

      this.$snackbar(this.$t('common.successApiMessage'))

      this.$emit('submit')
    },

    async load() {
      let response = (await this.$axios.get('/api/users', {
        params: {
          'page[size]': 100,
        },
      })).data

      this.users = response.data
    },
  },

  watch: {
    currentManagerId(value) {
      if (value) {
        const manager = this.users.find(manager => manager.id === parseInt(value))
        if (manager) {
          this.form.data.name = manager.attributes.full_name
          this.form.data.email = manager.attributes.email
        }
      }
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>

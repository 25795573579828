<template>
  <fragment>
    <v-col cols="12">
      <h3 class="mb-5">
        {{ $t('accreditation.steps.publicParticipation.subTitle') }}
      </h3>
      <p v-html="$t('accreditation.infoSponsored')"></p>

      <div class="d-flex">
        <v-checkbox
            v-model="form.data.publicSectorInvolvement"
            class="my-0"
            :label="$t('accreditation.steps.publicParticipation.declarations.publicSectorInvolvement')"
            dense
        >
        </v-checkbox>
        <v-icon
            size="20"
            style="cursor: pointer; width: 20px; height: 20px"
            class="align-start primary--text mt-1 ml-1"
            @click="openModalSectorInvolvement"
        >
          fal fa-question-circle
        </v-icon>
      </div>

      <v-checkbox
          v-model="form.data.publicSectorInstitution"
          :label="$t('accreditation.steps.publicParticipation.declarations.publicSectorInstitution')"
          class="my-0"
          dense
      />


      <div class="d-flex">
        <v-checkbox
            v-model="form.data.publicSectorMajority"
            :label="$t('accreditation.steps.publicParticipation.declarations.publicSectorMajority')"
            class="my-0"
            dense
        />
        <v-icon
            size="20"
            style="cursor: pointer; width: 20px; height: 20px"
            class="align-start primary--text mt-1 ml-1"
            @click="openModalSectorMajority"
        >
          fal fa-question-circle
        </v-icon>
      </div>

      <error-message :messages="form.errorMessages"/>
    </v-col>


    <v-dialog v-model="modalOpenSectorInvolvement" max-width="800px">
      <v-card>
        <v-card-title class="justify-space-between">
          <h4 class="primary--text">
            {{ $t('accreditation.steps.publicParticipation.subTitle') }}
          </h4>
          <v-icon size="17" @click="closeModalSectorInvolvement">fal fa-times</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <p class="mt-5" v-html="$t('accreditation.infoSponsoredInstitutionalOrPublic')">
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="secondary" @click="closeModalSectorInvolvement">
            {{ $t('common.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalOpenSectorMajority" max-width="800px">
      <v-card>
        <v-card-title class="justify-space-between">
          <h4 class="primary--text">
            {{ $t('accreditation.steps.publicParticipation.subTitle') }}
          </h4>
          <v-icon size="17" @click="closeModalSectorMajority">fal fa-times</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <p class="mt-5" v-html="$t('accreditation.infoSectorMajority')">
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="secondary" @click="closeModalSectorMajority">
            {{ $t('common.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-row
        class="mx-0 mb-2 px-3"
    >
      <v-btn
          v-if="!accreditation.attributes.accreditation_approved_at && accreditation_old.attributes.accreditation_approved_at"
          @click="$emit('cancel')"
          color="error"
          class="elevation-0"
      >
        {{ $t('common.cancel') }}
      </v-btn>

      <v-btn
          @click="handleSubmit"
          color="primary"
          class="elevation-0 ml-auto"
      >
        {{ $t('forms.buttons.continue') }}
      </v-btn>
    </v-row>
  </fragment>
</template>

<script>
import {SAVE_PUBLIC_PARTICIPATION} from '@/store/loading-types'
import validation from '@/mixins/validate'
import ErrorMessage from '@/components/base/Messages/ErrorMessage'

export default {
  name: 'PublicParticipation',

  components: {
    ErrorMessage,
  },

  mixins: [validation],

  props: {
    accreditation: Object,
    accreditation_old: Object,
  },

  data() {
    return {
      modalOpenSectorInvolvement: false,
      modalOpenSectorMajority: false,

      form: {
        customFieldsMap: {
          publicSectorInvolvement: 'declared_public_sector_involvement',
          publicSectorInstitution: 'declared_public_sector_institution',
          publicSectorMajority: 'declared_public_sector_majority',
        },

        data: {
          publicSectorInvolvement: false,
          publicSectorInstitution: false,
          publicSectorMajority: false,
        },

        validationRules: {},

        custom: {
          validationRulesMap: {
            publicSectorInvolvement: {
              sameAs: 'checkboxRequired',
            },
            publicSectorInstitution: {
              sameAs: 'checkboxRequired',
            },
            publicSectorMajority: {
              sameAs: 'checkboxRequired',
            },
          },
        },
        errorMessages: [],
      },

      loaders: {
        savePublicParticipation: SAVE_PUBLIC_PARTICIPATION,
      },
    }
  },

  async created() {
    this.setInitialFormData(this.accreditation.attributes)
  },

  methods: {
    openModalSectorInvolvement() {
      this.modalOpenSectorInvolvement = true
    },
    closeModalSectorInvolvement() {
      this.modalOpenSectorInvolvement = false
    },

    openModalSectorMajority() {
      this.modalOpenSectorMajority = true
    },
    closeModalSectorMajority() {
      this.modalOpenSectorMajority = false
    },

    async handleSubmit() {
      this.form.errorMessages = []

      this.$v.$touch()
      if (this.$v.$invalid) {
        this.form.errorMessages.push(this.$t('validation.activateCheckboxesToProceed'))

        return
      }

      this.form.loading = true
      try {
        await this.$axios.patch(`/api/accreditations/${this.accreditation.id}`, {
          ...this.getFormData(),
        })

        this.$snackbar(this.$t('common.successApiMessage'))

        this.$emit('submit')
      } catch (e) {
        if (e?.response?.status === 422) {
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
      }

      this.form.loading = false
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>

<template>
  <v-container>
    <v-row justify="center">

      <v-col
        cols="7"
        class="text-center py-10"
      >
        <v-icon
          class="primary--text mb-4"
          size="50"
        >
          fal fa-check-circle
        </v-icon>
        <h1 class="primary--text mb-4">
          {{ $t('onboarding.header.thanksForRegistration') }}
        </h1>
        <p class="text--primary">
          {{ $t('onboarding.header.completeProfile') }}
        </p>
      </v-col>

    </v-row>

    <v-stepper
      v-model="step"
      class="elevation-1 rounded-0 mb-10"
      non-linear
    >
      <v-stepper-header class="elevation-0">
        <v-stepper-step
          :complete="step > 1"
          step="1"
        >
          {{ $t('onboarding.steps.companyData.title') }}
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          :complete="step > 2"
           step="2"
        >
          {{ $t('onboarding.steps.countriesAndIndustries.title') }}
        </v-stepper-step>

        <v-divider />

        <v-stepper-step
          :complete="step > 3"
          step="3"
        >
          {{ $t('onboarding.steps.tradeFairs.title') }}
        </v-stepper-step>

        <v-divider />

        <v-stepper-step step="4">
          {{ $t('onboarding.steps.toAccreditation') }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>

        <v-stepper-content step="1">
          <company @submit="step = 2"/>
        </v-stepper-content>

        <v-stepper-content step="2">
          <interest-profile @submit="step = 3"/>
        </v-stepper-content>

        <v-stepper-content step="3">
          <trade-fairs @submit="step = 4"/>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-row justify="center">

            <v-col
              cols="3"
              class="mt-5"
            >
              <h3 class="mb-4">{{ $t('onboarding.steps.nextStep.title') }}</h3>
            </v-col>

            <v-col cols="7">
              <v-list>
                <v-list-item :to="{ name: 'exhibitor.accreditation' }">
                  <v-list-item-icon>
                    <v-icon
                      size="20"
                      class="primary--text"
                    >
                      fal fa-arrow-right fa-fw
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    {{ $t('onboarding.steps.nextSteps.actions.carryOutAccreditation') }}
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>

          </v-row>

        </v-stepper-content>

      </v-stepper-items>
    </v-stepper>

  </v-container>
</template>

<script>
import Company from '@/components/exhibitor/Onboarding/Company'
import InterestProfile from '@/components/exhibitor/Onboarding/InterestProfile'
import TradeFairs from '@/components/exhibitor/Onboarding/TradeFairs'

export default {
  name: 'ExhibitorOnboard',

  components: {
    Company,
    InterestProfile,
    TradeFairs,
  },

  data() {
    return {
      step: 1,
    }
  },
}
</script>

<template>
  <v-row>
    <v-col class="col-12 col-lg-3 px-5" :hidden="!showFilters">
      <FairSearch
          ref="search"
          v-model="filters"
          @disclaimer="showDisclaimer = false"
      />

      <v-btn color="secondary" @click="showFilters = false" elevation="0" :hidden="!showFilters">
        {{ $t('public.fairs.applyFilters') }}
      </v-btn>
    </v-col>

    <v-col :class="[ showFilters ? 'col-12 col-lg-9' : 'col-12']">
      <v-sheet class="pa-5 mb-5" elevation="1" v-if="showDisclaimer">
        <v-row>
          <v-col class="col-12 col-md-7">
            <template>
              <h2 class="primary--text mb-3">{{ $t('public.fairs.findTradeFairs') }}</h2>
              <div v-html="$t('public.fairs.findTradeFairsDisclaimer')">
              </div>
              <v-btn color="primary" elevation="1" to="/signup" :disabled="isAuthorized">
                {{ $t('public.fairs.findTradeFairsDisclaimerButton') }}
              </v-btn>
            </template>
          </v-col>
          <v-col cols="col-12 col-md-5 pt-10">
            <logos/>
          </v-col>
        </v-row>
      </v-sheet>

      <v-sheet class="pa-5" elevation="1">
        <h3 class="primary--text mb-3">{{ $t('fairs.findTradeFairParticipations') }}</h3>

        <v-btn color="secondary" @click="showFilters = true" elevation="0" v-if="!showFilters">
          <v-icon size="14" class="mr-2">fal fa-filter</v-icon>
          {{ $t('public.fairs.customizeFilters') }}
        </v-btn>

        <FairList :filters="filters"/>
      </v-sheet>
    </v-col>

  </v-row>

</template>

<script>
import FairSearch from '@/components/public/Fair/Search'
import FairList from '@/components/public/Fair/List'
import Logos from '@/components/base/Logos/Logos'
import { mapGetters } from 'vuex'

export default {
  name: 'PublicFairs',

  components: {
    FairSearch,
    FairList,
    Logos
  },

  data() {
    return {
      filters: {},
      showFilters: false,
      showDisclaimer: true,
    }
  },

  computed: {
    ...mapGetters('auth', [
      'isAuthorized',
    ]),
  },
}
</script>

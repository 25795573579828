<template>
  <v-container class="pa-9" fluid>
    <v-card class="elevation-1 rounded-0">
      <v-card-title>
        <h4 class="primary--text">{{ $t('tables.accreditation.accreditationRequests') }}</h4>
      </v-card-title>
      <v-card-subtitle>
        {{ $t('tables.accreditation.checkAndApprove') }}
      </v-card-subtitle>

      <v-divider></v-divider>

      <v-data-table
          :headers="headers"
          :loading="form.loading"
          :items="accreditations"
          :page.sync="form.page"
          :options.sync="options"
          :footer-props="{itemsPerPageOptions: [10, 25, 50, 100], showFirstLastPage: true}"
          :server-items-length="form.total"
          item-key="id"
          singleExpand: false
          show-expand
      >
        <template v-slot:item.type="{ item }">
          <v-chip v-if="!item.included.old" small>{{
              $t('tables.accreditation.statuses.initialAccreditation')
            }}
          </v-chip>
          <v-chip v-else small>{{ $t('tables.accreditation.statuses.update') }}</v-chip>
        </template>

        <template v-slot:item.company="{ item }">
          <router-link class="text-center" :to="{ name: 'admin.company', params: { id: item.attributes.company_id } }">
            {{ item.attributes.company }}
          </router-link>
        </template>

        <template v-slot:item.city="{ item }">
          {{ item.attributes.city }}, {{ item.relationships.country.translations[$i18n.locale].country }}
        </template>

        <template v-slot:item.email="{ item }">
          <a v-if="item.relationships.user" :href="'mailto:'+item.relationships.user.attributes.email">{{
              item.relationships.user.attributes.email
            }}</a>
        </template>

        <template v-slot:item.email_verified_at="{ item }">
          <v-icon size="17">fal {{
              item.relationships.user && item.relationships.user.attributes.email_verified_at ? 'fa-check' : 'fa-times'
            }}
          </v-icon>
        </template>

        <template v-slot:item.similar_companies="{ item }">
          {{ item.included.similar_companies.length }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-card-actions>
            <v-btn
                :loading="form.loading"
                @click="handleApprove(item)"
                color="secondary"
                icon
            >
              <v-icon>fal fa-check-circle</v-icon>
            </v-btn>
            <v-btn
                :loading="form.loading"
                @click="handleReject(item)"
                color="secondary"
                icon
            >
              <v-icon>fal fa-ban</v-icon>
            </v-btn>
          </v-card-actions>
        </template>

        <template v-slot:item.accreditation_request_at="{ item }">
          <span>{{ item.attributes.accreditation_request_at | dateByLocale }}</span>
        </template>

        <template v-slot:item.notes="{ item }">
          <router-link class="text-center"
                       :to="{ name: 'admin.notes', params: { id: item.attributes?.company_id } }">
            {{ item.included.notes_count ?? '-' }}
          </router-link>
        </template>

        <template v-slot:expanded-item="{ item }">
          <td :colspan="headers.length" class="elevation-1 px-1 grey lighten-5">
            <v-container fluid>
              <v-row>

                <v-col cols="8" class="px-7 pb-0">
                  <table class="accreditation">
                    <tr>
                      <th></th>
                      <th>{{ $t('tables.accreditation.currentAccreditationRequest') }}</th>
                      <th v-if="!item.included.old?.attributes.accreditation_rejected_at">{{ $t('tables.accreditation.lastAccreditationGranted') }}</th>
                      <th v-if="item.included.old?.attributes.accreditation_rejected_at" class="error--text">{{ $t('tables.accreditation.lastAccreditationGranted') }}<br>({{ $t('tables.accreditation.rejectedAt', {date: formatDateByLocale(item.included.old?.attributes.accreditation_rejected_at) }) }})</th>
                    </tr>
                    <tr>
                      <td>{{ $t('tables.accreditation.company') }}</td>
                      <td>
                        {{ item.attributes.company }}
                      </td>
                      <td>
                        {{ item.included.old?.attributes.company ?? '-' }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('tables.accreditation.street') }}</td>
                      <td>
                        {{ item.attributes.street }}
                      </td>
                      <td>
                        {{ item.included.old?.attributes.street ?? '-' }}
                      </td>
                    </tr>

                    <tr>
                      <td>{{ $t('forms.fields.addresssupplement') }}</td>
                      <td>
                        {{ item.attributes.address_supplement }}
                      </td>
                      <td>
                        {{ item.included.old?.attributes.address_supplement ?? '-' }}
                      </td>
                    </tr>

                    <tr>
                      <td>{{ $t('tables.accreditation.postcode') }}</td>
                      <td>
                        {{ item.attributes.postalcode }}
                      </td>
                      <td>
                        {{ item.included.old?.attributes.postalcode ?? '-' }}
                      </td>
                    </tr>

                    <tr>
                      <td>{{ $t('tables.accreditation.location') }}</td>
                      <td>
                        {{ item.attributes.city }}, {{ item.relationships.country.translations[$i18n.locale].country }}
                      </td>
                      <td v-if="item.included.old">
                        {{ item.included.old?.attributes.city ?? '-' }},
                        {{ item.included.old?.relationships.country?.translations[$i18n.locale].country }}
                      </td>
                      <td v-else>
                        -
                      </td>
                    </tr>

                    <tr>
                      <td>{{ $t('tables.accreditation.state') }}</td>
                      <td>
                        {{ item.relationships.federal_state?.attributes.name[$i18n.locale] }}
                      </td>
                      <td v-if="item.included.old">
                        {{ item.included.old.relationships.federal_state?.attributes.name[$i18n.locale] }}
                      </td>
                      <td v-else>
                        -
                      </td>
                    </tr>

                    <tr>
                      <td>{{ $t('tables.accreditation.foreignRepresentation') }}</td>
                      <td>
                        {{
                          item.attributes.is_foreign_representation ? $t('tables.accreditation.yes') : $t('tables.accreditation.no')
                        }}
                      </td>
                      <td v-if="item.included.old">
                        {{
                          item.included.old.attributes.is_foreign_representation ? $t('tables.accreditation.yes') : $t('tables.accreditation.no')
                        }}
                      </td>
                      <td v-else>
                        -
                      </td>
                    </tr>

                    <tr>
                      <td>{{ $t('tables.accreditation.foreignBranch') }}</td>
                      <td>
                        {{
                          item.attributes.is_foreign_branch ? $t('tables.accreditation.yes') : $t('tables.accreditation.no')
                        }}
                      </td>
                      <td v-if="item.included.old">
                        {{
                          item.included.old.attributes.is_foreign_branch ? $t('tables.accreditation.yes') : $t('tables.accreditation.no')
                        }}
                      </td>
                      <td v-else>
                        -
                      </td>
                    </tr>

                    <tr>
                      <td>{{ $t('tables.accreditation.connectedCompany') }}</td>
                      <td>
                        {{ item.attributes.related_company }}
                      </td>
                      <td v-if="item.included.old">
                        {{ item.included.old?.attributes.related_company }}
                      </td>
                      <td v-else>
                        -
                      </td>
                    </tr>

                    <tr>
                      <td>{{ $t('tables.accreditation.phone') }}</td>
                      <td>
                        {{ item.attributes.phone }}
                      </td>
                      <td v-if="item.included.old">
                        {{ item.included.old?.attributes.phone }}
                      </td>
                      <td v-else>
                        -
                      </td>
                    </tr>

                    <tr>
                      <td>{{ $t('tables.accreditation.email') }}</td>
                      <td>
                        {{ item.attributes.email }}
                      </td>
                      <td v-if="item.included.old">
                        {{ item.included.old?.attributes.email }}
                      </td>
                      <td v-else>
                        -
                      </td>
                    </tr>

                    <tr>
                      <td>{{ $t('tables.accreditation.website') }}</td>
                      <td
                      >
                        <a
                            v-if="item.attributes.website"
                            :href="item.attributes.website"
                            target="_blank"
                        >
                          {{ item.attributes.website }}
                        </a>
                      </td>
                      <td v-if="item.included.old">
                        <a
                            v-if="item.included.old?.attributes.website"
                            :href="item.included.old?.attributes.website"
                            target="_blank"
                        >
                          {{ item.included.old?.attributes.website }}
                        </a>
                      </td>
                      <td v-else>
                        -
                      </td>
                    </tr>

                    <tr>
                      <td>{{ $t('tables.accreditation.contactPerson') }}</td>
                      <td>
                        <span v-if="item.attributes.contact_gender">
                          {{ $t(`common.salutation.${item.attributes.contact_gender}`) }}
                        </span>
                        {{ item.attributes.contact_first_name }} {{ item.attributes.contact_last_name }} <br/>
                        {{ item.attributes.contact_phone }}<br/>
                        {{ item.attributes.contact_email }}<br/>
                      </td>
                      <td v-if="item.included.old">
                        {{ $t(`common.salutation.${item.attributes.contact_gender}`) }}
                        {{ item.included.old?.attributes.contact_first_name }}
                        {{ item.included.old?.attributes.contact_last_name }} <br/>
                        {{ item.included.old?.attributes.contact_phone }}<br/>
                        {{ item.included.old?.attributes.contact_email }}<br/>
                      </td>
                      <td v-else>
                        -
                      </td>
                    </tr>

                    <tr>
                      <td>{{ $t('tables.accreditation.hrRegistryCourt') }}</td>
                      <td>
                        {{ item.attributes.registry_court }}
                      </td>
                      <td>
                        {{ item.included.old?.attributes.registry_court }}
                      </td>
                    </tr>
                    <tr>
                      <td>{{ $t('tables.accreditation.hrNumber') }}</td>
                      <td>
                        {{ item.attributes.registry_number }}
                      </td>
                      <td>
                        {{ item.included.old?.attributes.registry_number }}
                      </td>
                    </tr>

                    <tr>
                      <td>{{ $t('tables.accreditation.vatID') }}</td>
                      <td>
                        {{ item.attributes.vat_id }}
                      </td>
                      <td>
                        {{ item.included.old?.attributes.vat_id }}
                      </td>
                    </tr>

                    <tr>
                      <td>{{ $t('tables.accreditation.commercialRegisterExtract') }}</td>
                      <td v-if="item.included.has_document">
                        <a
                            @click="downloadDocument(item.id)"
                            style="cursor: pointer"
                        >
                          Download (PDF)
                        </a>
                      </td>
                      <td v-else>-</td>
                      <td v-if="item.included.old">
                        <a
                            @click="downloadDocument(item.included.old.id)"
                            style="cursor: pointer"
                        >
                          Download (PDF)
                        </a>
                      </td>
                      <td v-else>-</td>
                    </tr>


                    <tr>
                      <td>{{ $t('accreditation.underPublicLaw.publicSectorInvolvement') }}</td>
                      <td>
                        {{
                          item.attributes.declared_public_sector_involvement ? $t('tables.accreditation.no') : $t('tables.accreditation.yes')
                        }}
                      </td>
                      <td v-if="item.included.old">
                        {{
                          item.included.old.attributes.declared_public_sector_involvement ? $t('tables.accreditation.no') : $t('tables.accreditation.yes')
                        }}
                      </td>
                      <td v-else>-</td>
                    </tr>

                    <tr>
                      <td>{{ $t('accreditation.underPublicLaw.publicSectorInstitution') }}</td>
                      <td>
                        {{
                          item.attributes.declared_public_sector_institution ? $t('tables.accreditation.no') : $t('tables.accreditation.yes')
                        }}
                      </td>
                      <td v-if="item.included.old">
                        {{
                          item.included.old.attributes.declared_public_sector_institution ? $t('tables.accreditation.no') : $t('tables.accreditation.yes')
                        }}
                      </td>
                      <td v-else>-</td>
                    </tr>


                    <tr>
                      <td>{{ $t('accreditation.underPublicLaw.publicSectorMajority') }}</td>
                      <td>
                        {{
                          item.attributes.declared_public_sector_majority ? $t('tables.accreditation.no') : $t('tables.accreditation.yes')
                        }}
                      </td>
                      <td v-if="item.included.old">
                        {{
                          item.included.old.attributes.declared_public_sector_majority ? $t('tables.accreditation.no') : $t('tables.accreditation.yes')
                        }}
                      </td>
                      <td v-else>-</td>
                    </tr>
                  </table>
                </v-col>

                <div class="col-4" v-if="!item.included.similar_companies.length">
                  <p>{{ $t('tables.accreditation.noCompanyEntriesWithMatchingCharacteristicsWereFound') }}</p>
                </div>

                <div class="col-4" v-else>
                  <p>{{ $t('tables.accreditation.companyEntriesWithMatchingCharacteristicsWereFound', { similarities: item.included.similar_companies.length }) }}:</p>
                  <ul>
                    <li
                      v-for="similarCompany in item.included.similar_companies"
                      :key="similarCompany.id"
                    >
                      <a :href="'/admin/companies/' + similarCompany.attributes.company_id" target="_blank">{{ similarCompany.attributes.company }}</a>
                      ({{ similarCompany.included.company_is_similar ? $t('tables.accreditation.company') + ': ' + similarCompany.included.company_is_similar : '' }}
                      {{  similarCompany.included.website_is_similar ? $t('tables.accreditation.website') + ': ' + similarCompany.included.website_is_similar : '' }}
                      {{  similarCompany.included.vat_id_is_similar ? $t('tables.accreditation.vatID') + ': ' + similarCompany.included.vat_id_is_similar : '' }}
                      {{  similarCompany.included.registry_number_is_similar ? $t('tables.accreditation.hrNumber') + ': ' + similarCompany.included.registry_number_is_similar : '' }}
                      )
                    </li>
                  </ul>
                </div>

              </v-row>
            </v-container>
          </td>
        </template>
      </v-data-table>

    </v-card>
  </v-container>
</template>

<script>
import {mapActions} from 'vuex'
import date from "@/mixins/date";

export default {
  name: 'AdminAccreditations',

  mixins: [date],

  data() {
    return {
      options: {},

      accreditations: [],

      form: {
        loading: false
      },
    }
  },

  computed: {
    headers() {
      return [
        {
          text: '',
          value: 'data-table-expand'
        },
        {
          text: this.$t('tables.accreditation.status'),
          value: 'type',
          sortable: false,
        },
        {
          text: this.$t('tables.accreditation.companies'),
          value: 'company',
          sortable: false,
        },
        {
          text: this.$t('tables.accreditation.cityCountry'),
          value: 'city',
          sortable: false,
        },
        {
          text: this.$t('tables.accreditation.users'),
          value: 'email',
          sortable: false,
        },
        {
          text: this.$t('tables.accreditation.date'),
          value: 'accreditation_request_at',
          sortable: false,
        },
        {
          text: this.$t('tables.accreditation.similarCompanies'),
          value: 'similar_companies',
          sortable: false,
        },
        {
          text: this.$t('users.confirmedEmailAddress'),
          value: 'email_verified_at',
          sortable: false,
        },
        {
          text: this.$t('help.notes.notes'),
          value: 'notes',
          sortable: false,
          align: 'right',
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
        },
      ]
    }
  },

  methods: {
    ...mapActions('accreditation', [
      'fetchAccreditations',
      'downloadDocument'
    ]),

    async load() {
      this.form.loading = true

      let sort = ''

      for (let i in this.options.sortBy) {
        sort += this.options.sortDesc[i] ? '-' + this.options.sortBy[i] : this.options.sortBy[i]
      }

      let response = (await this.$axios.get('/api/accreditations', {
        params: {
          'languages': '*',
          'page[number]': this.options.page ?? 1,
          'page[size]': this.options.itemsPerPage ?? 10,
          'include': [
            'country',
            'company',
            'federalState',
          ],
          'sort': sort,
          'similar': true,
        },
      })).data

      this.accreditations = response.data

      this.form.total = response.meta.paginator.total
      this.form.loading = false
    },

    async handleApprove(item) {
      this.form.loading = true

      await this.$axios.post(`/api/accreditations/${item.id}/approve`)

      this.$snackbar(this.$t('common.successApiMessage'))

      this.load()

      this.fetchAccreditations()
    },

    async handleReject(item) {
      this.form.loading = true

      await this.$axios.post(`/api/accreditations/${item.id}/reject`)

      this.$snackbar(this.$t('common.successApiMessage'))

      this.load()

      this.fetchAccreditations()
    },
  },

  watch: {
    'options'() {
      this.load()
    },
  },
}
</script>

<style scoped>
table.accreditation {
  width: 100%;
}

table.accreditation {
  vertical-align: top;
  padding: .65rem 1rem .4rem 0 !important;
  border-bottom: 0px none;
}

table.accreditation td {
  padding-top: .8em !important;
  padding-bottom: .8em !important;
  border-bottom: 1px solid #E9E9E9;
}

table.accreditation th {
  text-align: left;
  vertical-align: bottom;
  padding-top: .8em !important;
  padding-bottom: .8em !important;
  border-bottom: 1px solid #1AA8C0;
}
</style>

export default {
  notification: {
    description: 'Here you can send a notification about this event to interested parties and participants. The notification will be sent by e-mail and displayed in the online registration.',
    notifications: 'Notifications',
    newNotifications: 'New notifications',
    readNotifications: 'Include read notifications',
    noNotifications: 'There are currently no notifications for you.',
    sendNotification: 'Send Notification',
    sendTo: 'Send to',
    groups: 'Send to',
    interestedPersons: 'Interested persons',
    allParticipants: 'All participants',
    confirmedParticipants: 'Confirmed participants',
    send: 'Send',
    obtain: 'Obtain',
    sent: 'Sent',
    markAsReadAll: 'Mark all as read',
    allNotifications: 'All notifications',
    sendNotificationCount: '{count} e-mails being sent successfully!'
  },
}

<template>
  <v-dialog v-model="isShown" width="500">
    <v-card>
      <v-card-title class="justify-space-between">
        <h4 class="primary--text">
          {{ $t('common.confirmModal') }}
        </h4>
        <v-icon size="17" @click="hide">fal fa-times</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-5">
        <slot />
      </v-card-text>
      <v-card-actions>
        <v-btn class="elevation-0" @click="cancel">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn
          class="elevation-0 ml-auto"
          color="secondary"
          @click="confirm"
          :loading="loading"
        >
          {{ $t('common.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    asyncHide: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      isShown: false,
    }
  },

  methods: {
    show() {
      this.isShown = true
    },

    hide() {
      this.isShown = false
    },

    cancel() {
      this.hide()
    },

    confirm() {
      this.$emit('confirm')

      if (!this.asyncHide) {
        this.hide()
      }
    },
  },
}
</script>

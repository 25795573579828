<template>
  <v-app>
    <public-header />

    <v-main class="grey lighten-4">
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>

    <snackbar ref="snackbar" />

  </v-app>
</template>

<script>
import PublicHeader from '@/components/public/Header.vue'
import Snackbar from '@/components/base/Snackbar.vue'

export default {
  name: 'AppPublic',

  components: {
    PublicHeader,
    Snackbar
  },

  created () {

  },

  mounted() {
    this.$root.vtoast = this.$refs.snackbar
  },

  methods: {

  }
}
</script>

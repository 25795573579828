<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-switch
            :label="$t('tradeFair.filters.futureEventsOnly')"
            v-model="filters.future"
            :disabled="loading"
        />
      </v-col>

      <v-col cols="12">
        <v-autocomplete
            v-model="filters.names"
            :items="fairs"
            :loading="loading"
            :disabled="loading"
            item-text="attributes.name_display"
            item-value="id"
            :label="$t('fairs.tradeFairName')"
            :no-data-text="$t('common.noDataAvailable')"
            multiple
            clearable
            chips
            dense
        />
      </v-col>

      <v-col cols="12">
        <v-autocomplete
            v-model="filters.countries"
            :items="countries"
            :loading="loading"
            :disabled="loading"
            :item-text="`translations.${$i18n.locale}.country`"
            item-value="id"
            chips
            :label="$t('fairs.country')"
            multiple
            dense
            clearable
            background-color="transparent"
        />
      </v-col>

      <v-col cols="12">
        <v-autocomplete
            v-model="filters.years"
            :items="years"
            :loading="loading"
            :disabled="loading"
            chips
            :label="$t('fairs.year')"
            multiple
            dense
            clearable
            background-color="transparent"
        />
      </v-col>

      <v-col cols="12">
        <v-autocomplete
            v-model="filters.sectors"
            :items="sectors"
            :loading="loading"
            :disabled="loading"
            :item-text="`translations.${$i18n.locale}.name`"
            item-value="id"
            chips
            :label="$t('fairs.branch')"
            multiple
            flat
            dense
            clearable
        />
      </v-col>

      <v-col cols="12">
        <v-autocomplete
            v-model="filters.sponsors"
            :items="sponsors"
            :loading="loading"
            :disabled="loading"
            chips
            item-text="text"
            item-value="value"
            :label="$t('tradeFair.filters.program')"
            multiple
            flat
            dense
            clearable
        />
      </v-col>

      <v-col cols="12">
        <v-autocomplete
            v-model="filters.associations"
            :items="associations"
            :loading="loading"
            :disabled="loading"
            :item-text="`translations.${$i18n.locale}.name`"
            item-value="id"
            chips
            :label="$t('tradeFair.filters.association')"
            multiple
            flat
            dense
            clearable
        />
      </v-col>

    </v-row>
  </div>
</template>

<script>
import { SPONSORS } from '@/enums/sponsors'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'FairSearch',

  props: {
    loading: Boolean,
  },

  data() {
    return {
      filters: {
        names: [],
        countries: [],
        years: [],
        sectors: [],
        sponsors: [],
        associations: [],
        future: true,
      },
      fairs: [],
    }
  },

  computed: {
    ...mapGetters('sectors', [
      'sectors',
    ]),

    ...mapGetters('association', [
      'associations',
    ]),

    countries() {
      let countries = []

      for (let fair of this.fairs) {
        if (!fair.relationships.country) {
          continue;
        }

        countries[fair.relationships.country.id] = fair.relationships.country
      }

      return countries
    },

    sponsors() {
      return [
        {text: this.$t('sponsor.bmwi'), value: SPONSORS.BMWI},
        {text: this.$t('sponsor.bmel'), value: SPONSORS.BMEL},
      ]
    },

    years() {
      return [
        String(Number(this.$moment().format('YYYY'))),
        String(Number(this.$moment().format('YYYY')) + 1),
      ]
    },
  },

  created() {
    this.load()

    this.fetchSectors()
    this.fetchAssociations()
  },

  methods: {
    ...mapActions('sectors', [
      'fetchSectors'
    ]),

    ...mapActions('association', [
      'fetchAssociations'
    ]),

    async load() {
      let response = (await this.$axios.get('/api/public/trade-fairs', {
        params: {
          'languages': '*',
          'include': [
            'industrySectors',
            'country',
            'associations',
          ],
          'fields': [
            'name',
            'name_alternative',
            'country_id',
          ],
        },
      })).data

      this.fairs = response.data
    },
  },

  watch: {
    'filters': {
      handler() {
        var query = {};
        for (var filter in this.filters) {
          query[filter] = this.filters[filter]
        }
        this.$router.replace({query: Object.assign({}, this.$route.query, query)});

        if (this.showDisclaimer) {
          this.$emit('disclaimer')
        } else {
          this.showDisclaimer = true
        }

        this.$emit('input', this.filters)
      },
      deep: true
    },

    'associations'() {
      for (var el in this.filters) {
        var arr = this.$route.query[el] ? (typeof this.$route.query[el] == 'object' ? this.$route.query[el] : [this.$route.query[el]]) : [];

        if (el !== 'years' && el !== 'sponsors') {
          arr = arr.map((item) => {
            return Number(item);
          })
        }

        this.filters[el] = arr;
      }

      this.filters.years = Object.keys(this.$route.query).length ? this.filters.years : [this.$moment().format('YYYY'), this.$moment().add(1, 'y').format('YYYY')]
      this.filters.future = Object.keys(this.$route.query).length ? (this.$route.query.future ?? true) : true
    }
  }
}
</script>

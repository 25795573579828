export default {
  tradeFair: {
    tradeFair: 'Messe',
    tradeFairs: 'Messen',
    overviewEventAndStatus: 'Veranstaltungsübersicht und Anmeldestand der Auslandsmesseprogramme',
    date: 'Datum',
    location: 'Ort',
    deadline: 'Anmeldeschluss',
    changeDeadline: 'Anmeldeschluss ändern',
    reopenRegistration: {
      form:'Anmeldeformular für DFG wieder öffnen',
      alert: 'Diese Messe hat bereits Anmeldungen und kann daher nicht wieder zur Bearbeitung freigegeben werden.',
    },
    hidden: {
      hide: 'Messe verstecken',
      unhide: 'Messe anzeigen',
      hiddenStatus: 'Status: Unsichtbar',
      unhiddenStatus: 'Status: Sichtbar',
    },
    editTradeFair: 'Messe bearbeiten',
    executionCompany: 'Durchführungsgesellschaft',
    executionCompanyDescription: 'Durchführungsgesellschaft im Sinne der Allgemeinen Teilnahmebedingungen',
    projectManager: 'Projektleitung',
    confirmationPDF: 'Bestätigungs-PDF',
    confirmedRegistrations: 'Bestätigte Anmeldungen',
    confirmedExhibitorApplications: 'Bestätigte Ausstelleranmeldungen',
    organizer: 'Veranstalter',
    inCooperationWith: 'in Kooperation mit',
    dynAMPSandbox: 'DynAMP Sandbox',
    testDynAMPConfiguration: 'DynAMP-Konfiguration und Formulardarstellung testen.',
    filters: {
      futureEventsOnly: 'nur zukünftige Veranstaltungen',
      name: 'Messename',
      year: 'Jahr',
      program: 'Messeprogramm',
      association: 'Wirtschaftsorganisation',
    },
    registrationDocuments: 'Anmeldeunterlagen für Bundesbeteiligungen',
    DFG: 'DFG',
    assignedUsers: 'Zugeordnete Nutzer',
    dates: 'Messedaten',
    aumaId: 'AUMA ID',
    bafaId: 'Bafa ID',
    aumaDatabase: 'AUMA Datenbank',
    registeredExhibitors: 'Angemeldete Aussteller',
    gtq: 'GTQ',
    funding: 'Förderung',
    registrationIsCancelled: 'Die Anmeldung ist storniert',
    registrationIsExternalLocked: 'Die Anmeldung ist extern gesperrt',
    tradeFairIsFinished: 'Messe ist fertig',
    registrationDeadlineIsPassed: 'Die Anmeldefrist ist abgelaufen',
  },
}

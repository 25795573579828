<template>
  <v-container v-if="!isSelectedTradeFair" fluid>
    <v-card class="rounded-0 elevation-1 mb-5">
      <v-card-text class="text--primary">
        <p>{{ $t('participation.tradeFairNotSelected') }}</p>
      </v-card-text>
    </v-card>
  </v-container>
  <v-container v-else-if="fair.id && !fair.attributes.end_date" fluid>
    <v-card class="rounded-0 elevation-1 mb-5">
      <v-card-text class="text--primary">
        <p>{{ $t('participation.noEndDate') }}</p>
      </v-card-text>
    </v-card>
  </v-container>
  <v-container fluid
               v-else-if="fair.id"
               class="pa-9"
  >

    <edit-inactive-registration
        v-if="isRegistrationPossibleCustomize"
        :fair="fair"
        @update="load"
        @customized="handleCustomized"
    />

    <edit-active-registration
        v-if="isOnlineRegistrationOpen"
        :fair="fair"
        :is-deadline-running="isDeadlineRunning"
        @update="load"
    />

    <registration-link
        v-if="isOnlineRegistration && isRegistrationOpen"
        :fair="fair"
        @submit="load"
    />

    <print-registration-open
        v-if="isPrintRegistrationOpen"
        :auma-link="fair.attributes.link_auma_database"
    />

    <div v-if="!isPrintRegistrationOpen">
      <v-btn @click="handleRegistrationFormPreview"
             color="secondary"
             elevation="0"
             class="mb-3"
             text
             v-if="isRegistrationPossibleCustomize && registrationFormCustomize"
      >
        <template v-if="!registrationFormPreview">
          {{ $t('participation.configureRegistrationForm.preview') }}
        </template>

        <template v-if="registrationFormPreview">
          {{ $t('participation.configureRegistrationForm.editRegistrationForm') }}
        </template>
      </v-btn>

      <v-btn @click="handleExportSummary"
             color="secondary"
             elevation="0"
             class="mb-3"
             text
      >
        {{ $t('participation.configureRegistrationForm.downloadSummary') }}
      </v-btn>
    </div>

    <div v-if="isRegistrationPossibleCustomize && registrationFormCustomize">
      <template v-if="registrationFormPreview && !form.loading">
        <registration-form-preview :fair="fair"/>
      </template>

      <template v-if="!registrationFormPreview && !form.loading">
        <organizer-customize-registration :fair="fair"></organizer-customize-registration>
      </template>
    </div>
    <div v-if="!isRegistrationPossibleCustomize">
      <registration-form-preview v-if="isOnlineRegistration && isRegistrationOpen" :fair="fair"/>
    </div>

  </v-container>
</template>

<script>
import {REGISTRATION_TYPES} from '@/enums/registrationTypes'
import {mapGetters, mapActions} from 'vuex'
import EditInactiveRegistration from '@/components/organizer/Application/EditInactiveRegistration'
import EditActiveRegistration from '@/components/organizer/Application/EditActiveRegistration'
import RegistrationLink from '@/components/organizer/Application/RegistrationLink'
import PrintRegistrationOpen from '@/components/organizer/Application/PrintRegistrationOpen'
import RegistrationFormPreview from '@/components/organizer/Application/RegistrationFormPreview'
import OrganizerCustomizeRegistration from '@/components/organizer/Application/Customize/CustomizeRegistration'

export default {
  name: 'OrganizerApplication',

  components: {
    OrganizerCustomizeRegistration,
    EditInactiveRegistration,
    EditActiveRegistration,
    RegistrationLink,
    PrintRegistrationOpen,
    RegistrationFormPreview,
  },

  data() {
    return {
      fair: {},

      form: {
        loading: false,
      },

      registrationFormCustomize: false,
      registrationFormPreview: false,
    }
  },

  computed: {
    ...mapGetters('organizer', {
      tradeFairID: 'tradeFairID',
    }),

    isSelectedTradeFair() {
      return !!this.tradeFairID;
    },

    isRegistrationOpen() {
      return this.fair.attributes.flag_registration_is_open == true
    },

    isRegistrationClosed() {
      return this.fair.attributes.flag_registration_is_open == false
    },

    isRegistrationPossibleCustomize() {
      return !this.isRegistrationOpen;
    },

    isOnlineRegistration() {
      return this.fair.attributes.registration_type === REGISTRATION_TYPES.ONLINE
    },

    isPrintRegistration() {
      return this.fair.attributes.registration_type === REGISTRATION_TYPES.PRINT
    },

    isPrintRegistrationOpen() {
      return this.isPrintRegistration && this.isRegistrationOpen
    },

    isOnlineRegistrationOpen() {
      return this.isOnlineRegistration && this.isRegistrationOpen
    },

    isDeadlineRunning() {
      return this.$moment(this.fair.attributes.registration_deadline).isAfter()
    },
  },

  async created() {
    this.load()
  },

  methods: {
    ...mapActions('tradefair', [
      'downloadSummary',
    ]),

    async load() {
      this.form.loading = true

      let response = (await this.$axios.get(`/api/trade-fairs/${this.tradeFairID}`, {
        params: {
          'languages': '*',
          'include': [
            'accessUsers',
            'associations',
            'country',
            'exhibitionAreas',
            'industrySectors',
            'organizer',
            'packages',
            'services',
            'spaces',
            'type',
            'interestedCompanies',
          ],
        },
      })).data

      this.fair = response.data

      this.form.loading = false
    },

    handleCustomized(val) {
      this.registrationFormCustomize = val;
    },

    /** TODO */
    handleRegistrationFormPreview() {
      // this.fetchCurrentTradeFair()

      this.registrationFormPreview = !this.registrationFormPreview
    },

    handleExportSummary() {
      this.downloadSummary({tradeFair: this.fair});
    }
  },
}
</script>

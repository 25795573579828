<template>
  <v-navigation-drawer
    class="primary"
    app
    light
    clipped
    fixed
    permanent
    expand-on-hover
    mini-variant
    width="350"
  >
    <v-list nav class="px-0 py-5">
      <v-list-item to="/exhibitor/dashboard/" class="ma-0">
        <v-list-item-icon class="ml-2">
          <v-icon class="menubar-icon" size="22"> fal fa-home fa-fw </v-icon>
        </v-list-item-icon>

        <v-list-item-title class="white--text">
          {{ $t('menu.overview') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-group value="false" class="ma-0">
        <v-icon slot="appendIcon" color="white" size="24">
          fal fa-fw fa-angle-down
        </v-icon>

        <template v-slot:activator>
          <v-list-item-icon class="ml-2">
            <v-icon class="menubar-icon" size="22">
              fal fa-calendar-star fa-fw
            </v-icon>
          </v-list-item-icon>

          <v-list-item-title class="white--text">
            {{ $t('menu.myTradeFairParticipations') }}
          </v-list-item-title>
        </template>

        <v-list-item to="/exhibitor/participation/overview" class="ma-0">
          <v-list-item-icon class="ml-2" />

          <v-list-item-title class="white--text">
            {{ $t('menu.overview') }}
          </v-list-item-title>
        </v-list-item>

        <v-list-item
          v-for="tradeFair in tradeFairs"
          :to="tradeFair.route"
          :key="tradeFair.id"
          class="ma-0"
        >
          <v-list-item-icon class="ml-2" />

          <v-list-item-title class="white--text">
            {{ tradeFair.name }}
          </v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-list-item to="/exhibitor/accreditation" class="ma-0">
        <v-list-item-icon>
          <v-icon class="menubar-icon ml-2" size="22">
            fal fa-id-card-alt fa-fw
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="white--text">
            {{ $t('menu.accreditation') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/exhibitor/fairs" class="ma-0">
        <v-list-item-icon>
          <v-icon class="menubar-icon ml-2" size="22">
            fal fa-search-location fa-fw
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="white--text">
            {{ $t('menu.findTradeFairs') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/exhibitor/help" class="ma-0">
        <v-list-item-icon>
          <v-icon class="menubar-icon ml-2" size="22">
            fal fa-question-circle fa-fw
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="white--text">
            {{ $t('menu.helpAndAdvice') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/exhibitor/settings" class="ma-0">
        <v-list-item-icon>
          <v-icon class="menubar-icon ml-2" size="22">
            fal fa-cogs fa-fw
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="white--text">
            {{ $t('menu.settings') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ExhibitorMenuBar',

  computed: {
    ...mapGetters('participation', ['allParticipations', 'notedTradeFairs']),

    tradeFairs() {
      let tradeFairs = []

      let fairIds = this.allParticipations.map((item) => {
        tradeFairs.push({
          id: item.tradeFair.id,
          name: item.tradeFair.nameDisplay,
          route: this.$router.resolve({
            name: 'exhibitor.participation.view',
            params: {
              id: item.id,
            },
          }).href,
        })
        return item.tradeFair.id
      })

      this.notedTradeFairs.forEach((item) => {
        if (!fairIds.includes(item.id)) {
          tradeFairs.push({
            id: item.id,
            name: item.nameDisplay,
            route: this.$router.resolve({
              name: 'exhibitor.fair.view',
              params: {
                id: item.id,
              },
            }).href,
          })
        }
      })

      return tradeFairs
    },
  },
}
</script>

<template>
  <v-row>
    <v-col
      cols="6"
      class="pr-5"
    >
      <v-text-field
        v-model="form.data.email"
        :error-messages="errors.email"
        :label="$t('forms.fields.email')"
      />

      <v-text-field
        type="password"
        v-model="form.data.password"
        :error-messages="errors.password"
        :label="$t('forms.fields.password')"
        :hint="$t('forms.hints.password')"
      />

      <v-text-field
        type="password"
        v-model="form.data.password_confirmation"
        :error-messages="errors.password_confirmation"
        :label="$t('forms.fields.passwordConfirmation')"
        :hint="$t('forms.hints.password')"
      />

      <validation-general-error :errors="errors.general" />

      <v-btn
        color="secondary"
        class="elevation-0"
        @click="handleSubmit"
        :loading="form.loading"
      >
        {{ $t('common.save') }}
      </v-btn>
    </v-col>
    <v-col
      cols="6"
      class="pl-5"
    >
      <p>{{ $t('settings.sections.userCredentials.description') }}</p>
      <v-alert dense outlined type="warning">{{ $t('settings.sections.userCredentials.warning') }}</v-alert>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { email, minLength, required, sameAs } from 'vuelidate/lib/validators'
import validation from '@/mixins/validate'
import ValidationGeneralError from '@/components/base/ValidationGeneralError'

export default {
  name: 'UserCredentials',

  components: {
    ValidationGeneralError,
  },

  mixins: [
    validation,
  ],

  data() {
    return {
      form: {
        loading: false,

        data: {
          email: '',
          password: '',
          password_confirmation: '',
        },

        validationRules: {
          email: {
            required,
            email,
          },
          password: {
            minLength: minLength(6),
          },
          password_confirmation: {
            minLength: minLength(6),
            sameAsPassword: sameAs('password'),
          },
        },

        validationSubstitutions: {
          password_confirmation: {
            sameAsPassword: {
              sameAsField: this.$t('forms.fields.password'),
            },
            minLength: {
              length: 6,
            },
          },
          password: {
            minLength: {
              length: 6,
            },
          },
        },
      },
    }
  },

  computed: {
    ...mapGetters('auth', [
      'user',
    ]),
  },

  created() {
    this.setInitialFormData()
  },

  methods: {
    setInitialFormData() {
      this.form.data.email = this.user.email
    },

    async handleSubmit() {
      this.resetServerErrors()

      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      var form = this.getFormData()

      if (!this.form.data.password) {
        delete form.password
        delete form.password_confirmation
      }

      this.form.loading = true
      try {
        await this.$axios.put(`/api/users/${this.user.id}`, {
          ...form
        })

        this.$snackbar(this.$t('common.successApiMessage'))

        this.$emit('submit')

        this.hide()
      } catch (e) {
        if (e?.response?.status === 422) {
          this.setServerErrors(e?.response?.data?.errors ?? {})
        }
      }

      this.form.loading = false
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
